import { makeOptions } from ".";
import { categoryType } from "../../repositories/survey-brand/category";

export const categoryTypeOptions = makeOptions(categoryType);

export const ageFromOptions = [...new Array(13)].map((_, index) => {
	const value = 15 + index * 5;
	return {
		value,
		label: `${value}歳`,
	};
});
export const ageToOptions = [...new Array(13)].map((_, index) => {
	const value = 19 + index * 5;
	return {
		value,
		label: `${value}歳`,
	};
});
