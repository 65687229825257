import * as React from "react";
import { Option } from "../../../../../server/lib/option";
import classnames from "classnames";
import { DateInput, NumberInput, SingleSelect, Text } from "../input";
import { ValidationInput } from "../validation-input";
import { Col, Row } from "react-bootstrap";

export type SearchFieldType = "text" | "select" | "number" | "date";
export type BaseField<Type extends SearchFieldType> = {
	name: string;
	type: Type;
	label: string;
};

export type TextSearch = BaseField<"text">;
export type SelectSearch = BaseField<"select"> & { options: Option[] };
export type NumberSearch = BaseField<"number">;
export type DateSearch = BaseField<"date">;

export type SearchField = TextSearch | SelectSearch | NumberSearch | DateSearch;

export type SearcherProps = {
	fields: SearchField[];
	onChange: (name: string, value: any) => void;
	searchValues: { [key: string]: any };
};

export const Searcher: React.FC<SearcherProps> = React.memo(({ fields, searchValues, onChange }) => {
	return (
		<div className={"searcher"}>
			<Row>
				{fields.map((field) => (
					<Col md={6} key={`search-field-${field.name}`} className={classnames(`searcher__${field.type}`)}>
						{field.type === "text" && (
							<ValidationInput
								inputtype={"text"}
								label={field.label}
								name={field.name}
								onChange={(value) => onChange(field.name, value)}
								value={searchValues[field.name] || ""}
								noblock
							/>
						)}
						{field.type === "select" && (
							<ValidationInput
								inputtype={"single-select"}
								label={field.label}
								name={field.name}
								onChange={(value) => onChange(field.name, value)}
								value={searchValues[field.name]}
								options={field.options}
								noblock
							/>
						)}
						{field.type === "number" && (
							<ValidationInput
								inputtype={"number"}
								label={field.label}
								name={field.name}
								onChange={(value) => onChange(field.name, value)}
								value={searchValues[field.name]}
								noblock
							/>
						)}
						{field.type === "date" && (
							<ValidationInput
								inputtype={"date"}
								label={field.label}
								name={field.name}
								onChange={(value) => onChange(field.name, value)}
								value={searchValues[field.name]}
								noblock
							/>
						)}
					</Col>
				))}
			</Row>
		</div>
	);
});
