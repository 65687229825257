import { StandardCategoryDetail } from "..";

export const eyeShadowDetail: StandardCategoryDetail = {
	inUse: {
		standards: [
			{ id: 1, text: "のばしやすい" },
			{ id: 2, text: "色のつきがよい" },
			{ id: 3, text: "まぶたにフィットする" },
			{ id: 4, text: "細かいところも塗りやすい" },
		],
		canAdd: true,
	},
	afterUse: {
		standards: [
			{ id: 1, text: "見たままの色に仕上がる" },
			{ id: 2, text: "ムラなく仕上がる" },
			{ id: 3, text: "発色が良い" },
			{ id: 4, text: "立体感のある目もとに仕上がる" },
			{ id: 5, text: "粉っぽくない" },
			{ id: 6, text: "目が大きく見える" },
			{ id: 7, text: "目もとの印象・インパクトが増す" },
			{ id: 8, text: "自然な目もとに仕上がる" },
			{ id: 9, text: "目もとが明るくなる" },
			{ id: 10, text: "のせた色が目もとになじむ" },
		],
		canAdd: true,
	},
	afterFourHours: {
		standards: [
			{ id: 1, text: "のせた色が薄れない" },
			{ id: 2, text: "ヨレていない" },
			{ id: 3, text: "目もとが乾燥していない" },
			{ id: 4, text: "のせた色がにじまない" },
			{ id: 5, text: "のせた色がつけた直後と変わらない" },
			{ id: 6, text: "仕上がりが持続する" },
			{ id: 7, text: "発色が持続する" },
			{ id: 8, text: "目もとの立体感が持続する" },
		],
		canAdd: true,
	},
};
