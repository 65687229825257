import { AggregationLabel, AggregationLabelGrouping, CrossData } from "@lu/muscat-analytics-library/dist/model/cross";

export type AxisLabel = {
	label: string;
	colSpan?: number;
	rowSpan?: number;
	indexes: number[];
};

export type AxisFormat = "separate" | "combine";
export class AxisLabelConveter {
	protected labels: AxisLabel[][];
	private axisDepth: number;
	private valueIndex: number = 1;
	private axisFormat: AxisFormat;
	constructor(axisFormat: AxisFormat) {
		this.axisFormat = axisFormat;
	}
	private skipValueIndex(up: number): void {
		this.valueIndex += up;
	}
	protected setInitialLabel(crosses: CrossData[]): void {
		this.axisDepth =
			this.axisFormat === "separate"
				? crosses.reduce((prev, { label }) => {
					const depth = this.getDepth(label.grouping);
					if (depth > prev) return depth;
					return prev;
				}, 0)
				: 1;
		this.labels = [...new Array(this.axisDepth + 1)].map(() => []);
	}
	/**
	 * labelのグループ情報からどのくらいの深さがあるのかを取得。
	 * @param {AggregationLabelGrouping[]} [grouping = undefined]
	 * @returns {number}
	 */
	protected getDepth(grouping?: AggregationLabelGrouping[]): number {
		if (!grouping || grouping.length === 0 || !grouping[0].axis) return 1;
		return 1 + this.getDepth(grouping[0].axis.grouping);
	}
	public make(crosses: CrossData[]): AxisLabel[][] {
		this.setInitialLabel(crosses);
		for (const { label } of crosses) {
			if (this.axisFormat === "separate") {
				this.makeSeparate(label);
			} else {
				this.makeCombine(label);
			}
		}
		return this.labels;
	}
	/**
	 * 軸のベースとなる名称の取得
	 * @param {AggregationLabel} label
	 * @returns {string[]}
	 */
	public getMainLabels(label: AggregationLabel): string[] {
		const labels = new Set([label.label]);
		for (const group of label.grouping) {
			if (group.axis) this.getMainLabels(group.axis).map((l) => labels.add(l));
		}
		return Array.from(labels);
	}

	/**
	 * 分割したラベル情報の生成
	 * @param {AggregationLabel} label
	 * @returns {void}
	 */
	protected makeSeparate(label: AggregationLabel): void {
		const index = this.valueIndex;
		const indexes = [...new Array(label.childCount)].map((_, vIndex) => index + vIndex);
		const axisLabel: AxisLabel = {
			label: this.getMainLabels(label).join("x"),
			colSpan: label.childCount,
			indexes,
		};
		const depth = this.getDepth(label.grouping);
		const skip = this.axisDepth - depth + 1;
		axisLabel.rowSpan = skip;
		this.labels[0].push(axisLabel);
		this.makeSeparateChildLabels(label.grouping, indexes, index, skip);
		this.skipValueIndex(label.childCount);
	}
	private makeSeparateChildLabels(
		groups: AggregationLabelGrouping[],
		parentsIndexes: number[],
		index: number,
		labelIndex: number
	): void {
		let tmpIndex = index;
		for (const { label, axis } of groups) {
			const tmpLabel: AxisLabel = { label, indexes: [] };
			if (axis) {
				tmpLabel.colSpan = axis.childCount;
				const tIndex = tmpIndex + axis.childCount;
				const filteredIndexes = parentsIndexes.filter((v) => v >= tmpIndex && v < tIndex);
				tmpLabel.indexes = [...new Array(axis.childCount)].map((_, vIndex) => tmpIndex + vIndex);
				this.makeSeparateChildLabels(axis.grouping, filteredIndexes, tmpIndex, labelIndex + 1);
				tmpIndex = tIndex;
			} else {
				tmpLabel.indexes.push(tmpIndex);
				tmpIndex++;
			}
			this.labels[labelIndex].push(tmpLabel);
		}
	}
	/**
	 * 軸の最下部の名称の取得
	 * @param {AggregationLabel} label
	 * @returns {AxisLabel[]}
	 */
	public getBottomLabels(label: AggregationLabel): AxisLabel[] {
		const ret: AxisLabel[] = [];
		let index = this.valueIndex;
		for (const group of label.grouping) {
			if (group.axis) {
				const childLabels = this.getBottomLabels(group.axis);
				ret.push(
					...childLabels.map((cLabel) => ({
						label: `${group.label} ${cLabel.label}`,
						indexes: childLabels.reduce((a, b) => [...a, ...b.indexes.map((_, vIndex) => vIndex + index)], []),
					}))
				);
				index += childLabels.length;
			} else {
				ret.push({ label: group.label, indexes: [this.valueIndex] });
				this.skipValueIndex(this.valueIndex);
				index++;
			}
		}
		return ret;
	}
	protected makeCombine(label: AggregationLabel): void {
		const mainLabels = this.getMainLabels(label);
		const bottomLabels = this.getBottomLabels(label);
		this.labels[0].push({
			label: mainLabels.join("x"),
			colSpan: label.childCount,
			indexes: bottomLabels.reduce((a, b) => [...a, ...b.indexes], []),
		});
		this.labels[1].push(...bottomLabels);
		this.skipValueIndex(bottomLabels.length);
	}
	public transpose(array: string[][]): string[][] {
		return array[0].map((_, i) => array.map((row) => row[i]));
	}
}
