import * as React from "react";
import classNames from "classnames";
import { useHistory } from "react-router";
import { apiEndpoint } from "../../../../server/router/api/endpoint";
import { LoginResponse } from "../../../../server/types/auth";
import { LoginPage, LoginParams } from "../../components/pages/login";
import { AppBar } from "../../components/parts/app-bar";
import { makeError, post } from "../../lib/request";
import { initState, reducer } from "../../reducers/login";

export const LoginContainer = (): JSX.Element => {
	const history = useHistory();
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { info, email, password } = state;
	const onChange = React.useCallback(
		(name: keyof LoginParams, value: string) => dispatch({ type: "change", payload: { name, value } }),
		[]
	);
	const onSubmit = React.useCallback(() => {
		dispatch({ type: "changeLoading", payload: true });
		post<LoginResponse>(apiEndpoint.login, { email, password })
			.then((response) => {
				history.push(response.data.redirectUrl || "/");
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, [email, password]);
	return (
		<div className={classNames("body-container")}>
			<AppBar />
			<LoginPage
				email={email}
				password={password}
				errorMessage={info.isSuccess ? undefined : info.message}
				errors={info.errors}
				onChange={onChange}
				onSubmit={onSubmit}
			/>
		</div>
	);
};
