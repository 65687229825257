import { CrossData } from "@lu/muscat-analytics-library";
import { SurveyBrandWithBrand } from "../../../../../server/repositories/survey-brand/model";
import { CrossAction } from "../../../types/actions";
import { MessageInfo } from "../../../types/info";
import { ResultViewType } from "../../../types/result-view-type";

export type State = {
	loading: boolean;
	info: MessageInfo;

	cross: string;
	data: CrossData[];
	surveyBrand?: SurveyBrandWithBrand;
	viewType: ResultViewType;
};

export const initState: State = {
	info: { message: "", isSuccess: true },
	cross: "",
	loading: true,
	viewType: "both",
	data: [],
};

export const reducer = (state: State, action: CrossAction): State => {
	switch (action.type) {
		case "changeMessageInfo":
			return {
				...state,
				loading: false,
				info: action.payload,
			};
		case "changeLoading":
			return {
				...state,
				loading: action.payload,
			};
		case "changeCross":
			return {
				...state,
				cross: action.payload,
			};
		case "loadCross":
			return {
				...state,
				...action.payload,
				loading: false,
			};
		case "changeViewType":
			return {
				...state,
				viewType: action.payload,
			};
	}
};
