import React from "react";
import { CustomAggregateJobWithInfo } from "../../../../../../server/repositories/custom-aggregate-job/model";
import { Key } from "@lu/repository";
import { AggregateJobTarget } from "../../../../../../server/types/request/aggregate/redirect";
import { DownloadAggregateJobTarget } from "../../../../../../server/types/request/aggregate/download";
import { SettingMenu, SettingMenuItem } from "../../../parts/setting-menu";
import { categoryType } from "../../../../../../server/repositories/survey-brand/category";
import { productType } from "../../../../../../server/repositories/survey-brand/model";

type AggregateJobRowProps = {
	aggregateJob: CustomAggregateJobWithInfo;
	onEvoke: (id: Key, target: AggregateJobTarget) => void;
	onDownload: (aggregateJob: CustomAggregateJobWithInfo, target: DownloadAggregateJobTarget) => void;
};

export const AggregateJobRow: React.FC<AggregateJobRowProps> = React.memo(({ aggregateJob, onEvoke, onDownload }) => {
	const events = React.useMemo<SettingMenuItem[]>(() => {
		const items: SettingMenuItem[] = [{ label: "編集", event: () => onEvoke(aggregateJob._id, "editor") }];
		if (aggregateJob.canViewGT) {
			items.push({ provider: true });
			items.push({ label: "GT集計", event: () => onEvoke(aggregateJob._id, "gt") });
		}
		if (aggregateJob.canViewCross) {
			items.push({ label: "クロス集計", event: () => onEvoke(aggregateJob._id, "cross") });
		}
		/*/
		if (aggregateJob.canViewGT) {
			items.push({ provider: true });
			items.push({ label: "GT集計ダウンロード", event: () => onDownload(aggregateJob, "gt") });
		}
		if (aggregateJob.canViewCross) {
			items.push({ label: "クロス集計ダウンロード", event: () => onDownload(aggregateJob, "cross") });
		}
		/*/
		return items;
	}, [aggregateJob, onEvoke, onDownload]);
	return (
		<tr>
			<td>{aggregateJob.jobNo}</td>
			<td>{aggregateJob.job.no}</td>
			<td>{aggregateJob.job.surveyStartDate}</td>
			<td>{aggregateJob.name}</td>
			<td>{categoryType[aggregateJob.surveyBrand.categoryType]}</td>
			<td>{productType[aggregateJob.surveyBrand.productType]}</td>
			<td>{aggregateJob.surveyBrand.productName}</td>

			<td>
				<>{aggregateJob.brand.name}</>
				{aggregateJob.surveyBrand.brandName != null && <div>（{aggregateJob.surveyBrand.brandName}）</div>}
			</td>
			<td>{aggregateJob.account.name}</td>
			<td>
				<SettingMenu items={events} />
			</td>
		</tr>
	);
});
