import { StandardCategoryDetail } from "..";

export const allInOneDetail: StandardCategoryDetail = {
	inUse: {
		standards: [
			{ id: 1, text: "容器から出したときの見た目・色がよい" },
			{ id: 2, text: "のびがよい" },
			{ id: 3, text: "油っぽくない" },
			{ id: 4, text: "肌へのなじみがよい" },
		],
		options: [
			{ id: 5, text: "硬さがちょうどよい" },
			{ id: 6, text: "とろみ／粘度がちょうどよい" },
			{ id: 7, text: "こくがある" },
			{ id: 8, text: "みずみずしい" },
			{ id: 9, text: "コットンの肌あたりがソフト" },
			{ id: 10, text: "ぬめりがない" },
			{ id: 11, text: "清涼感がある" },
		],
		canAdd: true,
	},
	afterUse: {
		standards: [
			{ id: 1, text: "肌が油っぽくない" },
			{ id: 2, text: "肌がしっとりする" },
			{ id: 3, text: "肌がさっぱりする" },
			{ id: 4, text: "肌がやわらかい" },
			{ id: 5, text: "肌がなめらかになる" },
			{ id: 6, text: "肌がべたつかない" },
			{ id: 7, text: "肌にハリがある" },
			{ id: 8, text: "肌の中まで浸透した" },
			{ id: 9, text: "自分の肌タイプに合う" },
		],
		options: [
			{ id: 10, text: "肌がみずみずしい" },
			{ id: 11, text: "肌にうるおいがある" },
			{ id: 12, text: "肌がつるつるする" },
			{ id: 13, text: "肌がつっぱらない" },
			{ id: 14, text: "肌がふっくらとする" },
			{ id: 15, text: "肌がもちもちとする" },
			{ id: 16, text: "肌がすべすべになる" },
			{ id: 17, text: "肌がサラサラになる" },
		],
		canAdd: true,
	},
	nextMorning: {
		standards: [
			{ id: 1, text: "翌朝、肌がべたつかない" },
			{ id: 2, text: "翌朝、肌がしっとりする" },
			{ id: 3, text: "翌朝、肌にハリがある" },
		],
		options: [
			{ id: 4, text: "翌朝、肌がふっくらとする" },
			{ id: 5, text: "翌朝、肌がもちもちとする" },
		],
		canAdd: true,
	},
	daytimeSkin: {
		standards: [{ id: 1, text: "日中、肌にうるおいがある" }],
		options: [
			{ id: 2, text: "ファンデーションののりがよい" },
			{ id: 3, text: "ファンデーションのもちがよい" },
		],
		canAdd: true,
	},
};
