import React, { FunctionComponent } from "react";
import { CustomAggregateJobWithInfo } from "../../../../../../server/repositories/custom-aggregate-job/model";
import { Key } from "@lu/repository";
import { Table } from "react-bootstrap";
import { AggregateJobRow } from "./row";
import { customAggregateJobLabel, jobLabel, surveyBrandLabel } from "../../../../../../label";
import { SurveyBrandWithBrand } from "../../../../../../server/repositories/survey-brand/model";
import { AggregateJobTarget } from "../../../../../../server/types/request/aggregate/redirect";
import { DownloadAggregateJobTarget } from "../../../../../../server/types/request/aggregate/download";

export type AggregateJobListPageProps = {
	surveyBrand?: SurveyBrandWithBrand;
	aggregateJobs: CustomAggregateJobWithInfo[];
	onEvoke: (id: Key, targrt: AggregateJobTarget) => void;
	onDownload: (aggregateJob: CustomAggregateJobWithInfo, target: DownloadAggregateJobTarget) => void;
};

export const AggregateJobListPage: FunctionComponent<AggregateJobListPageProps> = React.memo(
	({ aggregateJobs, onEvoke, surveyBrand, onDownload }) => {
		return (
			<>
				<div className={"main-title"}>集計一覧{surveyBrand != null && <>（{surveyBrand.brand.name}）</>}</div>
				<Table>
					<thead>
						<tr>
							<th>{customAggregateJobLabel.jobNo}</th>
							<th>{jobLabel.no}</th>
							<th>{jobLabel.surveyStartDate}</th>
							<th>{customAggregateJobLabel.name}</th>

							<th>{surveyBrandLabel.categoryType}</th>
							<th>{surveyBrandLabel.productType}</th>
							<th>{surveyBrandLabel.productName}</th>

							<th>{customAggregateJobLabel.surveyBrandId}</th>
							<th>{customAggregateJobLabel.accountId}</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{aggregateJobs.map((aggregateJob) => (
							<AggregateJobRow
								key={aggregateJob._id.toString()}
								aggregateJob={aggregateJob}
								onEvoke={onEvoke}
								onDownload={onDownload}
							/>
						))}
					</tbody>
				</Table>
			</>
		);
	}
);
