import * as React from "react";
import { Table } from "react-bootstrap";

export type ArrayViewProps = {
	values: string[];
};

export const ArrayView: React.FC<ArrayViewProps> = React.memo(({ values }) => {
	return (
		<Table className={"array-view"}>
			<thead>
				<tr>
					<th>No</th>
					<th>項目内容</th>
				</tr>
			</thead>
			<tbody>
				{values.map((value, index) => (
					<tr key={`array-view-item-${index}`}>
						<td>{index + 1}</td>
						<td>{value}</td>
					</tr>
				))}
			</tbody>
		</Table>
	);
});
