import { Option } from "@lu/muscat-library/dist/lib/common";
import { Key } from "@lu/repository";
import * as React from "react";
import { Button } from "react-bootstrap";
import { jobLabel, surveyBrandLabel } from "../../../../../../label";
import { option2Map } from "../../../../../../server/lib/option";
import { categoryTypeOptions } from "../../../../../../server/lib/option/job";
import { productTypeOptions, surveyStatusOptions } from "../../../../../../server/lib/option/survey-brand";
import { JobWithSurveyBrands } from "../../../../../../server/repositories/job/model";
import { Searcher, SearchField } from "../../../parts/searcher";
import { JobTable, JobTableProps } from "./job-table";

export type JobListPageProps = {
	isTargetJob: boolean;
	accountOptions: Option[];
	brandOptions: Option[];
	searchValues: { [key: string]: any };
	onChangeSearchValues: (name: string, value: any) => void;
	jobs: JobWithSurveyBrands[];

	onChangeJobPage: JobTableProps["onChangeJobPage"];
	onChangeSurveyBrandPage: JobTableProps["onChangeSurveyBrandPage"];
};

export const JobListPage: React.FC<JobListPageProps> = React.memo(
	({
		isTargetJob,
		accountOptions,
		brandOptions,
		jobs,
		onChangeJobPage,
		onChangeSurveyBrandPage,
		onChangeSearchValues,
		searchValues,
	}) => {
		const accountMap = React.useMemo(() => option2Map(accountOptions), [accountOptions]);
		const brandMap = React.useMemo(() => option2Map(brandOptions), [brandOptions]);
		const fields = React.useMemo<SearchField[]>(() => {
			if (isTargetJob) {
				return [
					{ type: "select", name: "status", label: surveyBrandLabel.status, options: surveyStatusOptions },
					{ type: "select", name: "brandId", label: surveyBrandLabel.brandId, options: brandOptions },
					{ type: "select", name: "categoryType", label: surveyBrandLabel.categoryType, options: categoryTypeOptions },
					{ type: "text", name: "productName", label: surveyBrandLabel.productName },
					{ type: "select", name: "accountId", label: jobLabel.accountId, options: accountOptions },

					{ type: "select", name: "productType", label: surveyBrandLabel.productType, options: productTypeOptions },
				];
			}
			return [
				{ type: "number", name: "no", label: jobLabel.no },
				{ type: "select", name: "status", label: surveyBrandLabel.status, options: surveyStatusOptions },
				{ type: "select", name: "brandId", label: surveyBrandLabel.brandId, options: brandOptions },
				{ type: "date", name: "surveyStartDate", label: jobLabel.surveyStartDate },
				{ type: "select", name: "categoryType", label: surveyBrandLabel.categoryType, options: categoryTypeOptions },
				{ type: "text", name: "productName", label: surveyBrandLabel.productName },
				{ type: "select", name: "accountId", label: jobLabel.accountId, options: accountOptions },

				{ type: "select", name: "productType", label: surveyBrandLabel.productType, options: productTypeOptions },
			];
		}, [isTargetJob, brandOptions]);
		const filteredJobs = React.useMemo(() => {
			if (fields.every(({ name }) => !searchValues[name])) return jobs;
			return jobs
				.map((job) => {
					const jobMatch = fields.every(({ name, type }) => {
						if (!searchValues[name]) return true;
						if (name === "no" || name === "accountId" || name === "surveyStartDate") {
							return searchValues[name] === job[name];
						}
						return true;
					});
					if (!jobMatch) return undefined;
					const surveyBrands = job.surveyBrands.filter((surveyBrand) => {
						return fields.every(({ name, type }) => {
							if (!searchValues[name]) return job;
							if (
								name === "status" ||
								name === "brandId" ||
								name === "categoryType" ||
								name === "productName" ||
								name === "productType"
							) {
								if (type === "text") return surveyBrand[name].toString().includes(searchValues[name].toString());
								return surveyBrand[name] === searchValues[name];
							}
							return true;
						});
					});
					if (surveyBrands.length === 0) return undefined;
					return { ...job, surveyBrands };
				})
				.filter((job) => !!job);
		}, [jobs, searchValues, fields]);
		const onCreate = React.useCallback(() => onChangeJobPage("new", "edit"), [onChangeJobPage]);
		return (
			<>
				<div className="main-title">
					ジョブ一覧
					<div className={"float-right"}>
						<Button variant={"outline-secondary"} onClick={onCreate}>
							新規作成
						</Button>
					</div>
				</div>
				<Searcher onChange={onChangeSearchValues} fields={fields} searchValues={searchValues} />
				<JobTable
					isTargetJob={isTargetJob}
					jobs={filteredJobs}
					brandMap={brandMap}
					accountMap={accountMap}
					onChangeJobPage={onChangeJobPage}
					onChangeSurveyBrandPage={onChangeSurveyBrandPage}
				/>
			</>
		);
	}
);
