import { LoginParams } from "../../components/pages/login";
import { LoginAction } from "../../types/actions";
import { MessageInfo } from "../../types/info";

export type State = LoginParams & {
	loading: boolean;
	info: MessageInfo;
};

export const initState: State = {
	email: "",
	password: "",
	info: { message: "", isSuccess: true },
	loading: false,
};
export const reducer = (state: State, action: LoginAction): State => {
	switch (action.type) {
		case "change":
			return {
				...state,
				[action.payload.name]: action.payload.value,
			};
		case "changeMessageInfo":
			return {
				...state,
				loading: false,
				info: action.payload,
			};
		case "changeLoading":
			return {
				...state,
				loading: action.payload,
			};
		default:
			return state;
	}
};
