import { StandardCategoryDetail } from "..";

export const cleansingDetail: StandardCategoryDetail = {
	inUse: {
		standards: [
			{ id: 1, text: "容器から出したときの見た目・色がよい" },
			{ id: 2, text: "肌へのなじみがよい" },
			{ id: 3, text: "メイクとのなじみがよい" },
		],
		canAdd: true,
	},
	afterUse: {
		standards: [
			{ id: 1, text: "汚れ落ちがよい" },
			{ id: 2, text: "メイク汚れが全体にきちんと落ちたと感じる" },
			{ id: 3, text: "肌がみずみずしい" },
			{ id: 4, text: "肌にうるおいがある" },
			{ id: 5, text: "肌がしっとりする" },
			{ id: 6, text: "肌がさっぱりする" },
			{ id: 7, text: "肌がやわらかい" },
			{ id: 8, text: "肌がなめらかになる" },
			{ id: 9, text: "肌がかさつかない" },
			{ id: 10, text: "肌がつるつるになる" },
			{ id: 11, text: "肌がすべすべになる" },
			{ id: 12, text: "肌がサラサラになる" },
			{ id: 13, text: "肌がつっぱらない" },
			{ id: 14, text: "肌がすっきりした" },
			{ id: 15, text: "自分の肌のタイプに合う" },
			{ id: 16, text: "肌が明るくなる" },
			{ id: 17, text: "肌にやさしい" },
		],
		canAdd: true,
	},
};
