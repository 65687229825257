import React, { useReducer } from "react";
import { AggregateJobListPage } from "../../../components/pages/aggregates/list";
import { Key } from "@lu/repository";
import { useParams } from "react-router";
import { apiEndpoint } from "../../../../../server/router/api/endpoint";
import { AggregateJobTarget } from "../../../../../server/types/request/aggregate/redirect";
import { reducer, initState } from "../../../reducers/aggregates/list";
import { fileDownload, get, makeError } from "../../../lib/request";
import { GetAggregatgeResponse } from "../../../../../server/types/request/aggregate/get";
import { LoadingToast } from "../../../components/parts/loadings";
import { ConfirmBox } from "../../../components/parts/message/confirm-box";
import { DownloadAggregateJobTarget } from "../../../../../server/types/request/aggregate/download";
import { CustomAggregateJobWithInfo } from "../../../../../server/repositories/custom-aggregate-job/model";

export const AggregateJobContainer: React.FC = () => {
	const [state, dispatch] = useReducer(reducer, initState);
	const { surveyBrandId } = useParams<{ surveyBrandId?: string }>();

	const handleEvoke = React.useCallback((id: Key, target: AggregateJobTarget) => {
		const childWindow = window.open(`${apiEndpoint.aggregateJob}/${id}/${target}`);
		const timer = setInterval(() => {
			if (childWindow.closed) {
				clearInterval(timer);
				location.reload();
			}
		}, 500);
	}, []);
	const handleDownload = React.useCallback(
		(aggregateJob: CustomAggregateJobWithInfo, target: DownloadAggregateJobTarget) => {
			dispatch({ type: "changeLoading", payload: true });
			const url = `${apiEndpoint.aggregateJob}/${aggregateJob._id}/download/${target}`;
			const filename = `${aggregateJob.name}-${target}.csv`;
			fileDownload(url, filename)
				.then(() => dispatch({ type: "changeLoading", payload: false }))
				.catch((error) => {
					dispatch({ type: "changeLoading", payload: false });
					console.error(error);
				});
		},
		[]
	);
	React.useEffect(() => {
		get<GetAggregatgeResponse>(
			surveyBrandId ? `${apiEndpoint.aggregateJob}/${surveyBrandId}` : `${apiEndpoint.aggregateJob}`
		)
			.then((response) => {
				dispatch({ type: "aggregateJobs", payload: response.data });
			})
			.catch((error) => {
				dispatch({
					type: "changeMessageInfo",
					payload: makeError(error),
				});
			});
	}, [surveyBrandId]);
	return (
		<>
			<LoadingToast show={state.loading} />
			<ConfirmBox info={state.info} />
			<AggregateJobListPage
				onEvoke={handleEvoke}
				surveyBrand={state.surveyBrand}
				aggregateJobs={state.aggregateJobs}
				onDownload={handleDownload}
			/>
		</>
	);
};
