import { AccountWithoutPassword } from "../../../../../server/repositories/account/model";
import { AccountCreateAction } from "../../../types/actions";
import { MessageInfo } from "../../../types/info";

export type State = {
	loading: boolean;
	info: MessageInfo;
	account: Partial<AccountWithoutPassword>;
};

export const initState: State = {
	info: { message: "", isSuccess: true },
	loading: true,
	account: {},
};

export const reducer = (state: State, action: AccountCreateAction): State => {
	switch (action.type) {
		case "changeMessageInfo":
			return {
				...state,
				loading: false,
				info: action.payload,
			};
		case "changeLoading":
			return {
				...state,
				loading: action.payload,
			};
		case "loadAccount":
			return {
				...state,
				loading: false,
				...action.payload,
			};
		case "change":
			return {
				...state,
				account: {
					...state.account,
					[action.payload.name]: action.payload.value,
				},
			};
		case "upsert":
			return {
				...state,
				...action.payload,
				loading: false,
				info: {
					isSuccess: true,
					message: `アカウントの${state.account._id ? "更新" : "作成"}が完了しました。`,
				},
			};
		default:
			return state;
	}
};
