import { DefaultSchema, Key, WithoutAutoGenerateColumn } from "@lu/repository";
import { Brand } from "../brand/model";
import { CategoryCustomConfig, CategoryType } from "./category";

export type Age = {
	from: number;
	to: number;
};

export type ImageInfo = {
	url: string;
};

export const surveyStatus = {
	inPreparation: "準備中",
	progress: "進行中",
	complete: "調査完了",
} as const;

export type SurveyStatus = keyof typeof surveyStatus;
export const surveyStatusKeys = Object.keys(surveyStatus) as SurveyStatus[];

export const productType = {
	newProduct: "新製品",
	existingProduct: "既存製品",
	competitiveProduct: "競合品",
} as const;
export type ProductType = keyof typeof productType;
export const productTypeKeys = Object.keys(productType) as ProductType[];

/**
 * 本来はテスト製品 --- 競合品なので、1レコード内にまとめるのが理想だが、
 * 初回リリースからの変更への対応のため、2レコードのままとする
 */
export type SurveyBrand = {
	jobId: Key;

	productType: ProductType;
	no: number; // 調査番号
	brandId: Key;
	brandName?: string;
	productName: string;
	categoryType: CategoryType;
	releaseDate: string;
	status: SurveyStatus; // ブランドごとにステータスを持つ
	age: Age;
	imageInfo: ImageInfo;
	memo?: string; // メモはなければなし

	hasFragrance: boolean;
	// custom項目
	customConfig?: CategoryCustomConfig;

	// dummyフラグ
	isDummy?: boolean;
} & DefaultSchema;

export type SurveyBrandWithBrand = SurveyBrand & {
	brand: Brand;
};

export type SurveyBrandPair = {
	target: SurveyBrand;
	competitor?: SurveyBrand;
};

export type SurveyBrandPairResuqest = {
	target: WithoutAutoGenerateColumn<SurveyBrand>;
	competitor?: WithoutAutoGenerateColumn<SurveyBrand>;
};
