import { AccountWithoutPassword } from "../../../../../server/repositories/account/model";
import { AccountAction } from "../../../types/actions";
import { MessageInfo } from "../../../types/info";

export type State = {
	loading: boolean;
	info: MessageInfo;
	accounts: AccountWithoutPassword[];
	searchValues: { [key: string]: any };
};

export const initState: State = {
	info: { message: "", isSuccess: true },
	loading: true,
	accounts: [],
	searchValues: {},
};

export const reducer = (state: State, action: AccountAction): State => {
	switch (action.type) {
		case "changeMessageInfo":
			return {
				...state,
				loading: false,
				info: action.payload,
			};
		case "changeLoading":
			return {
				...state,
				loading: action.payload,
			};
		case "loadAccounts":
			return {
				...state,
				loading: false,
				...action.payload,
			};
		case "changeSearchValues":
			return {
				...state,
				searchValues: {
					...state.searchValues,
					[action.payload.name]: action.payload.value,
				},
			};
		default:
			return state;
	}
};
