import * as React from "react";
import { JobListPage, JobListPageProps } from "../../../components/pages/job/list";
import { LoadingToast } from "../../../components/parts/loadings";
import { ConfirmBox } from "../../../components/parts/message/confirm-box";
import { fileDownloadStream, get, makeError } from "../../../lib/request";
import { initState, reducer } from "../../../reducers/job/list";
import { apiEndpoint } from "../../../../../server/router/api/endpoint";
import { GetJobListResponse } from "../../../../../server/types/request/job";
import { endpoint } from "../../../routes/endpoint";
import { useHistory, useParams } from "react-router";
import { GetApproveByJobIdResponse } from "../../../../../server/types/request/approve";
import { SurveyBrand } from "../../../../../server/repositories/survey-brand/model";

export const JobListContainer: React.FC = () => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { loading, info, ...jobCorrectProps } = state;
	const history = useHistory();

	const { jobId } = useParams<{ jobId?: string }>();

	React.useEffect(() => {
		const requestUrl = jobId ? `${apiEndpoint.loadJob}/${jobId}` : apiEndpoint.loadJob;
		get<GetJobListResponse>(requestUrl)
			.then((response) => dispatch({ type: "loadJobs", payload: response.data }))
			.catch((error) => dispatch({ type: "changeMessageInfo", payload: makeError(error) }));
	}, [jobId]);
	const onChangeJobPage = React.useCallback<JobListPageProps["onChangeJobPage"]>((jobId, page) => {
		switch (page) {
			case "edit":
				return history.push(`${endpoint.jobEdit}/${jobId}`);
			case "preview":
				return window.open(`${apiEndpoint.redirectPreview}/${jobId}`);
			case "debug":
				return window.open(`${apiEndpoint.redirectDebug}/${jobId}`);
			case "application":
				return get<GetApproveByJobIdResponse>(`${apiEndpoint.jobApprove}/${jobId}`).then((response) => {
					return history.push(`${endpoint.approve}/${response.data.application._id}`);
				});
		}
	}, []);
	const surveyBrandMap = React.useMemo(
		() =>
			jobCorrectProps.jobs.reduce((a, b) => {
				b.surveyBrands.map((surveyBrand) => a.set(surveyBrand._id.toString(), surveyBrand));
				return a;
			}, new Map<string, SurveyBrand>()),
		[jobCorrectProps.jobs]
	);
	const onChangeSearchValues = React.useCallback<JobListPageProps["onChangeSearchValues"]>(
		(name, value) => dispatch({ type: "changeSearchValues", payload: { name, value } }),
		[]
	);
	const onChangeSurveyBrandPage = React.useCallback<JobListPageProps["onChangeSurveyBrandPage"]>(
		(surveyBrandId, page) => {
			if (page === "result") return history.push(`${endpoint.cross}/${surveyBrandId}`);
			const surveyBrand = surveyBrandMap.get(surveyBrandId.toString());
			if (page === "view") {
				return history.push(`${endpoint.surveyBrand}/${surveyBrand.jobId}/${surveyBrandId}`);
			}
			dispatch({ type: "changeLoading", payload: true });
			const requestUrl = page === "rawdata" ? apiEndpoint.downloadRawdata : apiEndpoint.downloadQuestionSheet;
			const filename = `#${surveyBrand.no}${surveyBrand.productName}-${page}.csv`;
			fileDownloadStream(`${requestUrl}/${surveyBrandId}`, filename)
				.then(() => dispatch({ type: "changeLoading", payload: false }))
				.catch((error) => dispatch({ type: "changeMessageInfo", payload: makeError(error) }));
		},
		[surveyBrandMap]
	);
	return (
		<>
			<LoadingToast show={loading} />
			<ConfirmBox info={info} />
			<JobListPage
				{...jobCorrectProps}
				isTargetJob={!!jobId}
				onChangeJobPage={onChangeJobPage}
				onChangeSurveyBrandPage={onChangeSurveyBrandPage}
				onChangeSearchValues={onChangeSearchValues}
			/>
		</>
	);
};
