import React, { FunctionComponent, useMemo } from "react";
import Select, { ActionMeta } from "react-select";

type Props = {
	isDisabled?: boolean;
	isMulti?: boolean;
	isSearchable?: boolean;
	options: { label: string; value: string | number }[];
	placeholder?: string;
	value?: string | number | (string | number)[];
	onChange?: (
		value:
			| {
				label: string;
				value: string | number;
			}
			| {
				label: string;
				value: string | number;
			}[],
		action: ActionMeta<{
			label: string;
			value: string | number;
		}>
	) => void;
};

export const ReactSelect: FunctionComponent<Props> = React.memo(
	({ isDisabled, isMulti, isSearchable, options, placeholder, value, onChange }) => {
		const _value = useMemo(
			() => (typeof value === "object" ? value : [value]).map((val) => options.find((option) => option.value === val)),
			[options, value]
		);

		return (
			<Select
				className="react-select"
				classNamePrefix="react-select"
				isMulti={isMulti}
				isDisabled={isDisabled}
				isSearchable={isSearchable}
				options={options}
				onChange={onChange}
				placeholder={placeholder || "選択してください"}
				value={_value}
			/>
		);
	}
);
