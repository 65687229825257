import * as React from "react";
import { Table } from "react-bootstrap";
import { surveyBrandLabel } from "../../../../../../label";
import { Brand } from "../../../../../../server/repositories/brand/model";
import { categoryType } from "../../../../../../server/repositories/survey-brand/category";
import { productType, SurveyBrand } from "../../../../../../server/repositories/survey-brand/model";

export type SurveyBrandListProps = {
	surveyBrands: SurveyBrand[];
	brands: Brand[];
};
export const SurveyBrandList: React.FC<SurveyBrandListProps> = React.memo(({ surveyBrands, brands }) => {
	const brandMap = React.useMemo(() => {
		return brands.reduce((a, b) => {
			a.set(b._id.toString(), b);
			return a;
		}, new Map<string, Brand>());
	}, [brands]);
	return (
		<Table>
			<thead>
				<tr>
					<th>{surveyBrandLabel.brandId}</th>
					<th>{surveyBrandLabel.categoryType}</th>
					<th>{surveyBrandLabel.productType}</th>
					<th>{surveyBrandLabel.productName}</th>
					<th>{surveyBrandLabel.releaseDate}</th>
				</tr>
			</thead>
			<tbody>
				{surveyBrands.map((surveyBrand) => (
					<tr key={`surveyBrand-${surveyBrand.brandId}`}>
						<td>{brandMap.get(surveyBrand.brandId.toString())?.name || ""}</td>
						<td>{categoryType[surveyBrand.categoryType]}</td>
						<td>{productType[surveyBrand.productType]}</td>
						<td>{surveyBrand.productName}</td>
						<td>{surveyBrand.releaseDate}</td>
					</tr>
				))}
			</tbody>
		</Table>
	);
});
