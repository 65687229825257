import React, { CSSProperties, useCallback, useReducer } from "react";
import { ResultViewType } from "../../../../types/result-view-type";
import { CrossObj } from "../../../../lib/aggregate/converter";
import { reducer, State } from "../../../../reducers/parts/aggregates/cross-area";
import classNames from "classnames";

const hasOneOf = <T,>(set: Set<T>, values: T[]): boolean => {
	return values.reduce((accumulator, value) => accumulator || set.has(value), false);
};

export type CrossViewProps = {
	cross: CrossObj;
	style?: CSSProperties;
	viewType: ResultViewType;
};

const initState: State = {
	selectedCols: new Set(),
	selectedRows: new Set(),
};

export const CrossArea = React.memo<CrossViewProps>(({ cross, style, viewType: view }) => {
	const { bigTitle, condition, datas, labels, options, qunename, selections, title } = cross;
	const { ns: targetNs, options: targetOptions, percents: targetPercents } = datas;

	const [state, dispatch] = useReducer(reducer, initState);

	const handleClickCell = useCallback(
		(col: number, row: number) => dispatch({ type: "select_cell", payload: { col, row } }),
		[]
	);

	const handleClickColHeader = useCallback((payload: number[]) => dispatch({ type: "select_col", payload }), []);

	const handleClickRowHeader = useCallback((payload: number[]) => dispatch({ type: "select_row", payload }), []);

	return (
		<div className="cross-area" id={`cross-${qunename}${condition}`} style={style}>
			{condition && <div className="cross-area__big-title">{`【${condition}】`}</div>}
			{bigTitle && <div className="cross-area__big-title">{bigTitle}</div>}
			<div className="cross-area__title">
				{qunename}.{title}
			</div>
			<div className="cross">
				<table className="cross__table">
					<thead>
						{/* 軸名称 */}
						{labels.map((ls, axisIndex) => {
							return (
								<tr key={`axis-label-${axisIndex}`} className="cross__head">
									{axisIndex === 0 && (
										<React.Fragment>
											<th rowSpan={labels.length} />
											<th
												rowSpan={labels.length - 1}
												className={classNames("cross__col", {
													"cross__col--selected": state.selectedCols.has(0),
												})}
												onClick={handleClickColHeader.bind(undefined, [0])}
											/>
										</React.Fragment>
									)}
									{labels.length === axisIndex + 1 && (
										<th
											className={classNames("cross__col", {
												"cross__col--selected": state.selectedCols.has(0),
											})}
											onClick={handleClickColHeader.bind(undefined, [0])}
										>
											全体
										</th>
									)}
									{ls.map(({ label, colSpan, rowSpan, indexes }, labelIndex) => {
										return (
											<th
												className={classNames("cross__col", {
													"cross__col--selected": hasOneOf(state.selectedCols, indexes),
												})}
												onClick={handleClickColHeader.bind(undefined, indexes)}
												key={`axis-label-${axisIndex}-${labelIndex}`}
												rowSpan={rowSpan || 1}
												colSpan={colSpan || 1}
											>
												{label}
											</th>
										);
									})}
								</tr>
							);
						})}
					</thead>
					<tbody>
						{/* values area */}
						{selections.map((selection, selectionIndex) => {
							const ns = targetNs[selectionIndex];
							const percents = targetPercents[selectionIndex];
							return (
								<React.Fragment key={`selection-${selectionIndex}`}>
									{(view === "n" || view === "both" || selectionIndex === 0) && !!ns && (
										<tr
											className={classNames("cross__row", {
												"cross__row--selected": state.selectedRows.has(selectionIndex * 2),
											})}
										>
											<td
												className={classNames(
													"cross__selection-item",

													{
														"cross__row--selected": hasOneOf(state.selectedRows, [
															selectionIndex * 2,
															selectionIndex * 2 + 1,
														]),
													}
												)}
												rowSpan={view === "both" && !!percents ? 2 : 1}
												onClick={handleClickRowHeader.bind(undefined, [selectionIndex * 2, selectionIndex * 2 + 1])}
											>
												{selection}
											</td>
											{ns.map((n, nIndex) => (
												<td
													key={`selection-${selectionIndex}-n-${nIndex}`}
													className={classNames("cross__n", {
														"cross__col--selected": state.selectedCols.has(nIndex),
													})}
													onClick={handleClickCell.bind(undefined, nIndex, selectionIndex * 2)}
												>
													{n}
												</td>
											))}
										</tr>
									)}
									{((view === "percent" && selectionIndex !== 0) || view === "both") && !!percents && (
										<tr
											className={classNames("cross__row", {
												"cross__row--selected": state.selectedRows.has(selectionIndex * 2 + 1),
											})}
										>
											{view === "percent" && (
												<td
													className={classNames(
														"cross__selection-item",

														{
															"cross__row--selected": hasOneOf(state.selectedRows, [
																selectionIndex * 2,
																selectionIndex * 2 + 1,
															]),
														}
													)}
													onClick={handleClickRowHeader.bind(undefined, [selectionIndex * 2, selectionIndex * 2 + 1])}
												>
													{selection}
												</td>
											)}
											{percents.map((percent, pIndex) => (
												<td
													key={`selection-${selectionIndex}-percent-${pIndex}`}
													className={classNames("cross__percent", {
														"cross__col--selected": state.selectedCols.has(pIndex),
													})}
													onClick={handleClickCell.bind(undefined, pIndex, selectionIndex * 2 + 1)}
												>
													{percent}
												</td>
											))}
										</tr>
									)}
								</React.Fragment>
							);
						})}
						{/* 集計オプションエリア */}
						{options.length > 0 &&
							!!targetOptions &&
							targetOptions.length > 0 &&
							options.map((option, oIndex) => (
								<tr
									key={`options-${oIndex}-option`}
									className={classNames("cross__row", {
										"cross__row--selected": state.selectedRows.has(selections.length * 2 + oIndex),
									})}
								>
									<td onClick={handleClickRowHeader.bind(undefined, [selections.length * 2 + oIndex])}>{option}</td>
									{targetOptions[oIndex].map((n, nIndex) => (
										<td
											key={`options-${oIndex}-option-${nIndex}`}
											className={classNames("cross__n", {
												"cross__col--selected": state.selectedCols.has(nIndex),
											})}
											onClick={handleClickCell.bind(undefined, nIndex, selections.length * 2 + oIndex)}
										>
											{n}
										</td>
									))}
								</tr>
							))}
					</tbody>
				</table>
			</div>
		</div>
	);
});
