import * as React from "react";
import { ConfirmInfo } from "../confirm-info";
import { MessageInfo } from "../../../../types/info";
import classnames from "classnames";

export type ConfirmBoxProps = {
	info: MessageInfo;
	onClose?: () => void;
};
export const ConfirmBox = React.memo((props: ConfirmBoxProps) => {
	const { info, onClose } = props;
	const [show, setShow] = React.useState<boolean>();
	React.useEffect(() => {
		// 成功ステータスで、messageがない場合は表示しない。
		if (info.isSuccess && !info.message) return;
		setShow(true);
	}, [info]);
	const title = React.useMemo(() => {
		if (info.isSuccess) {
			return <div className={"confirm-message__title"}>処理完了</div>;
		}
		return <div className={classnames("confirm-message__title", "confirm-message__title--error")}>処理エラー</div>;
	}, [info.isSuccess]);
	const onCloseClick = React.useCallback(() => {
		setShow(false);
		if (info.isSuccess && !!onClose) {
			onClose();
		}
	}, [onClose, info.isSuccess]);
	return <ConfirmInfo title={title} message={info.message} show={show} onClose={onCloseClick} />;
});
