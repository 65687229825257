import { QuestionConfig } from "..";

export const mascaraConfig: QuestionConfig[] = [
	{ queNumber: 1, content: "intentionToContinuePurchasing" },
	{ queNumber: 2, content: "reasonForIntentionToContinuePurchasing" },
	{ queNumber: 3, content: "intentionToContinuePurchasingTheBrand" },
	{ queNumber: 4, content: "worth" },
	{ queNumber: 5, content: "brandImage" },

	{ queNumber: 6, content: "usabilityEvaluation" },
	{ queNumber: 7, content: "satisfaction" },
	{ queNumber: 8, content: "inUse" },

	{ queNumber: 9, content: "afterUse" },

	{ queNumber: 10, content: "afterFourHours" },

	{ queNumber: 11, content: "afterClean" },

	{ queNumber: 12, content: "fragrancePreference" },
	{ queNumber: 13, content: "fragranceIntensity" },
	{ queNumber: 14, content: "containerComprehensiveEvaluation" },
	{ queNumber: 15, content: "containerdetailedEvaluation" },
	{ queNumber: 16, content: "dissatisfactionImprovement" },
	{ queNumber: 17, content: "channel" },
	{ queNumber: 18, content: "contactExperience" },
	{ queNumber: 19, content: "mostContactExperience" },
	{ queNumber: 20, content: "bc" },
	{ queNumber: 21, content: "bcContent" },
	{ queNumber: 22, content: "annualCosmeticsSpending" },
	{ queNumber: 23, content: "personalAnnualIncome" },
	{ queNumber: 24, content: "householdIncome" },
];
