import classNames from "classnames";
import * as React from "react";
import { useState } from "react";
import Card from "react-bootstrap/Card";
import { Spinner } from "react-bootstrap";

export type LoadingToastProps = { show: boolean; text?: string };

export const LoadingToast: React.FC<LoadingToastProps> = React.memo(({ show, text = "updating..." }) => {
	const [initialzied, setInitialzied] = useState(true);

	React.useEffect(() => {
		setInitialzied(!show && initialzied);
	}, [show]);

	return (
		<div
			className={classNames("loading-toast", {
				"loading-toast--show": show,
				"loading-toast--hide": !show && !initialzied,
			})}
		>
			<Card>
				<Card.Body>
					<span>
						<Spinner animation="border" size="sm" variant="secondary" />
						{text}
					</span>
				</Card.Body>
			</Card>
		</div>
	);
});
