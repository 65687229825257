import { StandardCategoryDetail } from "..";

export const eyelinerDetail: StandardCategoryDetail = {
	inUse: {
		standards: [
			{ id: 1, text: "太さがちょうどよい" },
			{ id: 2, text: "描き心地がなめらか" },
			{ id: 3, text: "細かいラインが描きやすい" },
			{ id: 4, text: "自分好みのラインが描きやすい" },
			{ id: 5, text: "均一の太さに描ける" },
			{ id: 6, text: "簡単に描ける" },
			{ id: 7, text: "まぶたにフィットする" },
		],
		canAdd: true,
	},
	afterUse: {
		standards: [
			{ id: 1, text: "色の濃さがちょうどよい" },
			{ id: 2, text: "乾きが早い" },
			{ id: 3, text: "にじまない" },
			{ id: 4, text: "下まぶたに付着していない" },
			{ id: 5, text: "目が大きく見える" },
			{ id: 6, text: "目もとの印象・インパクトが増す" },
			{ id: 7, text: "自然な目もとに仕上がる" },
		],
		canAdd: true,
	},
	afterFourHours: {
		standards: [
			{ id: 1, text: "色が薄れない" },
			{ id: 2, text: "にじまない" },
			{ id: 3, text: "仕上がりが持続する" },
			{ id: 4, text: "発色が持続する" },
			{ id: 5, text: "下まぶたに付着していない" },
		],
		canAdd: true,
	},
};
