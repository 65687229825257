import * as React from "react";
import classnames from "classnames";
import { Job } from "../../../../../../server/repositories/job/model";
import { CustomOption, option2Map, option2OptionMap } from "../../../../../../server/lib/option";
import { ValidationInput } from "../../../parts/validation-input";
import { surveyBrandLabel } from "../../../../../../label";
import { BrandRegister } from "./brand-register";
import { Key } from "@lu/repository";
import { Alert, Button, Card } from "react-bootstrap";
import { ErrorObject } from "@lu/validator";
import { getErrorText } from "../../../../lib/error";
import { Application } from "../../../../../../server/repositories/application/model";
import { AccountWithoutPassword } from "../../../../../../server/repositories/account/model";
import { RegisterJob, RegisterSurveyBrandPair } from "../../../../reducers/job/create";
import { SurveyBrand } from "../../../../../../server/repositories/survey-brand/model";
import { TabViwer, TabViwerItem } from "../../../parts/tabs";
import {
	CategoryCustomConfigValues,
	CategoryCustomKey,
} from "../../../../../../server/repositories/survey-brand/category";

export type SubmitOption = "preview" | "application" | "temporary";

export type JobCreatePageProps = {
	loginAccount: AccountWithoutPassword;
	onChangeBrands: (selecteds: Key[]) => void;
	onAddProduct: (brandId: Key, index: number) => void;
	onDeleteProduct: (index: number) => void;
	application?: Application;
	onImageChangeSurveyBrand: (index: number, file: File | undefined) => void;
	onImageChangeCompetitorSurveyBrand: (index: number, file: File | undefined) => void;
	onChangeSurveyBrand: <K extends keyof SurveyBrand>(index: number, name: K, value: SurveyBrand[K]) => void;
	onChangeCompetitorSurveyBrand: <K extends keyof SurveyBrand>(index: number, name: K, value: SurveyBrand[K]) => void;
	onChangeCustomSurveyBrand: <K extends keyof CategoryCustomConfigValues>(
		index: number,
		targetField: CategoryCustomKey,
		target: K,
		values: CategoryCustomConfigValues[K]
	) => void;
	onChangeActiveCompetitor: (index: number, active: boolean) => void;
	onSubmit: (submitOption?: SubmitOption) => void;
	onApprovePage: () => void;
	onChangeJob: <K extends keyof Job>(name: K, value: Job[K]) => void;
	brandOptions: CustomOption[];
	job: RegisterJob;
	surveyBrandPairs: RegisterSurveyBrandPair[];
	errors?: ErrorObject;
};

export const JobCreatePage: React.FC<JobCreatePageProps> = React.memo(
	({
		loginAccount,
		application,
		brandOptions,
		job,
		surveyBrandPairs,
		onChangeSurveyBrand,
		onChangeCompetitorSurveyBrand,
		onAddProduct,
		onDeleteProduct,
		onImageChangeSurveyBrand,
		onImageChangeCompetitorSurveyBrand,
		onChangeBrands,
		onSubmit,
		onApprovePage,
		onChangeCustomSurveyBrand,
		onChangeActiveCompetitor,
		errors,
	}) => {
		const selecteds = React.useMemo(
			() => Array.from(new Set(surveyBrandPairs.map(({ target }) => target.brandId))).filter((brandId) => !!brandId),
			[surveyBrandPairs]
		);
		const brandMap = React.useMemo(() => option2OptionMap(brandOptions), [brandOptions]);
		const onPreview = React.useCallback(() => onSubmit("preview"), [onSubmit]);
		const onApplication = React.useCallback(() => onSubmit("application"), [onSubmit]);
		const onTemporarySave = React.useCallback(() => onSubmit("temporary"), [onSubmit]);
		const canApplication = React.useMemo(() => !application || application.status === "reject", [application]);
		const canApprove = React.useMemo(
			() => !!application && application.status === "applying" /* &&
				loginAccount._id.toString() === application.approver.toString()*/,
			[loginAccount, application]
		);
		const message = React.useMemo(() => {
			if (!application) return <></>;
			switch (application.status) {
				case "applying":
					return <Alert variant={"dark"}>申請中です</Alert>;
				case "approved":
					return <Alert variant={"dark"}>承認済みです</Alert>;
				case "reject":
					return (
						<Alert variant={"danger"}>
							却下されています。以下メッセージを確認の上、再度申請をお願いします。
							<br />
							{application.rejectMessage}
						</Alert>
					);
			}
		}, [application]);
		const tabs = React.useMemo(() => {
			if (selecteds.length === 0) return <div className={"message"}>ブランドを選択すると設定項目が表示されます。</div>;
			const items: TabViwerItem[] = selecteds.map((selected) => {
				const key = selected.toString();
				const brandOption = brandMap.get(key);
				const title = brandOption.label;
				const elements = surveyBrandPairs.map((surveyBrandPair, index) => {
					if (surveyBrandPair.target.brandId.toString() !== key) return <React.Fragment key={`${key}-${index}`} />;
					return (
						<BrandRegister
							key={`brand-register-${surveyBrandPair.target.brandId}-${index}`}
							index={index}
							requiredBrandName={brandOption.requiredBrandName}
							surveyBrand={surveyBrandPair.target}
							competitorSurveyBrand={surveyBrandPair.competitor}
							onChangeActiveCompetitor={onChangeActiveCompetitor}
							onAddProduct={onAddProduct}
							onDeleteProduct={onDeleteProduct}
							onChangeCustom={onChangeCustomSurveyBrand}
							onChange={onChangeSurveyBrand}
							onChangeCompetitor={onChangeCompetitorSurveyBrand}
							onImageChange={(value) => onImageChangeSurveyBrand(index, value)}
							onImageChangeCompetitor={(value) => onImageChangeCompetitorSurveyBrand(index, value)}
							errors={errors}
						/>
					);
				});
				return { title, key, elements };
			});
			return (
				<div className={"m-2"}>
					<div className={classnames("m-2")}>
						<div className={classnames("sub-title")}>調査製品登録​</div>
						<TabViwer items={items} />
					</div>
				</div>
			);
		}, [
			selecteds,
			surveyBrandPairs,
			brandMap,
			onChangeSurveyBrand,
			onChangeCompetitorSurveyBrand,
			onAddProduct,
			onDeleteProduct,
			errors,
		]);
		return (
			<>
				{message}
				<div className={classnames("main-title")}>{job._id ? "登録製品更新" : "新規登録製品"}</div>
				<div className={"m-2"}>
					<div className={classnames("sub-title")}>基本設定</div>
					<div className={classnames("form-area")}>
						<ValidationInput
							name={"brandId"}
							inputtype={"multiple-select"}
							selecteds={selecteds}
							options={brandOptions}
							onChange={onChangeBrands}
							label={`対象${surveyBrandLabel.brandId}選択`}
							error={getErrorText(errors, "brandId")}
							noblock
						/>
					</div>
					{tabs}
				</div>
				<div className={classnames("button-area")}>
					<Button variant={"outline-secondary"} onClick={onPreview}>
						プレビュー
					</Button>
					{canApplication && (
						<>
							<Button variant={"outline-secondary"} onClick={() => onSubmit()}>
								保存
							</Button>
							<Button variant={"outline-secondary"} onClick={onTemporarySave}>
								一時保存
							</Button>
							<Button variant={"outline-secondary"} onClick={onApplication}>
								承認申請
							</Button>
						</>
					)}
					{canApprove && (
						<Button variant={"outline-secondary"} onClick={onApprovePage}>
							承認ページ
						</Button>
					)}
				</div>
			</>
		);
	}
);
