import { ErrorObject } from "@lu/validator";
import * as React from "react";
import { FormGroup, Row, FormLabel, Col } from "react-bootstrap";
import {
	CategoryCustomConfigValues,
	CategoryCustomKey,
	CustomChoiceConfig,
} from "../../../../../../../server/repositories/survey-brand/category";
import { questionContent } from "../../../../../../../server/muscat/enquete/main/config";
import { getErrorObject, getErrorText } from "../../../../../lib/error";
import { ArrayView } from "../../../../parts/array-view";
import { ModalViewButton } from "../../../../parts/modal-view-button";
import { CustomInput } from "../../../../parts/validation-input";
import classnames from "classnames";

export type CustomConfigInputProps = {
	target: CategoryCustomKey;
	onChangeOptions: (target: CategoryCustomKey, options: number[]) => void;
	onChangeAdditionalChoices: (target: CategoryCustomKey, values: string[]) => void;
	config: CustomChoiceConfig;
	errors?: ErrorObject;
	values: CategoryCustomConfigValues;
};

export const CustomConfigInput: React.FC<CustomConfigInputProps> = React.memo(
	({ target, onChangeOptions, onChangeAdditionalChoices, config, values, errors }) => {
		const { options = [], additionalChoices = [] } = values;
		if (!config.canAdd && (!config.options || !config.options.length)) return <></>;
		const optionList = React.useMemo(
			() => (config.options ? config.options.map(({ id, text }) => ({ value: id, label: text })) : []),
			[config.options]
		);
		const standardList = React.useMemo(() => config.standards.map(({ text }) => text), [config.standards]);
		const handleChangeOptions = React.useCallback(
			(values: number[]) => {
				onChangeOptions(target, values);
			},
			[target, onChangeOptions]
		);
		const handleChangeAdditionalChoices = React.useCallback(
			(values: string[]) => {
				onChangeAdditionalChoices(target, values);
			},
			[target, onChangeAdditionalChoices]
		);
		return (
			<>
				<FormGroup as={Row}>
					<Col md={2}>
						<FormLabel>{questionContent[target]}</FormLabel>
						<ModalViewButton
							variant={"outline-secondary"}
							size={"sm"}
							label={"項目確認"}
							header={`${questionContent[target]}標準項目`}
							body={<ArrayView values={standardList} />}
						/>
					</Col>
					<Col md={10}>
						<Row>
							{config.options && !!config.options.length && (
								<Col md={6}>
									<FormLabel className={classnames("brand-register__option-label")}>オプション項目</FormLabel>
									<CustomInput
										inputtype={"checkboxes"}
										name={`surveyBrandLabel-options`}
										checkeds={options}
										onChange={handleChangeOptions}
										options={optionList}
										error={getErrorText(errors, "options")}
									/>
								</Col>
							)}
							{config.canAdd && (
								<Col md={6}>
									<FormLabel className={classnames("brand-register__option-label")}>任意項目</FormLabel>
									<CustomInput
										inputtype={"multiple-text"}
										name={`surveyBrandLabel-additionalChoices`}
										values={additionalChoices || []}
										onChange={handleChangeAdditionalChoices}
										errors={getErrorObject(errors, "additionalChoices")}
									/>
								</Col>
							)}
						</Row>
					</Col>
				</FormGroup>
			</>
		);
	}
);
