import * as React from "react";
import { Dropdown } from "react-bootstrap";
import { SettingIcon } from "../icons";

export type SettingMenuItem = {
	label: string;
	event: () => void;
};

export type SettingMenuProps = {
	items: SettingMenuItem[];
};

export const SettingMenu: React.FC<SettingMenuProps> = React.memo(({ items }) => {
	return (
		<Dropdown>
			<Dropdown.Toggle as={"span"}>
				<SettingIcon />
			</Dropdown.Toggle>
			<Dropdown.Menu>
				{items.map(({ label, event }, index) => (
					<Dropdown.Item key={`item-${index}`} onClick={event}>
						{label}
					</Dropdown.Item>
				))}
			</Dropdown.Menu>
		</Dropdown>
	);
});
