import { AccountWithoutPassword } from "../../repositories/account/model";

export class Role {
	/**
	 * 管理者権限かどうか
	 * @param {AccountWithoutPassword} account
	 * @returns {boolean}
	 */
	public static isAdmin(account: AccountWithoutPassword): boolean {
		return account.role === "admin";
	}
	/**
	 * 承認者権限かどうか
	 *  - 管理者
	 *  - 承認者
	 * @param {AccountWithoutPassword} account
	 * @returns {boolean}
	 */
	public static isApprover(account: AccountWithoutPassword): boolean {
		if (Role.isAdmin(account)) return true;
		return account.role === "approver";
	}
}
