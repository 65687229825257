import { Label, DefaultLabel } from "./label";
import { Application } from "../server/repositories/application/model";

export const applicationLabel: Label<Application> = {
	...DefaultLabel,
	applicant: "申請者",
	approver: "承認者",
	jobId: "ジョブ",
	message: "申請メッセージ",
	status: "承認ステータス",
	rejectMessage: "却下理由",
};
