import * as React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { endpoint } from "../../../routes/endpoint";

export type LogoutPageProps = {
	changeLocation: () => void;
};
export const LogoutPage: React.FC<LogoutPageProps> = React.memo(({ changeLocation }) => {
	return (
		<div className="logout-page">
			<Card className="logout-page__card">
				<Card.Body>
					<div className="text-center">
						<div className="logout-page__card__headline">ログアウトしました。</div>
						<div className="logout-page__card__message">
							再度ログインする場合は、ログインページからアクセスしてください。
						</div>
						<Button variant={"secondary"} onClick={changeLocation}>
							ログインする
						</Button>
					</div>
				</Card.Body>
			</Card>
		</div>
	);
});
