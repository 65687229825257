import { Key } from "@lu/repository";
import { CustomOption } from "../../../../../server/lib/option";
import { Application } from "../../../../../server/repositories/application/model";
import { Job } from "../../../../../server/repositories/job/model";
import { SurveyBrand } from "../../../../../server/repositories/survey-brand/model";
import { JobCreateAction } from "../../../types/actions";
import { MessageInfo } from "../../../types/info";

export type RegisterJob = Partial<Job>;
export type RegisterSurveyBrand = Partial<Omit<SurveyBrand, "brandId">> & { brandId: SurveyBrand["brandId"] };
export type RegisterSurveyBrandPair = { target: RegisterSurveyBrand; competitor?: RegisterSurveyBrand };

export type State = {
	loading: boolean;
	info: MessageInfo;
	brandOptions: CustomOption[];
	job: RegisterJob;
	surveyBrandPairs: RegisterSurveyBrandPair[];
	application?: Application;
	competitorId: Key;
};

export const initState: State = {
	info: { message: "", isSuccess: true },
	loading: true,
	brandOptions: [],
	job: {},
	surveyBrandPairs: [],
	competitorId: "",
};

const initSurveyBrandPair = (brandId: Key, competitorId: Key): RegisterSurveyBrandPair => {
	return {
		target: { brandId },
		competitor: {
			brandId: competitorId,
			productType: "competitiveProduct",
		},
	};
};
const activateCompetitor = (target: RegisterSurveyBrand, competitorId: Key): RegisterSurveyBrand => {
	const { age, categoryType, customConfig } = target;
	return {
		brandId: competitorId,
		productType: "competitiveProduct",
		age,
		categoryType,
		customConfig,
	};
};

export const reducer = (state: State, action: JobCreateAction): State => {
	switch (action.type) {
		case "changeMessageInfo":
			return {
				...state,
				loading: false,
				info: action.payload,
			};
		case "changeLoading":
			return {
				...state,
				loading: action.payload,
			};
		case "loadJob":
			return {
				...state,
				loading: false,
				...action.payload,
				// warning: ハードコーディング
				competitorId: action.payload.brandOptions.find(({ label }) => label === "競合ブランド").value,
				brandOptions: action.payload.brandOptions.filter(({ label }) => label !== "競合ブランド"),
			};
		case "changeBrands": {
			const newBrands = action.payload.filter((selected) => {
				return !state.surveyBrandPairs.some(({ target }) => target.brandId.toString() === selected.toString());
			});
			const surveyBrandPairs = state.surveyBrandPairs.filter(({ target }) => {
				return action.payload.some((selected) => target.brandId.toString() === selected.toString());
			});
			return {
				...state,
				surveyBrandPairs: [
					...surveyBrandPairs,
					...newBrands.map<RegisterSurveyBrandPair>((selected) => ({
						target: { brandId: selected },
						competitor: {
							brandId: state.competitorId,
							productType: "competitiveProduct",
						},
					})),
				],
			};
		}
		case "addSurveyBrandProduct": {
			const surveyBrandPairs = state.surveyBrandPairs.map((b) => ({ ...b }));
			surveyBrandPairs.splice(action.payload.index, 0, initSurveyBrandPair(action.payload.brandId, state.competitorId));

			return {
				...state,
				surveyBrandPairs,
			};
		}
		case "deleteSurveyBrandProduct": {
			const surveyBrandPairs = [...state.surveyBrandPairs];
			surveyBrandPairs.splice(action.payload, 1);
			return {
				...state,
				surveyBrandPairs,
			};
		}
		case "changeSurveyBrand": {
			return {
				...state,
				surveyBrandPairs: state.surveyBrandPairs.map((surveyBrandPair, index) => {
					if (index !== action.payload.index) return surveyBrandPair;
					const target = { ...surveyBrandPair.target, [action.payload.name]: action.payload.value };
					if (surveyBrandPair.target.categoryType !== target.categoryType) {
						delete target.brandName;
						target.customConfig = {};
					}
					if (
						!surveyBrandPair.competitor ||
						(action.payload.name !== "categoryType" && action.payload.name !== "age")
					) {
						return { target, competitor: surveyBrandPair.competitor };
					}
					const competitor = { ...surveyBrandPair.competitor, [action.payload.name]: action.payload.value };
					if (surveyBrandPair.competitor.categoryType !== competitor.categoryType) {
						delete competitor.brandName;
						competitor.customConfig = {};
					}
					return { target, competitor };
				}),
			};
		}
		case "changeCompetitorActive":
			return {
				...state,
				surveyBrandPairs: state.surveyBrandPairs.map((surveyBrandPair, index) => {
					if (index !== action.payload.index) return surveyBrandPair;
					if (!action.payload.active) return { target: surveyBrandPair.target };
					return {
						target: surveyBrandPair.target,
						competitor: activateCompetitor(surveyBrandPair.target, state.competitorId),
					};
				}),
			};
		case "changeCompetitorSurveyBrand":
			return {
				...state,
				surveyBrandPairs: state.surveyBrandPairs.map((surveyBrandPair, index) => {
					if (index !== action.payload.index || !surveyBrandPair.competitor) return surveyBrandPair;
					const competitor = { ...surveyBrandPair.competitor, [action.payload.name]: action.payload.value };
					if (surveyBrandPair.competitor.categoryType !== competitor.categoryType) {
						delete competitor.brandName;
						competitor.customConfig = {};
					}
					return { target: surveyBrandPair.target, competitor };
				}),
			};
		case "changeCustomSurveyBrand":
			return {
				...state,
				surveyBrandPairs: state.surveyBrandPairs.map((surveyBrandPair, index) => {
					if (index !== action.payload.index) return surveyBrandPair;
					return {
						target: {
							...surveyBrandPair.target,
							customConfig: {
								...surveyBrandPair.target.customConfig,
								[action.payload.targetField]: {
									...(surveyBrandPair.target.customConfig
										? surveyBrandPair.target.customConfig[action.payload.targetField]
										: {}),
									[action.payload.target]: action.payload.values,
								},
							},
						},
						competitor: surveyBrandPair.competitor
							? {
									...surveyBrandPair.competitor,
									customConfig: {
										...surveyBrandPair.competitor.customConfig,
										[action.payload.targetField]: {
											...(surveyBrandPair.competitor.customConfig
												? surveyBrandPair.competitor.customConfig[action.payload.targetField]
												: {}),
											[action.payload.target]: action.payload.values,
										},
									},
							  }
							: undefined,
					};
				}),
			};

		case "changeJob":
			return {
				...state,
				job: {
					...state.job,
					[action.payload.name]: action.payload.value,
				},
			};
		case "upsertJob":
			return {
				...state,
				loading: false,
				...action.payload,
			};
		default:
			return state;
	}
};
