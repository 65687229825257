import * as React from "react";
import { jobStatus, JobWithApplication } from "../../../../../../../server/repositories/job/model";
import { Key } from "@lu/repository";
import { SettingMenu, SettingMenuItem } from "../../../../parts/setting-menu";

export type Page = "edit" | "detail" | "preview" | "debug" | "application" | "view";

export type JobTableRowProps = {
	job: JobWithApplication;
	accountMap: Map<string, string>;
	onChangeJobPage: (jobId: Key, page: Page) => void;
};

export const JobTableRow: React.FC<JobTableRowProps> = React.memo(({ job, accountMap, onChangeJobPage }) => {
	const events = React.useMemo<SettingMenuItem[]>(() => {
		const items: SettingMenuItem[] = [{ label: "ジョブ一覧", event: () => onChangeJobPage(job._id, "detail") }];
		if (job.enqueteId) {
			items.push({ label: "プレビュー", event: () => onChangeJobPage(job._id, "preview") });
		}
		if (job.status === "inPreparation" || job.status === "pending" || job.status === "reject") {
			items.push(
				{ label: "動作画面", event: () => onChangeJobPage(job._id, "debug") },
				{ label: "編集", event: () => onChangeJobPage(job._id, "edit") }
			);
		} else {
			items.push({ label: "設定内容確認", event: () => onChangeJobPage(job._id, "view") });
		}
		if (job.status === "pending" || job.status === "reject") {
			items.push({ label: "承認ページ", event: () => onChangeJobPage(job._id, "application") });
		}
		return items;
	}, [job, onChangeJobPage]);
	return (
		<tr key={`job-${job._id.toString()}`}>
			<td>{job.no}</td>
			<td>{accountMap.get(job.accountId.toString()) || ""}</td>
			<td>{job.surveyStartDate}</td>

			<td>{jobStatus[job.status]}</td>
			<td>{accountMap.get(job.application?.applicant?.toString()) || ""}</td>
			<td>{accountMap.get(job.application?.approver?.toString()) || ""}</td>
			<td>
				<SettingMenu items={events} />
			</td>
		</tr>
	);
});
