import { Label, DefaultLabel } from "./label";
import { JobWithSurveyBrands } from "../server/repositories/job/model";

export const jobLabel: Label<JobWithSurveyBrands> = {
	...DefaultLabel,
	// enqueteId: "アンケートID",
	no: "JobId",
	accountId: "担当者",
	enqueteId: "アンケートID",
	status: "ステータス",
	surveyStartDate: "調査開始日希望日",
	surveyBrands: "調査ブランド",
	surveys: "調査",
};
