import { Application } from "../../../../server/repositories/application/model";
import { Brand } from "../../../../server/repositories/brand/model";
import { JobWithSurveyBrands } from "../../../../server/repositories/job/model";
import { ApproveAction } from "../../types/actions";
import { MessageInfo } from "../../types/info";

export type State = {
	loading: boolean;
	info: MessageInfo;

	job: JobWithSurveyBrands;
	application: Application;
	brands: Brand[];

	rejectMessage?: string;
};

export const initState: State = {
	loading: true,
	brands: [],
	info: { message: "", isSuccess: true },
	job: undefined,
	application: undefined,
};

export const reducer = (state: State, action: ApproveAction): State => {
	switch (action.type) {
		case "changeMessageInfo":
			return {
				...state,
				loading: false,
				info: action.payload,
			};
		case "changeLoading":
			return {
				...state,
				loading: action.payload,
			};
		case "loadApprove":
			return {
				...state,
				loading: false,
				...action.payload,
			};
		case "changeRejectMessage":
			return {
				...state,
				rejectMessage: action.payload,
			};
		default:
			return state;
	}
};
