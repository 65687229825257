import classNames from "classnames";
import React, { FunctionComponent, useCallback, useMemo } from "react";
import Form from "react-bootstrap/Form";
import { ReactSelect } from "../react-select";

type Prop = {
	disabled?: boolean;
	initialized?: boolean;
	label?: string;
	multiple?: boolean;
	options: { label: string; value: string | number }[];
	placeholder?: string;
	required?: boolean;
	searchable?: boolean;
	value?: string | number | string[] | number[];
	validationFunc?: (value: string | number | string[] | number[]) => { valid: boolean; message: string };
	onChange?: (value: string | number | string[] | number[]) => void;
};

export const ValidatableSelect: FunctionComponent<Prop> = React.memo(
	({
		disabled,
		initialized = true,
		label,
		multiple,
		options,
		placeholder,
		required,
		searchable,
		value,
		validationFunc = () => ({ valid: true, message: "" }),
		onChange: onChange = () => {
			return;
		},
	}) => {
		const handleUpdateState = useCallback(
			(option: { value: string | number; label: string } | { value: string | number; label: string }[]) => {
				if ("value" in option) return onChange(option.value);

				const values = option.map(({ value }) => value);
				onChange(values as string[] | number[]);
			},
			[onChange]
		);

		const { valid, message } = useMemo(() => {
			if (required && !initialized && (!value || (Array.isArray(value) && !value.length)))
				return { valid: false, message: "必須項目です" };
			const { valid, message } = validationFunc(value);
			return { valid, message: valid && required ? "必須項目です" : message };
		}, [required, initialized, value, validationFunc]);

		return (
			<div className={classNames("validatable-select", { "validatable-select--invalid": !valid })}>
				{label && <Form.Label>{label}</Form.Label>}
				<ReactSelect
					isDisabled={disabled}
					isMulti={multiple}
					isSearchable={searchable}
					options={options}
					onChange={handleUpdateState}
					placeholder={placeholder ? placeholder : "選択してください"}
					value={value}
				/>
				{!!message && <Form.Text className="validatable-select__annotation">{message}</Form.Text>}
			</div>
		);
	}
);
