import * as React from "react";
import { ErrorObject } from "@lu/validator";
import { Col, Row, Card, Button, Alert } from "react-bootstrap";
import { ValidationInput } from "../../parts/validation-input";
import { getErrorText } from "../../../lib/error";

export type LoginParams = {
	email: string;
	password: string;
};
export type LoginPageProps = LoginParams & {
	onChange: (name: keyof LoginParams, value: string) => void;
	onSubmit: () => void;
	errorMessage?: string;
	errors?: ErrorObject;
};

export const LoginPage = React.memo((props: LoginPageProps) => {
	const { errorMessage, errors, onChange, onSubmit, email, password } = props;
	const onChangeEmail = React.useCallback((value: string) => onChange("email", value), [onChange]);
	const onChangePassword = React.useCallback((value: string) => onChange("password", value), [onChange]);
	const disabled = React.useMemo(() => !email || !password, [email, password]);
	return (
		<Row style={{ marginTop: 100 }}>
			<Col md={{ span: 4, offset: 4 }}>
				<Card>
					<Card.Body>
						{errorMessage && <Alert variant={"danger"}>{errorMessage}</Alert>}
						<ValidationInput
							inputtype={"text"}
							label={"email"}
							placeholder="****@example.com"
							name={"email"}
							value={email}
							onChange={onChangeEmail}
							error={getErrorText(errors, "email")}
						/>
						<ValidationInput
							label={"password"}
							inputtype={"password"}
							name={"password"}
							value={password}
							onChange={onChangePassword}
							error={getErrorText(errors, "password")}
						/>
						<Button variant={"secondary"} disabled={disabled} onClick={onSubmit}>
							Login
						</Button>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	);
});
