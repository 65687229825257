import * as React from "react";
import { JobCreatePage, JobCreatePageProps } from "../../../components/pages/job/create";
import { LoadingToast } from "../../../components/parts/loadings";
import { ConfirmBox } from "../../../components/parts/message/confirm-box";
import { get, post, put, makeError, postFormData } from "../../../lib/request";
import { initState, reducer } from "../../../reducers/job/create";
import { UploadImageResponse } from "../../../../../server/types/request/image";
import { apiEndpoint } from "../../../../../server/router/api/endpoint";
import { useHistory, useParams } from "react-router";
import { GetOneJobResponse, UpsertJobResponse } from "../../../../../server/types/request/job";
import { endpoint } from "../../../routes/endpoint";
import { AccountWithoutPassword } from "../../../../../server/repositories/account/model";
import { StautsUtils } from "../../../../../server/lib/status";

export const JobCreateContainer: React.FC<AccountWithoutPassword> = (loginAccount) => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { loading, info, job, surveyBrandPairs, application, ...jobCreatePageProps } = state;
	const { jobId } = useParams<{ jobId: string }>();
	const history = useHistory();
	React.useEffect(() => {
		get<GetOneJobResponse>(`${apiEndpoint.job}/${jobId}`)
			.then((response) => dispatch({ type: "loadJob", payload: response.data }))
			.catch((error) => {
				dispatch({
					type: "changeMessageInfo",
					payload: makeError(error),
				});
			});
	}, []);
	const onChangeSurveyBrand = React.useCallback<JobCreatePageProps["onChangeSurveyBrand"]>(
		(index, name, value) => dispatch({ type: "changeSurveyBrand", payload: { index, name, value } }),
		[]
	);
	const onChangeCompetitorSurveyBrand = React.useCallback<JobCreatePageProps["onChangeCompetitorSurveyBrand"]>(
		(index, name, value) => dispatch({ type: "changeCompetitorSurveyBrand", payload: { index, name, value } }),
		[]
	);
	const onChangeBrands = React.useCallback<JobCreatePageProps["onChangeBrands"]>(
		(selected) => dispatch({ type: "changeBrands", payload: selected }),
		[]
	);
	const onImageChangeSurveyBrand = React.useCallback<JobCreatePageProps["onImageChangeSurveyBrand"]>(
		(index: number, file: File) => {
			if (!file)
				return dispatch({ type: "changeSurveyBrand", payload: { index, name: "imageInfo", value: undefined } });
			const formData = new FormData();
			formData.append("file", file);
			postFormData<UploadImageResponse>(apiEndpoint.image, formData)
				.then((response) =>
					dispatch({ type: "changeSurveyBrand", payload: { index, name: "imageInfo", value: response.data } })
				)
				.catch((error) => {
					dispatch({
						type: "changeMessageInfo",
						payload: makeError(error, true),
					});
				});
		},
		[]
	);
	const onImageChangeCompetitorSurveyBrand = React.useCallback<
		JobCreatePageProps["onImageChangeCompetitorSurveyBrand"]
	>((index: number, file: File) => {
		if (!file)
			return dispatch({ type: "changeCompetitorSurveyBrand", payload: { index, name: "imageInfo", value: undefined } });
		const formData = new FormData();
		formData.append("file", file);
		postFormData<UploadImageResponse>(apiEndpoint.image, formData)
			.then((response) =>
				dispatch({ type: "changeCompetitorSurveyBrand", payload: { index, name: "imageInfo", value: response.data } })
			)
			.catch((error) => {
				dispatch({
					type: "changeMessageInfo",
					payload: makeError(error, true),
				});
			});
	}, []);
	const onSubmit = React.useCallback<JobCreatePageProps["onSubmit"]>(
		(submitOption) => {
			console.log(submitOption);
			if (job.status && !StautsUtils.editing(job.status)) {
				if (submitOption === "preview") {
					return window.open(`${apiEndpoint.redirectPreview}/${job._id}`);
				}
				return;
			}
			dispatch({ type: "changeLoading", payload: true });
			const isUpdate = !!job._id;
			const requestData = { ...job, surveyBrandPairs };
			const requestUrlBase = submitOption === "temporary" ? apiEndpoint.temporaryJob : apiEndpoint.job;
			const request = isUpdate
				? put<UpsertJobResponse>(`${requestUrlBase}/${job._id.toString()}`, requestData)
				: post<UpsertJobResponse>(requestUrlBase, requestData);
			request
				.then((response) => {
					dispatch({ type: "upsertJob", payload: response.data });
					switch (submitOption) {
						case "application":
							return history.push(`${endpoint.application}/${response.data.job._id}/new`);
						case "preview":
							return window.open(`${apiEndpoint.redirectPreview}/${response.data.job._id}`);
						default:
							dispatch({
								type: "changeMessageInfo",
								payload: { isSuccess: true, message: `ジョブの${isUpdate ? "更新" : "新規作成"}が完了しました。` },
							});
					}
				})
				.catch((error) => {
					dispatch({
						type: "changeMessageInfo",
						payload: makeError(error),
					});
				});
		},
		[job, surveyBrandPairs]
	);
	const onApprovePage = React.useCallback(
		() => application && history.push(`${endpoint.approve}/${application._id}`),
		[application]
	);
	const onAddProduct = React.useCallback<JobCreatePageProps["onAddProduct"]>(
		(brandId, index) => dispatch({ type: "addSurveyBrandProduct", payload: { brandId, index } }),
		[]
	);
	const onDeleteProduct = React.useCallback<JobCreatePageProps["onDeleteProduct"]>(
		(index) => dispatch({ type: "deleteSurveyBrandProduct", payload: index }),
		[]
	);
	const onChangeJob = React.useCallback<JobCreatePageProps["onChangeJob"]>(
		(name, value) => dispatch({ type: "changeJob", payload: { name, value } }),
		[]
	);
	const onChangeCustomSurveyBrand = React.useCallback<JobCreatePageProps["onChangeCustomSurveyBrand"]>(
		(index, targetField, target, values) =>
			dispatch({ type: "changeCustomSurveyBrand", payload: { index, targetField, target, values } }),
		[]
	);
	const onChangeActiveCompetitor = React.useCallback<JobCreatePageProps["onChangeActiveCompetitor"]>(
		(index, active) => dispatch({ type: "changeCompetitorActive", payload: { index, active } }),
		[]
	);
	return (
		<>
			<LoadingToast show={loading} />
			<ConfirmBox info={info} />
			{!!job && (
				<JobCreatePage
					{...jobCreatePageProps}
					loginAccount={loginAccount}
					application={application}
					job={job}
					surveyBrandPairs={surveyBrandPairs}
					onChangeJob={onChangeJob}
					onChangeSurveyBrand={onChangeSurveyBrand}
					onChangeCompetitorSurveyBrand={onChangeCompetitorSurveyBrand}
					onChangeBrands={onChangeBrands}
					onChangeCustomSurveyBrand={onChangeCustomSurveyBrand}
					onImageChangeSurveyBrand={onImageChangeSurveyBrand}
					onChangeActiveCompetitor={onChangeActiveCompetitor}
					onImageChangeCompetitorSurveyBrand={onImageChangeCompetitorSurveyBrand}
					onSubmit={onSubmit}
					onApprovePage={onApprovePage}
					onAddProduct={onAddProduct}
					onDeleteProduct={onDeleteProduct}
					errors={info.errors}
				/>
			)}
		</>
	);
};
