import * as React from "react";
import { Button, Modal } from "react-bootstrap";
import { ValidationInput } from "../../../parts/validation-input";

export type RejectFormButtonProps = {
	rejectMessage?: string;
	onChange: (rejectMessage: string) => void;
	onReject: () => void;
	error?: string;
};

export const RejectFormButton: React.FC<RejectFormButtonProps> = React.memo(
	({ error, rejectMessage, onChange, onReject }) => {
		const [show, setShow] = React.useState<boolean>(false);
		const onClose = React.useCallback(() => setShow(false), []);
		const onOpen = React.useCallback(() => setShow(true), []);
		const onSubmit = React.useCallback(() => {
			onReject();
			onClose;
		}, [onReject]);
		return (
			<>
				<Button variant={"outline-secondary"} onClick={onOpen}>
					却下する
				</Button>
				<Modal show={show} onHide={onClose} className={"confirm-message"}>
					<Modal.Header>却下する場合は却下理由を入力してください。</Modal.Header>
					<Modal.Body>
						<ValidationInput
							label={"却下理由"}
							inputtype={"textarea"}
							value={rejectMessage}
							name={"rejectMessage"}
							onChange={onChange}
							error={error}
						/>
					</Modal.Body>
					<Modal.Footer style={{ textAlign: "right" }}>
						<Button variant={"outline-secondary"} onClick={onSubmit}>
							却下
						</Button>
						<Button variant={"secondary"} onClick={onClose}>
							キャンセル
						</Button>
					</Modal.Footer>
				</Modal>
			</>
		);
	}
);
