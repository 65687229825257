import { Label, DefaultLabel } from "./label";
import { SurveyBrand } from "../server/repositories/survey-brand/model";

export const surveyBrandLabel: Label<SurveyBrand> = {
	...DefaultLabel,
	jobId: "ジョブID",
	brandName: "ブランド名",
	no: "No",
	brandId: "ブランド",
	productType: "製品フラグ",
	// brandName: "ブランド名",
	productName: "製品名",
	categoryType: "カテゴリ名",
	status: "ステータス",
	releaseDate: "発売日",
	age: "ターゲット年代",
	imageInfo: "製品画像",
	hasFragrance: "香料の有無",
	memo: "メモ",
	customConfig: "追加設定項目",
	isDummy: "ダミーフラグ",
};
