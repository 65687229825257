import React, { FunctionComponent, useCallback, useMemo } from "react";
import { CrossView } from "../../../parts/aggregate/cross";
import { ValidatableSelect } from "../../../parts/validatable-select";
import { ResultViewType } from "../../../../types/result-view-type";
import { ButtonsContainer } from "../../../parts/buttons-container";
import Button from "react-bootstrap/Button";
import { CrossAggregateConverter } from "../../../../lib/aggregate/converter";
import { CrossData } from "@lu/muscat-analytics-library";
import { SurveyBrandWithBrand } from "../../../../../../server/repositories/survey-brand/model";

export type DataType = "rawdata" | "question-sheet" | "cross";
export type CrossResultPageProps = {
	cross?: string;
	data: CrossData[];
	surveyBrand: SurveyBrandWithBrand;
	viewType: ResultViewType;
	onChangeViewType: (viewType: ResultViewType) => void;
	onChangeCross: (cross: string) => void;
	onDownload: (dataType: DataType) => void;
	goBack: () => void;
};

export const CrossResultPage: FunctionComponent<CrossResultPageProps> = React.memo(
	({ data, surveyBrand, viewType, onChangeViewType, onDownload, cross, onChangeCross, goBack }) => {
		const crosses = useMemo(() => {
			return CrossAggregateConverter.convert(data, "separate");
		}, [data]);

		const crossOptions = useMemo(() => {
			return [
				{ label: "全て表示", value: "" },
				...crosses.map(({ condition, qunename, title }) => ({
					label: `${condition ? `【${condition}】 ` : ""}${qunename}.${title}`,
					value: `${qunename}${condition}`,
				})),
			];
		}, [crosses]);

		const filteredCross = useMemo(
			() => (cross ? [crosses.find(({ condition, qunename }) => `${qunename}${condition}` === cross)] : crosses),
			[crosses, cross]
		);

		const viewTypeOptions = useMemo(
			() => [
				{ label: "n/%表", value: "both" },
				{ label: "n表", value: "n" },
				{ label: "%表", value: "percent" },
			],
			[]
		);

		const handleClickDownloadQuestionSheet = useCallback(() => onDownload("question-sheet"), [onDownload]);
		const handleClickDownloadRawdata = useCallback(() => onDownload("rawdata"), [onDownload]);
		const handleClickDownloadCross = useCallback(() => onDownload("cross"), [onDownload]);

		return (
			<div className="cross-result-page">
				<div className={"page-contents"}>
					<div className="cross-result-page__header main-title">
						<div>クロス集計結果</div>
						<div className="cross-result-page__header__job-title">
							<div>調査製品</div>
							<div>
								{surveyBrand.brand.name}: {surveyBrand.productName}
							</div>
						</div>
					</div>
					<ButtonsContainer align="right">
						<Button variant="btn btn-secondary" onClick={handleClickDownloadCross}>
							<i className="bi-download" />
							<span>クロス表</span>
						</Button>
						<Button variant="btn btn-secondary" onClick={handleClickDownloadRawdata}>
							<i className="bi-download" />
							<span>ローデータ</span>
						</Button>
						<Button variant="btn btn-secondary" onClick={handleClickDownloadQuestionSheet}>
							<i className="bi-download" />
							<span>質問対応表</span>
						</Button>
					</ButtonsContainer>
					<ButtonsContainer>
						<Button onClick={goBack} variant="white">
							<i className="bi-chevron-left" />
							<span>戻る</span>
						</Button>
					</ButtonsContainer>
					<div className="cross-result-page__select-container">
						<ValidatableSelect options={viewTypeOptions} value={viewType} onChange={onChangeViewType} />
						<ValidatableSelect options={crossOptions} value={cross} onChange={onChangeCross} />
					</div>
					<CrossView crosses={filteredCross} viewType={viewType} />
				</div>
			</div>
		);
	}
);
