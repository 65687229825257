import { ErrorObject } from "@lu/validator";
import * as React from "react";
import { accountLabel } from "../../../../../../label/account";
import { Account } from "../../../../../../server/repositories/account/model";
import { getErrorText } from "../../../../lib/error";
import classnames from "classnames";
import { ValidationInput } from "../../../parts/validation-input";
import { accountRoleOptions } from "../../../../../../server/lib/option/account";
import { Button } from "react-bootstrap";

export type AccountCreatePageProps = {
	account: Partial<Account>;
	onChange: <K extends keyof Account>(name: K, value: Account[K]) => void;
	onSubmit: () => void;
	errors?: ErrorObject;
};

export const AccountCreatePage = React.memo((props: AccountCreatePageProps) => {
	const { account, onChange, errors, onSubmit } = props;
	return (
		<>
			<div className={classnames("main-title")}>アカウント作成</div>
			<ValidationInput
				name={"name"}
				label={accountLabel.name}
				inputtype={"text"}
				value={account.name || ""}
				onChange={(v) => onChange("name", v)}
				error={getErrorText(errors, "name")}
				noblock
			/>
			<ValidationInput
				name={"email"}
				label={accountLabel.email}
				placeholder={"example@example.com"}
				inputtype={"text"}
				value={account.email || ""}
				onChange={(v) => onChange("email", v)}
				error={getErrorText(errors, "email")}
				noblock
			/>
			<ValidationInput
				name={"password"}
				label={accountLabel.password}
				inputtype={"password"}
				value={account.password || ""}
				onChange={(v) => onChange("password", v)}
				error={getErrorText(errors, "password")}
				noblock
			/>
			<ValidationInput
				name={"role"}
				label={accountLabel.role}
				inputtype={"single-select"}
				value={account.role || ""}
				onChange={(v) => onChange("role", v)}
				error={getErrorText(errors, "role")}
				options={accountRoleOptions}
				noblock
			/>
			<ValidationInput
				name={"inactive"}
				label={"ステータス"}
				inputtype={"single-select"}
				value={account.inactive ?? false}
				onChange={(v) => onChange("inactive", v)}
				error={getErrorText(errors, "inactive")}
				options={[
					{ value: false, label: "有効" },
					{ value: true, label: "無効" },
				]}
				noblock
			/>
			<div className={classnames("button-area")}>
				<Button variant={"outline-secondary"} onClick={onSubmit}>
					保存
				</Button>
			</div>
		</>
	);
});
