import { QuestionConfig } from "..";

export const tonerConfig: QuestionConfig[] = [
	{ queNumber: 1, content: "intentionToContinuePurchasing" },
	{ queNumber: 2, content: "reasonForIntentionToContinuePurchasing" },
	{ queNumber: 3, content: "intentionToContinuePurchasingTheBrand" },
	{ queNumber: 4, content: "worth" },
	{ queNumber: 5, content: "brandImage" },

	{ queNumber: 6, content: "usabilityEvaluation" },
	{ queNumber: 7, content: "satisfaction" },
	{ queNumber: 8, content: "inUse" },

	{ queNumber: 9, content: "afterUse" },
	{ queNumber: 10, content: "nextMorning" },
	{ queNumber: 11, content: "daytimeSkin" },

	{ queNumber: 12, content: "fragrancePreference" },
	{ queNumber: 13, content: "fragranceIntensity" },
	{ queNumber: 14, content: "containerComprehensiveEvaluation" },
	{ queNumber: 15, content: "containerdetailedEvaluation" },
	{ queNumber: 16, content: "dissatisfactionImprovement" },

	{ queNumber: 17, content: "orderOfUseOfLotionAndMilkyLotion" },
	{ queNumber: 18, content: "combinedEmulsionBrand" },
	{ queNumber: 19, content: "productNameOfCombinedEmulsion" },

	{ queNumber: 20, content: "channel" },
	{ queNumber: 21, content: "contactExperience" },
	{ queNumber: 22, content: "mostContactExperience" },
	{ queNumber: 23, content: "bc" },
	{ queNumber: 24, content: "bcContent" },
	{ queNumber: 25, content: "annualCosmeticsSpending" },
	{ queNumber: 26, content: "personalAnnualIncome" },
	{ queNumber: 27, content: "householdIncome" },
];
