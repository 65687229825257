import { ErrorObject } from "@lu/validator";
import * as React from "react";
import { InputBaseProps } from "..";
import { Text } from "../text";
import classnames from "classnames";
import { Button } from "react-bootstrap";
import { MinusIcon, PlusIcon } from "../../icons";
import { CustomInput } from "../../validation-input";
import { getErrorText } from "../../../../lib/error";

export type MultipleTextProps = {
	values: string[];
	onChange: (value: string[]) => void;
	errors?: ErrorObject;
} & InputBaseProps;

export const MultipleText: React.FC<MultipleTextProps> = React.memo(
	({ values, onChange, errors, name, ...inputProps }) => {
		const onChangeText = React.useCallback(
			(index: number, value: string) => onChange(values.map((v, i) => (i === index ? value : v))),
			[onChange, values]
		);
		const onDelete = React.useCallback(
			(index: number) => onChange(values.filter((_, i) => i !== index)),
			[onChange, values]
		);
		const onAdd = React.useCallback(
			(index: number) => {
				const vs = [...values];
				vs.splice(index, 0, "");
				onChange(vs);
			},
			[onChange, values]
		);
		const onAddLast = React.useCallback(() => {
			const vs = [...values, ""];
			onChange(vs);
		}, [onChange, values]);
		const cantAdd = React.useMemo(() => values.length >= 5, [values]);
		return (
			<div className={classnames("multiple-text-area")}>
				{values.map((value, index) => (
					<div key={`${name}-${index.toString()}`} className={classnames("multiple-text")}>
						<div className={classnames("multiple-text__input")}>
							<CustomInput
								{...inputProps}
								inputtype={"text"}
								value={value}
								onChange={(value) => onChangeText(index, value)}
								name={`${name}-${index.toString()}`}
								error={getErrorText(errors, index.toString())}
							/>
						</div>
						<div className={classnames("multiple-text__icon")}>
							<div
								className={classnames(
									"multiple-text__icon__plus",
									cantAdd ? " multiple-text__icon__plus__disalbed" : ""
								)}
								onClick={() => !cantAdd && onAdd(index)}
							>
								<PlusIcon />
							</div>
							<div className={classnames("multiple-text__icon__minus")} onClick={() => onDelete(index)}>
								<MinusIcon />
							</div>
						</div>
					</div>
				))}
				<Button variant={"outline-secondary"} onClick={onAddLast} disabled={cantAdd}>
					項目を追加
				</Button>
			</div>
		);
	}
);
