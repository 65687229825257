import * as React from "react";
import { SurveyBrand } from "../../../../../../../server/repositories/survey-brand/model";
import { ErrorObject } from "@lu/validator";
import { ValidationInput } from "../../../../parts/validation-input";
import { surveyBrandLabel } from "../../../../../../../label";
import { categoryTypeOptions, ageFromOptions, ageToOptions } from "../../../../../../../server/lib/option/job";
import { getErrorObject, getErrorText } from "../../../../../lib/error";
import classnames from "classnames";

import {
	CategoryCustomConfigValues,
	CategoryCustomKey,
	CategoryDetail,
	CustomChoiceConfig,
	getCategoryDetail,
} from "../../../../../../../server/repositories/survey-brand/category";
import { CustomConfigInput, CustomConfigInputProps } from "./custom-config";
import { productTypeOptions } from "../../../../../../../server/lib/option/survey-brand";
import { Button, Card } from "react-bootstrap";
import { PlusIcon, MinusIcon } from "../../../../parts/icons";
import { Key } from "@lu/repository";

export type BrandRegisterProps = {
	isCompetitor?: boolean;
	index: number;
	requiredBrandName?: boolean;
	surveyBrand: Partial<SurveyBrand>;
	competitorSurveyBrand: Partial<SurveyBrand>;
	errors?: ErrorObject;
	onChange: <K extends keyof SurveyBrand>(index: number, name: K, value: SurveyBrand[K]) => void;
	onChangeActiveCompetitor: (index: number, active: boolean) => void;
	onChangeCompetitor: <K extends keyof SurveyBrand>(index: number, name: K, value: SurveyBrand[K]) => void;
	onChangeCustom: <K extends keyof CategoryCustomConfigValues>(
		index: number,
		targetField: CategoryCustomKey,
		target: K,
		values: CategoryCustomConfigValues[K]
	) => void;
	onAddProduct: (brandId: Key, index: number) => void;
	onDeleteProduct: (index: number) => void;

	onImageChange: (file: File | undefined) => void;
	onImageChangeCompetitor: (file: File | undefined) => void;
};

export const BrandRegister: React.FC<BrandRegisterProps> = React.memo(
	({
		index,
		isCompetitor,
		surveyBrand,
		onChange,
		onImageChange,
		onChangeCustom,
		errors,
		requiredBrandName,
		onChangeActiveCompetitor,
		onAddProduct,
		onDeleteProduct,
		onChangeCompetitor,
		onImageChangeCompetitor,
		competitorSurveyBrand,
	}) => {
		const handleAdd = React.useCallback(
			() => onAddProduct(surveyBrand.brandId, index + 1),
			[onAddProduct, index, surveyBrand.brandId]
		);
		const surveyBrandErrors = React.useMemo(
			() => getErrorObject(getErrorObject(getErrorObject(errors, "surveyBrandPairs"), index.toString()), "target"),
			[errors, index]
		);
		const competitorSurveyBrandErrors = React.useMemo(
			() => getErrorObject(getErrorObject(getErrorObject(errors, "surveyBrandPairs"), index.toString()), "competitor"),
			[errors, index]
		);
		const handleDelete = React.useCallback(() => onDeleteProduct(index), [index]);
		const handleOptionsChange = React.useCallback<CustomConfigInputProps["onChangeOptions"]>(
			(target, options) => {
				onChangeCustom(index, target, "options", options);
			},
			[index, onChangeCustom]
		);
		const handleAdditionalChoicesChange = React.useCallback<CustomConfigInputProps["onChangeAdditionalChoices"]>(
			(target, values) => {
				onChangeCustom(index, target, "additionalChoices", values);
			},
			[index, onChangeCustom]
		);

		const categoryDetail = React.useMemo<CategoryDetail | undefined>(() => {
			if (!surveyBrand.categoryType) return undefined;
			return getCategoryDetail(surveyBrand.categoryType);
		}, [surveyBrand.categoryType]);
		const filteredProductTypeOptions = React.useMemo(() => {
			if (isCompetitor) return productTypeOptions.filter(({ value }) => value === "competitiveProduct");
			return productTypeOptions.filter(({ value }) => value !== "competitiveProduct");
		}, [isCompetitor]);
		return (
			<Card className={classnames("brand-register")}>
				<Card.Header className={classnames("brand-register__number")}>
					{index + 1}製品目{" "}
					<div className={"icon"} style={{ marginLeft: "7px" }} onClick={handleAdd}>
						<PlusIcon />
					</div>
					<div className={"icon"} style={{ marginLeft: "7px" }} onClick={handleDelete}>
						<MinusIcon />
					</div>
				</Card.Header>
				<Card.Body>
					<div className={"form-area"}>
						<div className={classnames("brand-register--main")}>
							<ValidationInput
								inputtype={"single-select"}
								label={surveyBrandLabel.productType}
								options={filteredProductTypeOptions}
								name={`productType-${index}`}
								value={surveyBrand.productType}
								onChange={(value) => onChange(index, "productType", value)}
								error={getErrorText(surveyBrandErrors, "productType")}
								noblock
							/>
							<ValidationInput
								inputtype={"single-select"}
								label={surveyBrandLabel.categoryType}
								options={categoryTypeOptions}
								name={`categoryType-${index}`}
								value={surveyBrand.categoryType}
								onChange={(value) => onChange(index, "categoryType", value)}
								error={getErrorText(surveyBrandErrors, "categoryType")}
								noblock
							/>

							{requiredBrandName && (
								<ValidationInput
									inputtype={"text"}
									label={surveyBrandLabel.brandName}
									name={`brandName-${index}`}
									value={surveyBrand.brandName || ""}
									onChange={(value) => onChange(index, "brandName", value)}
									error={getErrorText(surveyBrandErrors, "brandName")}
									noblock
								/>
							)}
							<ValidationInput
								inputtype={"text"}
								label={surveyBrandLabel.productName}
								name={`productName-${index}`}
								value={surveyBrand.productName || ""}
								onChange={(value) => onChange(index, "productName", value)}
								error={getErrorText(surveyBrandErrors, "productName")}
								noblock
							/>

							<ValidationInput
								inputtype={"date"}
								label={surveyBrandLabel.releaseDate}
								name={`releaseDate-${index}`}
								value={surveyBrand.releaseDate}
								onChange={(value) => onChange(index, "releaseDate", value)}
								error={getErrorText(surveyBrandErrors, "releaseDate")}
								noblock
							/>
							<ValidationInput
								inputtype={"image-input"}
								label={surveyBrandLabel.imageInfo}
								data={surveyBrand.imageInfo}
								name={`imageInfo-${index}`}
								id={`imageInfo-${index}`}
								onChange={onImageChange}
								error={getErrorText(surveyBrandErrors, "imageInfo")}
								noblock
							/>

							<ValidationInput
								inputtype={"textarea"}
								label={surveyBrandLabel.memo}
								name={`memo-${index}`}
								value={surveyBrand.memo}
								onChange={(value) => onChange(index, "memo", value)}
								error={getErrorText(surveyBrandErrors, "memo")}
								noblock
							/>
							<ValidationInput
								inputtype={"range-select"}
								label={surveyBrandLabel.age}
								name={`age-${index}`}
								value={surveyBrand.age || { from: undefined, to: undefined }}
								onChange={(value) => onChange(index, "age", value)}
								fromOptions={ageFromOptions}
								toOptions={ageToOptions}
								error={getErrorText(surveyBrandErrors, "age")}
								errors={getErrorObject(surveyBrandErrors, "age")}
								noblock
							/>
							<ValidationInput
								inputtype={"single-select"}
								label={surveyBrandLabel.hasFragrance}
								options={[
									{ value: true, label: "香料あり" },
									{ value: false, label: "香料なし" },
								]}
								name={`hasFragrance-${index}`}
								value={surveyBrand.hasFragrance}
								onChange={(value) => onChange(index, "hasFragrance", value)}
								error={getErrorText(surveyBrandErrors, "hasFragrance")}
								noblock
							/>
							{/* 競合 start */}
							<div className="product-title">
								競合製品
								<Button
									variant={"outline-secondary"}
									size={"sm"}
									style={{ marginLeft: "10px" }}
									onClick={() => {
										onChangeActiveCompetitor(index, !competitorSurveyBrand);
									}}
								>
									競合製品を設定{competitorSurveyBrand ? "しない" : "する"}
								</Button>
							</div>
							{competitorSurveyBrand ? (
								<>
									<ValidationInput
										inputtype={"text"}
										label={surveyBrandLabel.brandName}
										name={`brandName-${index}`}
										value={competitorSurveyBrand.brandName || ""}
										onChange={(value) => onChangeCompetitor(index, "brandName", value)}
										error={getErrorText(competitorSurveyBrandErrors, "brandName")}
										noblock
									/>
									<ValidationInput
										inputtype={"text"}
										label={surveyBrandLabel.productName}
										name={`productName-${index}`}
										value={competitorSurveyBrand.productName || ""}
										onChange={(value) => onChangeCompetitor(index, "productName", value)}
										error={getErrorText(competitorSurveyBrandErrors, "productName")}
										noblock
									/>
									<ValidationInput
										inputtype={"date"}
										label={surveyBrandLabel.releaseDate}
										name={`releaseDate-${index}`}
										value={competitorSurveyBrand.releaseDate}
										onChange={(value) => onChangeCompetitor(index, "releaseDate", value)}
										error={getErrorText(competitorSurveyBrandErrors, "releaseDate")}
										noblock
									/>
									<ValidationInput
										inputtype={"image-input"}
										label={surveyBrandLabel.imageInfo}
										data={competitorSurveyBrand.imageInfo}
										name={`imageInfo-${index}`}
										id={`imageInfo-${index}`}
										onChange={onImageChangeCompetitor}
										error={getErrorText(competitorSurveyBrandErrors, "imageInfo")}
										noblock
									/>
									<ValidationInput
										inputtype={"single-select"}
										label={surveyBrandLabel.hasFragrance}
										options={[
											{ value: true, label: "香料あり" },
											{ value: false, label: "香料なし" },
										]}
										name={`hasFragrance-${index}`}
										value={competitorSurveyBrand.hasFragrance}
										onChange={(value) => onChangeCompetitor(index, "hasFragrance", value)}
										error={getErrorText(competitorSurveyBrandErrors, "hasFragrance")}
										noblock
									/>
								</>
							) : (
								<></>
							)}
							{/* 競合 end */}
						</div>
						<div className={classnames("brand-register--option")}>
							{categoryDetail && (
								<>
									<div className="product-title">任意設定項目</div>
									{Object.entries(categoryDetail).map(([target, config]: [CategoryCustomKey, CustomChoiceConfig]) => {
										return (
											<CustomConfigInput
												key={`custom-config-${index}-${target}`}
												target={target}
												config={config}
												errors={getErrorObject(getErrorObject(surveyBrandErrors, "customConfig"), target)}
												values={
													surveyBrand.customConfig && surveyBrand.customConfig[target]
														? surveyBrand.customConfig[target]
														: {}
												}
												onChangeOptions={handleOptionsChange}
												onChangeAdditionalChoices={handleAdditionalChoicesChange}
											/>
										);
									})}
								</>
							)}
						</div>
					</div>
				</Card.Body>
			</Card>
		);
	}
);
