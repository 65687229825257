import { JobStatus } from "../repositories/job/model";

export class StautsUtils {
	public static editing(status: JobStatus): boolean {
		return status === "inPreparation" || status === "reject";
	}
	public static debugMode(status: JobStatus): boolean {
		return status === "inPreparation" || status === "reject" || status === "pending";
	}
	public static progress(status: JobStatus): boolean {
		return status === "progress";
	}
	public static complete(status: JobStatus): boolean {
		return status === "complete";
	}
	public static inApplication(status: JobStatus): boolean {
		return status === "reject" || status === "pending";
	}
}
