import { Key } from "@lu/repository";
import * as React from "react";
import { Job, jobStatus } from "../../../../../../../server/repositories/job/model";
import { categoryType } from "../../../../../../../server/repositories/survey-brand/category";
import { productType, SurveyBrand, surveyStatus } from "../../../../../../../server/repositories/survey-brand/model";
import { StautsUtils } from "../../../../../../../server/lib/status";
import { SettingMenu, SettingMenuItem } from "../../../../parts/setting-menu";

export type JobRedirectPage = "preview" | "debug" | "edit" | "application";
export type SurveyBrandPage = "rawdata" | "question-sheet" | "result" | "view" | "aggregate" | "create-aggregate";
export type JobTableRowProps = {
	job: Job;
	isTargetJob: boolean;
	surveyBrand: SurveyBrand;
	accountMap: Map<string, string>;
	brandMap: Map<string, string>;
	onChangeJobPage: (jobId: Key, page: JobRedirectPage) => void;
	onChangeSurveyBrandPage: (surveyBrand: Key, page: SurveyBrandPage) => void;
};

export const JobTableRow: React.FC<JobTableRowProps> = React.memo(
	({ job, surveyBrand, brandMap, accountMap, isTargetJob, onChangeJobPage, onChangeSurveyBrandPage }) => {
		const events = React.useMemo<SettingMenuItem[]>(() => {
			const items: SettingMenuItem[] = [];
			if (job.enqueteId) items.push({ label: "プレビュー", event: () => onChangeJobPage(job._id, "preview") });
			if (surveyBrand.status === "inPreparation") {
				items.push(
					{ label: "動作画面", event: () => onChangeJobPage(job._id, "debug") },
					{ label: "編集", event: () => onChangeJobPage(job._id, "edit") }
				);
			} else {
				items.push({ label: "設定内容確認", event: () => onChangeSurveyBrandPage(surveyBrand._id, "view") });
			}
			/*/
			if (job.status === "pending" || job.status === "reject") {
				items.push({ label: "承認ページ", event: () => onChangeJobPage(job._id, "application") });
			}
			/*/
			if (!surveyBrand.isDummy && surveyBrand.status === "complete") {
				items.push(
					{ label: "ローデータDL", event: () => onChangeSurveyBrandPage(surveyBrand._id, "rawdata") },
					{ label: "質問対応表DL", event: () => onChangeSurveyBrandPage(surveyBrand._id, "question-sheet") },
					{ label: "クロス集計結果", event: () => onChangeSurveyBrandPage(surveyBrand._id, "result") },
					/*/
					{ label: "GT閲覧", event: () => onChangeSurveyBrandPage(surveyBrand._id, "gt") },
					{ label: "クロス集計閲覧", event: () => onChangeSurveyBrandPage(surveyBrand._id, "cross") },
					{ label: "OA閲覧", event: () => onChangeSurveyBrandPage(surveyBrand._id, "oa") },
					{ label: "追加集計", event: () => onChangeSurveyBrandPage(surveyBrand._id, "aggregate") }
					/*/
					{ provider: true },
					{ label: "集計一覧", event: () => onChangeSurveyBrandPage(surveyBrand._id, "aggregate") },
					{ label: "集計作成", event: () => onChangeSurveyBrandPage(surveyBrand._id, "create-aggregate") }
				);
			}
			return items;
		}, [job, onChangeJobPage, onChangeSurveyBrandPage, surveyBrand.status, surveyBrand.isDummy]);
		const trClassName = React.useMemo(() => (surveyBrand.isDummy ? "job__dummy" : "job"), [surveyBrand.isDummy]);
		return (
			<tr className={trClassName}>
				{!isTargetJob && <td>{job.no}</td>}
				<td>{brandMap.get(surveyBrand.brandId.toString())}</td>
				<td>{categoryType[surveyBrand.categoryType]}</td>
				<td>{productType[surveyBrand.productType]}</td>
				<td>{surveyBrand.productName}</td>
				<td>{accountMap.get(job.accountId.toString())}</td>
				<td>{job.surveyStartDate}</td>
				<td>{StautsUtils.inApplication(job.status) ? jobStatus[job.status] : surveyStatus[surveyBrand.status]}</td>
				<td>
					<SettingMenu items={events} />
				</td>
			</tr>
		);
	}
);
