import * as React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ErrorPage } from "../components/pages/error";
import { AccountCreateContainer } from "../containers/account/create";
import { AccountListContainer } from "../containers/account/list";
import { ApplicationContainer } from "../containers/application";
import { ApproveContainer } from "../containers/approve";
import { WithAuth } from "../containers/auth";
import { JobListContainer } from "../containers/job/list";
import { JobCreateContainer } from "../containers/job/create";
import { LoginContainer } from "../containers/login";
import { LogoutContainter } from "../containers/logout";
import { endpoint } from "./endpoint";
import { JobTopContainer } from "../containers/job/top";
import { CrossContainer } from "../containers/aggregates/cross";
import { JobViewContainer } from "../containers/job/view";
import { AggregateJobContainer } from "../containers/aggregates/list";

export const Routes = (): JSX.Element => {
	return (
		<BrowserRouter>
			<Switch>
				<Route path={endpoint.login} exact>
					<LoginContainer />
				</Route>
				<Route path={endpoint.logout} exact>
					<LogoutContainter />
				</Route>
				<Route path={`${endpoint.account}`} exact>
					<WithAuth>
						<AccountListContainer />
					</WithAuth>
				</Route>
				<Route path={`${endpoint.account}/:accountId`} exact>
					<WithAuth>
						<AccountCreateContainer />
					</WithAuth>
				</Route>
				<Route path={endpoint.top} exact>
					<WithAuth>
						<JobTopContainer />
					</WithAuth>
				</Route>
				<Route path={`${endpoint.jobEdit}/:jobId`} exact>
					<WithAuth>{(loginAccount) => <JobCreateContainer {...loginAccount} />}</WithAuth>
				</Route>
				<Route path={`${endpoint.surveyBrand}/:jobId/:surveyBrandId`} exact>
					<WithAuth>
						<JobViewContainer />
					</WithAuth>
				</Route>
				<Route path={`${endpoint.surveyBrand}/:jobId`} exact>
					<WithAuth>
						<JobViewContainer />
					</WithAuth>
				</Route>
				<Route path={`${endpoint.job}`} exact>
					<WithAuth>
						<JobListContainer />
					</WithAuth>
				</Route>
				<Route path={`${endpoint.job}/:jobId`} exact>
					<WithAuth>
						<JobListContainer />
					</WithAuth>
				</Route>
				<Route path={`${endpoint.application}/:jobId/:applicationId`} exact>
					<WithAuth>
						<ApplicationContainer />
					</WithAuth>
				</Route>
				<Route path={`${endpoint.approve}/:applicationId`} exact>
					<WithAuth>{(loginAccount) => <ApproveContainer {...loginAccount} />}</WithAuth>
				</Route>
				<Route path={`${endpoint.cross}/:surveyBrandId`} exact>
					<WithAuth>
						<CrossContainer />
					</WithAuth>
				</Route>
				<Route path={`${endpoint.aggregate}`} exact>
					<WithAuth>
						<AggregateJobContainer />
					</WithAuth>
				</Route>
				<Route path={`${endpoint.aggregate}/:surveyBrandId`} exact>
					<WithAuth>
						<AggregateJobContainer />
					</WithAuth>
				</Route>
				<Route>
					<ErrorPage text="Not Found" />
				</Route>
			</Switch>
		</BrowserRouter>
	);
};
