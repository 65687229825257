import * as React from "react";
import classnames from "classnames";
import { Job, JobWithSurveyBrands } from "../../../../../server/repositories/job/model";
import { Application } from "../../../../../server/repositories/application/model";
import { applicationLabel, jobLabel } from "../../../../../label";
import { Key } from "@lu/repository";
import { Option } from "../../../../../server/lib/option";
import { ErrorObject } from "@lu/validator";
import { ValidationInput } from "../../parts/validation-input";
import { Button } from "react-bootstrap";
import { SurveyBrandList } from "../../parts/list/survey-brand";
import { getErrorText } from "../../../lib/error";
import { Brand } from "../../../../../server/repositories/brand/model";

export type ApplicationPageProps = {
	job: JobWithSurveyBrands;
	brands: Brand[];
	accountOptions: Option[];
	application: Partial<Application>;
	onChange: <K extends keyof Application>(name: K, value: Application[K]) => void;
	onChangeSurveyDate: (value: Job["surveyStartDate"]) => void;
	onSubmit: () => void;
	errors?: ErrorObject;
};

export const ApplicationPage: React.FC<ApplicationPageProps> = React.memo(
	({ job, brands, accountOptions, onChange, onChangeSurveyDate, onSubmit, application, errors }) => {
		const onChangeApprover = React.useCallback((value: Key) => onChange("approver", value), [onChange]);
		const onChangeMessage = React.useCallback((value: string) => onChange("message", value), [onChange]);
		return (
			<>
				<div className={classnames("main-title")}>調査概要</div>
				<div className={"m-2"}>
					<div className={classnames("sub-title")}>製品</div>
					<div className={classnames("form-area__800")}>
						<SurveyBrandList surveyBrands={job.surveyBrands} brands={brands} />
					</div>
					<div className={"m-2"}>
						<div className={classnames("sub-title")}>{jobLabel.surveyStartDate}</div>
						<div className={classnames("form-area__400")}>
							<ValidationInput
								inputtype={"date"}
								value={job.surveyStartDate}
								name={"surveyStartDate"}
								onChange={onChangeSurveyDate}
								error={getErrorText(errors, "surveyStartDate")}
							/>
						</div>
					</div>
					<div className={"m-2"}>
						<div className={classnames("sub-title")}>{applicationLabel.approver}選択</div>
						<div className={classnames("form-area__400")}>
							<ValidationInput
								inputtype={"single-select"}
								value={application.approver}
								name={"approver"}
								onChange={onChangeApprover}
								options={accountOptions}
								error={getErrorText(errors, "approver")}
							/>
						</div>
					</div>
					<div className={"m-2"}>
						<div className={classnames("sub-title")}>{applicationLabel.message}</div>
						<div className={classnames("form-area")}>
							<ValidationInput
								inputtype={"textarea"}
								value={application.message}
								name={"message"}
								onChange={onChangeMessage}
								error={getErrorText(errors, "message")}
							/>
						</div>
					</div>
				</div>
				{!application.status && (
					<div className="button-area">
						<Button variant={"outline-secondary"} onClick={onSubmit}>
							申請する
						</Button>
					</div>
				)}
			</>
		);
	}
);
