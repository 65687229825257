import * as React from "react";
import { Button, Modal, ButtonProps } from "react-bootstrap";

export type ModalViewButtonProps = {
	label: string;
	header: React.ReactText | React.ReactNode;
	body: React.ReactText | React.ReactNode;
} & ButtonProps;

export const ModalViewButton: React.FC<ModalViewButtonProps> = React.memo(({ label, header, body, ...buttonProps }) => {
	const [show, setShow] = React.useState<boolean>(false);
	const onShow = React.useCallback(() => setShow(true), []);
	const onHide = React.useCallback(() => setShow(false), []);
	return (
		<>
			<Button {...buttonProps} onClick={onShow}>
				{label}
			</Button>
			<Modal show={show} onHide={onHide}>
				<Modal.Header>{header}</Modal.Header>
				<Modal.Body>{body}</Modal.Body>
				<Modal.Footer style={{ textAlign: "right" }}>
					<Button {...buttonProps} onClick={onHide}>
						閉じる
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
});
