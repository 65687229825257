import * as React from "react";
import classnames from "classnames";
import { Redirect, useHistory } from "react-router";
import { AccountWithoutPassword } from "../../../../server/repositories/account/model";
import { Role } from "../../../../server/lib/permission/role";
import { apiEndpoint } from "../../../../server/router/api/endpoint";
import { AuthResponse } from "../../../../server/types/auth";
import { AppBar } from "../../components/parts/app-bar";
import { Drawer, DrawerItem } from "../../components/parts/drawer";
import { post } from "../../lib/request";
import { endpoint } from "../../routes/endpoint";
import { initState, reducer } from "../../reducers/auth";
import { useLocation } from "react-router";
import { AccountIcon, HomeIcon } from "../../components/parts/icons";

export type WithAuthProps = {
	children: ((account: AccountWithoutPassword) => React.ReactNode) | React.ReactNode;
};
export const WithAuth = React.memo((props: WithAuthProps) => {
	const { children } = props;
	const [state, dispatch] = React.useReducer(reducer, initState);
	const history = useHistory();
	const location = useLocation();
	const { requested, account } = state;
	React.useEffect(() => {
		post<AuthResponse>(apiEndpoint.authentication)
			.then((response) => {
				dispatch({ type: "authResponse", payload: response.data });
			})
			.catch((err) => {
				console.error(err);
				dispatch({ type: "changeRequested", payload: true });
			});
	}, []);
	const drawerItems = React.useMemo<DrawerItem[]>(() => {
		if (!account) return [];
		const items: DrawerItem[] = [
			{
				label: "ホーム",
				active: endpoint.top === location.pathname,
				event: () => history.push(endpoint.top),
				icon: <HomeIcon />,
			},
			{
				label: "全ジョブ一覧",
				active: endpoint.job === location.pathname,
				event: () => history.push(endpoint.job),
				icon: <HomeIcon />,
			},
		];
		if (Role.isAdmin(account)) {
			items.push({
				label: "アカウント",
				active: endpoint.account === location.pathname,
				event: () => history.push(endpoint.account),
				icon: <AccountIcon />,
			});
		}
		return items;
	}, [account, location.pathname]);
	const onLogout = React.useCallback(() => history.push(endpoint.logout), []);
	return (
		<>
			{requested &&
				(account ? (
					<>
						<div className={classnames("body-container")}>
							<AppBar account={account} onLogout={onLogout} />
							<div className="main-container">
								<Drawer items={drawerItems} />
								<main className={classnames("container-fluid")}>
									<div className={classnames("container-fluid__area")}>
										{typeof children === "function" ? children(account) : children}
									</div>
								</main>
							</div>
						</div>
					</>
				) : (
					<Redirect to={endpoint.login} />
				))}
		</>
	);
});
