export const endpoint = {
	top: "/",
	login: "/login",
	logout: "/logout",
	account: "/account",
	job: "/job",
	jobEdit: "/job-edit",
	surveyBrand: "/survey-brand", // 調査ブランド

	aggregate: "/aggregate",
	application: "/application",
	approve: "/approve",

	// 集計系のページ
	gt: "/gt",
	cross: "/cross",
} as const;
