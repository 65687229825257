import { SurveyBrandWithBrand } from "../../../../../server/repositories/survey-brand/model";
import { JobViewAction } from "../../../types/actions";
import { MessageInfo } from "../../../types/info";

export type State = {
	loading: boolean;
	info: MessageInfo;
	surveyBrands: SurveyBrandWithBrand[];
};

export const initState: State = {
	info: { message: "", isSuccess: true },
	loading: true,
	surveyBrands: [],
};

export const reducer = (state: State, action: JobViewAction): State => {
	switch (action.type) {
		case "changeMessageInfo":
			return {
				...state,
				loading: false,
				info: action.payload,
			};
		case "changeLoading":
			return {
				...state,
				loading: action.payload,
			};
		case "loadJobs":
			return {
				...state,
				loading: false,
				...action.payload,
			};
		default:
			return state;
	}
};
