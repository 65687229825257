import { StandardCategoryDetail } from "..";

export const eyelashSerumDetail: StandardCategoryDetail = {
	inUse: {
		standards: [
			{ id: 1, text: "容器から出したときの見た目・色がよい" },
			{ id: 2, text: "美容液の量を調節しやすい" },
			{ id: 3, text: "一度でまつげにしっかり液がつく" },
		],
		options: [
			{ id: 4, text: "とろみ／粘度がちょうどよい" },
			{ id: 5, text: "みずみずしい" },
			{ id: 6, text: "まつ毛に均一につく" },
			{ id: 7, text: "目の周りなど余計なところにつきにくい" },
			{ id: 8, text: "短い毛に塗りやすい" },
			{ id: 9, text: "まつ毛の生え際に塗りやすい" },
			{ id: 10, text: "ブラシ・チップ・筆が使いやすい" },
			{ id: 11, text: "ブラシ・チップ・筆がまぶたにフィットする" },
			{ id: 12, text: "乾きの早さがちょうどよい" },
			{ id: 13, text: "香りが気にならない" },
		],
		canAdd: true,
	},
	afterUse: {
		standards: [
			{ id: 1, text: "まつ毛がしっとりする" },
			{ id: 2, text: "まつ毛がべたつかない" },
			{ id: 3, text: "まつ毛にハリがある" },
			{ id: 4, text: "まつ毛につやがある" },
		],
		options: [{ id: 5, text: "まつ毛にうるおいがある" }],
		canAdd: true,
	},
	nextMorning: {
		standards: [],
		options: [
			{ id: 1, text: "翌朝、まつ毛がべたつかない" },
			{ id: 2, text: "翌朝、まつ毛がしっとりする" },
		],
		canAdd: true,
	},
	daytimeEyelash: {
		standards: [],
		options: [
			{ id: 1, text: "日中、まつ毛にうるおいがある" },
			{ id: 2, text: "まつ毛がカールしやすくなる" },
			{ id: 3, text: "マスカラのりがよい" },
			{ id: 4, text: "マスカラのもちがよい" },
		],
		canAdd: true,
	},
	continuedUse: {
		standards: [
			{ id: 1, text: "まつ毛が長くなったと感じる" },
			{ id: 2, text: "まつ毛がフサフサになったと感じる" },
			{ id: 3, text: "まつ毛が太くなったと感じる" },
		],
		options: [
			{ id: 4, text: "まつ毛にハリがでたと感じる" },
			{ id: 5, text: "まつ毛の量（本数）が多くなったと感じる" },
			{ id: 6, text: "まつ毛が抜けにくくなったと感じる" },
			{ id: 7, text: "目もとに色素沈着がない" },
		],
		canAdd: true,
	},
};
