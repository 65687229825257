import { StandardCategoryDetail } from "..";

export const sunCareDetail: StandardCategoryDetail = {
	inUse: {
		standards: [
			{ id: 1, text: "容器から出したときの見た目・色がよい" },
			{ id: 2, text: "のびがよい" },
			{ id: 3, text: "肌へのなじみがよい" },
			{ id: 4, text: "ムラなく均一になじむ" },
		],
		canAdd: true,
	},
	afterUse: {
		standards: [
			{ id: 1, text: "白浮きしない" },
			{ id: 2, text: "肌にうるおいがある" },
			{ id: 3, text: "肌がべたつかない" },
			{ id: 4, text: "肌がサラサラしている" },
			{ id: 5, text: "肌がみずみずしい" },
			{ id: 6, text: "肌がつっぱらない" },
			{ id: 7, text: "被膜感がない" },
			{ id: 8, text: "肌への負担を感じない" },
			{ id: 9, text: "ニオイが気にならない" },
		],
		canAdd: true,
	},
	/**
	 * warning
	 * 本来は4時間ごと8時間後であるが、同じ選択肢を使う関係上、一つに集約する
	 */
	afterEightHours: {
		standards: [
			{ id: 1, text: "日焼け止め効果が続いている" },
			{ id: 2, text: "白浮きしない" },
			{ id: 3, text: "肌にうるおいがある" },
			{ id: 4, text: "肌がべたつかない" },
			{ id: 5, text: "肌がサラサラしている" },
			{ id: 6, text: "肌がみずみずしい" },
			{ id: 7, text: "肌がつっぱらない" },
			{ id: 8, text: "被膜感がない" },
			{ id: 9, text: "肌への負担を感じない" },
			{ id: 10, text: "ニオイが気にならない" },
			{ id: 11, text: "こすれても落ちにくい" },
			{ id: 12, text: "汗・皮脂・水に強い感じがする" },
		],
		canAdd: true,
	},
	afterClean: {
		standards: [{ id: 1, text: "簡単に落とせる" }],
		canAdd: true,
	},
};
