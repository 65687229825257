import { StandardCategoryDetail } from "..";

export const cheekDetail: StandardCategoryDetail = {
	inUse: {
		standards: [
			{ id: 1, text: "広げやすい" },
			{ id: 2, text: "色のつきがよい" },
			{ id: 3, text: "頬にフィットする" },
			{ id: 4, text: "ぼかしやすい" },
			{ id: 5, text: "自分好みの形に描きやすい" },
		],
		canAdd: true,
	},
	afterUse: {
		standards: [
			{ id: 1, text: "色の濃さがちょうどよい" },
			{ id: 2, text: "ムラなく仕上がる" },
			{ id: 3, text: "肌になじむ" },
			{ id: 4, text: "自然な血色感に仕上がる" },
			{ id: 5, text: "立体感のある頬に仕上がる" },
			{ id: 6, text: "発色が良い" },
			{ id: 7, text: "見たままの色に仕上がる" },
			{ id: 8, text: "毛穴や凹凸が目立たない" },
		],
		canAdd: true,
	},
	afterFourHours: {
		standards: [
			{ id: 1, text: "のせた色が薄れない" },
			{ id: 2, text: "ヨレていない" },
			{ id: 3, text: "のせた色がつけた直後と変わらない" },
			{ id: 4, text: "仕上がりが持続する" },
			{ id: 5, text: "自然な血色感が持続する" },
			{ id: 6, text: "頬の立体感が持続する" },
			{ id: 7, text: "毛穴や凹凸が目立たない" },
		],
		canAdd: true,
	},
};
