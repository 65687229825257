import { DefaultSchema } from "@lu/repository";

export type Label<T> = {
	[key in keyof Required<T>]: string;
};

export const DefaultLabel: Label<DefaultSchema> = {
	_id: "管理ID",
	createdAt: "作成日",
	updatedAt: "更新日",
};
