import { StandardCategoryDetail } from "..";

export const lipstickDetail: StandardCategoryDetail = {
	inUse: {
		standards: [
			{ id: 1, text: "のばしやすい" },
			{ id: 2, text: "唇へのあたりがやわらかい" },
			{ id: 3, text: "唇にのせたとき軽く広げられる" },
			{ id: 4, text: "唇にフィットする" },
			{ id: 5, text: "唇がべたつかない" },
			{ id: 6, text: "唇にのせたときにつっぱらない" },
		],
		options: [
			{ id: 7, text: "唇にのせたときになめらか" },
			{ id: 8, text: "唇にのせたときにざらつかない" },
			{ id: 9, text: "唇にのせたときにみずみずしい" },
			{ id: 10, text: "細かいところも塗りやすい" },
			{ id: 11, text: "均一に塗りやすい" },
			{ id: 12, text: "上唇に塗りやすい" },
			{ id: 13, text: "下唇に塗りやすい" },
			{ id: 14, text: "重ね塗りしやすい" },
			{ id: 15, text: "つやが出る" },
			{ id: 16, text: "しっとりする" },
		],
		canAdd: true,
	},
	afterUse: {
		standards: [
			{ id: 1, text: "見たままの色に仕上がる" },
			{ id: 2, text: "ムラなく均一に仕上がる" },
			{ id: 3, text: "うるおいのある唇に仕上がる" },
			{ id: 4, text: "つやのある唇に仕上がる" },
			{ id: 5, text: "ふっくらとした唇に仕上がる" },
		],
		options: [
			{ id: 6, text: "発色が良い" },
			{ id: 7, text: "ボリューム感のある唇に仕上がる" },
			{ id: 8, text: "輝きのある唇に仕上がる" },
			{ id: 9, text: "立体感のある唇に仕上がる" },
			{ id: 10, text: "唇の縦じわが目立たない" },
			{ id: 11, text: "唇の荒れが目立たない" },
			{ id: 12, text: "元の唇の色が隠れる" },
			{ id: 13, text: "女性らしい唇に仕上がる" },
		],
		canAdd: true,
	},
	afterTwoHours: {
		standards: [
			{ id: 1, text: "のせた色が薄れない" },
			{ id: 2, text: "うるおいが持続する" },
			{ id: 3, text: "つやが持続する" },
			{ id: 4, text: "唇のふっくら感が持続する" },
			{ id: 5, text: "のせた色がにじまない" },
			{ id: 6, text: "唇が乾かない" },
			{ id: 7, text: "唇がつっぱらない" },
			{ id: 8, text: "口紅の跡がグラスやカップにつきにくい" },
		],
		options: [
			{ id: 9, text: "のせた色はムラなく均一である" },
			{ id: 10, text: "のせた色はつけた直後と変わらない" },
			{ id: 11, text: "仕上がりが持続する" },
			{ id: 12, text: "発色が持続する" },
			{ id: 13, text: "唇の立体感が持続する" },
			{ id: 14, text: "唇の縦じわが目立たない" },
			{ id: 15, text: "唇の荒れが目立たない" },
		],
	},
	afterEat: {
		standards: [
			{ id: 1, text: "飲食後も色が落ちにくい" },
			{ id: 2, text: "飲食後もつやが落ちにくい" },
			{ id: 3, text: "唇がつっぱらない" },
		],
		options: [{ id: 4, text: "口紅の跡がグラスやカップにつきにくい" }],
		canAdd: true,
	},
	afterClean: {
		standards: [
			{ id: 1, text: "簡単に落とせる" },
			{ id: 2, text: "唇が荒れない" },
		],
		options: [{ id: 3, text: "唇が乾かない" }],
		canAdd: true,
	},
};
