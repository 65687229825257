import { AccountWithoutPassword } from "../../../../server/repositories/account/model";
import { AuthAction } from "../../types/actions";

export type State = {
	account?: AccountWithoutPassword;
	requested: boolean;
};

export const initState: State = {
	requested: false,
};

export const reducer = (state: State, action: AuthAction): State => {
	switch (action.type) {
		case "authResponse":
			return {
				...action.payload,
				requested: true,
			};
		case "changeRequested":
			return {
				...state,
				requested: action.payload,
			};
		default:
			return state;
	}
};
