import { ShampooConditionerDateail } from "..";

export const shampooConditionerDetail: ShampooConditionerDateail = {
	inUseShampoo: {
		standards: [
			{ id: 1, text: "容器から出したときの見た目・色がよい" },
			{ id: 2, text: "容器の口もとからの香りがよい" },
			{ id: 3, text: "泡立ちがよい" },
			{ id: 4, text: "泡のきめが細かい" },
			{ id: 5, text: "泡の量が多い" },
			{ id: 6, text: "シャンプー中、髪の指通りがよい" },
			{ id: 7, text: "洗っている時、よい香りがする" },
			{ id: 8, text: "すすいでいる時、髪がきしまない" },
			{ id: 9, text: "すすいでいる時、髪の指通りがよい" },
			{ id: 10, text: "すすぎが早い" },
			{ id: 11, text: "すすいだ後、髪がしっとりした感じがする" },
			{ id: 12, text: "すすいだ後、髪がなめらかになった感じがする" },
			{ id: 13, text: "すすいだ後、髪がやわらかくなった感じがする" },
			{ id: 14, text: "すすいだ後、髪の指通りがよい" },
			{ id: 15, text: "髪の汚れが落ちた／さっぱりした感じがする" },
			{ id: 16, text: "髪や地肌にやさしい感じがする" },
			{ id: 17, text: "地肌の汚れが落ちた／さっぱりした感じがする" },
		],
		options: [
			{ id: 18, text: "シャンプー中、清涼感がある" },
			{ id: 19, text: "すすいだ後、清涼感がある" },
		],
		canAdd: true,
	},
	inUseConditioner: {
		standards: [
			{ id: 1, text: "容器から出したときの見た目・色がよい" },
			{ id: 2, text: "容器の口もとからの香りがよい" },
			{ id: 3, text: "髪につけて伸ばしやすい／なじみやすい" },
			{ id: 4, text: "髪につけた後、指通りがよい" },
			{ id: 5, text: "髪につけた後、しっとりした感じがする" },
			{ id: 6, text: "髪につけた後、なめらかになった感じがする" },
			{ id: 7, text: "髪につけた後、やわらかくなった感じがする" },
			{ id: 8, text: "すすいでいる時、指通りがよい" },
			{ id: 9, text: "すすいでいる時、髪がやわらかくなった感じがする" },
			{ id: 10, text: "すすぎが早い" },
			{ id: 11, text: "すすいでいる時、よい香りがする" },
			{ id: 12, text: "すすいだ後、髪がしっとりした感じがする" },
			{ id: 13, text: "すすいだ後、髪がなめらかになった感じがする" },
			{ id: 14, text: "すすいだ後、髪がやわらかくなった感じがする" },
			{ id: 15, text: "すすいだ後、髪の指通りがよい" },
		],
		options: [
			{ id: 16, text: "髪につけた後、清涼感がある" },
			{ id: 17, text: "すすいだ後、清涼感がある" },
		],
		canAdd: true,
	},
	afterTowelDry: {
		standards: [
			{ id: 1, text: "髪がしっとりした感じがする" },
			{ id: 2, text: "髪がなめらかになった感じがする" },
			{ id: 3, text: "髪がやわらかくなった感じがする" },
			{ id: 4, text: "髪の指通り、クシ通りがよい" },
			{ id: 5, text: "髪の香りがよい" },
		],
		canAdd: true,
	},
	afterDry: {
		standards: [
			{ id: 1, text: "髪がしっとりした感じがする" },
			{ id: 2, text: "髪がなめらかになった感じがする" },
			{ id: 3, text: "髪がやわらかくなった感じがする" },
			{ id: 4, text: "髪の指通り、クシ通りがよい" },
			{ id: 5, text: "髪がサラサラになった" },
			{ id: 6, text: "髪がツルツルになった" },
			{ id: 7, text: "髪がまとまりやすくなった" },
			{ id: 8, text: "髪にツヤが出る" },
			{ id: 9, text: "髪がべたつかない" },
			{ id: 10, text: "髪がパサつかない" },
			{ id: 11, text: "髪が広がらない" },
			{ id: 12, text: "髪がごわつかない" },
			{ id: 13, text: "地肌の汚れが落ちた／さっぱりした感じがする" },
			{ id: 14, text: "地肌がべたつかない" },
			{ id: 15, text: "地肌がうるおった感じがする" },
			{ id: 16, text: "フケ／かゆみが気にならなくなった" },
			{ id: 17, text: "髪の香りがよい" },
		],
		options: [
			{ id: 18, text: "髪にハリ、コシが出た感じがする" },
			{ id: 19, text: "髪がふんわりした感じがする" },
			{ id: 20, text: "髪の汚れが落ちた／さっぱりした感じがする" },
			{ id: 21, text: "髪や地肌にやさしい感じがする" },
		],
		canAdd: true,
	},
	nextDay: {
		standards: [
			{ id: 1, text: "髪がしっとりした感じがする" },
			{ id: 2, text: "髪がなめらかになった感じがする" },
			{ id: 3, text: "髪がやわらかくなった感じがする" },
			{ id: 4, text: "髪の指通り、クシ通りがよい" },
			{ id: 5, text: "髪がサラサラになった" },
			{ id: 6, text: "髪がツルツルになった" },
			{ id: 7, text: "髪がまとまりやすくなった" },
			{ id: 8, text: "髪にツヤが出る" },
			{ id: 9, text: "髪がべたつかない" },
			{ id: 10, text: "髪がパサつかない" },
			{ id: 11, text: "髪が広がらない" },
			{ id: 12, text: "髪がごわつかない" },
			{ id: 13, text: "地肌の汚れが落ちた／さっぱりした感じがする" },
			{ id: 14, text: "地肌がべたつかない" },
			{ id: 15, text: "地肌がうるおった感じがする" },
			{ id: 16, text: "フケ／かゆみが気にならなくなった" },
			{ id: 17, text: "髪の香りがよい" },
		],
		options: [
			{ id: 18, text: "髪にハリ、コシが出た感じがする" },
			{ id: 19, text: "髪がふんわりした感じがする" },
			{ id: 20, text: "寝癖ぐせがつきにくい" },
			{ id: 21, text: "スタイリングしやすくなった" },
			{ id: 22, text: "髪の汚れが落ちた／さっぱりした感じがする" },
			{ id: 23, text: "髪や地肌にやさしい感じがする" },
		],
		canAdd: true,
	},
};
