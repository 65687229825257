import { SurveyBrandWithBrand } from "../../../../../server/repositories/survey-brand/model";
import { AggregateListAction } from "../../../types/actions";
import { MessageInfo } from "../../../types/info";
import { CustomAggregateJobWithInfo } from "../../../../../server/repositories/custom-aggregate-job/model";

export type State = {
	loading: boolean;
	info: MessageInfo;

	surveyBrand?: SurveyBrandWithBrand;
	aggregateJobs: CustomAggregateJobWithInfo[];
};

export const initState: State = {
	info: { message: "", isSuccess: true },
	loading: true,
	aggregateJobs: [],
};

export const reducer = (state: State, action: AggregateListAction): State => {
	switch (action.type) {
		case "aggregateJobs":
			return { ...state, ...action.payload, loading: false };
		case "surveyBrand":
			return { ...state, surveyBrand: action.payload };
		case "changeMessageInfo":
			return {
				...state,
				loading: false,
				info: action.payload,
			};
		case "changeLoading":
			return {
				...state,
				loading: action.payload,
			};
	}
};
