import * as React from "react";
import { FormControl } from "react-bootstrap";
import { InputBaseProps } from "..";

export type DateInputProps = {
	onChange: (value: string) => void;
	value: string;
	placeholder?: string;
} & InputBaseProps;

export const DateInput = React.memo((props: DateInputProps) => {
	const { onChange, value, ...dateProps } = props;
	const onChangeDate = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value),
		[onChange]
	);
	return (
		<FormControl className={"date-input"} value={value || ""} type={"date"} onChange={onChangeDate} {...dateProps} />
	);
});
