import * as React from "react";
import { useHistory, useParams } from "react-router";
import { apiEndpoint } from "../../../../server/router/api/endpoint";
import { GetApplicationResponse, PostApplicationResponse } from "../../../../server/types/request/application";
import { ApplicationPage, ApplicationPageProps } from "../../components/pages/application";
import { LoadingToast } from "../../components/parts/loadings";
import { ConfirmBox } from "../../components/parts/message/confirm-box";
import { get, post, makeError } from "../../lib/request";
import { initState, reducer } from "../../reducers/application";
import { endpoint } from "../../routes/endpoint";

export const ApplicationContainer: React.FC = () => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { info, loading, application, job, ...applicationPageProps } = state;
	const history = useHistory();
	const { jobId, applicationId } = useParams<{ jobId: string; applicationId: string }>();
	React.useEffect(() => {
		get<GetApplicationResponse>(`${apiEndpoint.application}/${jobId}/${applicationId}`)
			.then((response) => dispatch({ type: "loadApplication", payload: response.data }))
			.catch((error) => {
				dispatch({
					type: "changeMessageInfo",
					payload: makeError(error, true),
				});
			});
	}, []);
	const onChange = React.useCallback<ApplicationPageProps["onChange"]>(
		(name, value) => dispatch({ type: "changeApplication", payload: { name, value } }),
		[]
	);
	const onChangeSurveyDate = React.useCallback<ApplicationPageProps["onChangeSurveyDate"]>(
		(payload) => dispatch({ type: "changeSurveyDate", payload }),
		[]
	);
	const onSubmit = React.useCallback(() => {
		post<PostApplicationResponse>(`${apiEndpoint.application}/${job._id}`, {
			...application,
			surveyStartDate: job.surveyStartDate,
		})
			.then((response) => dispatch({ type: "updateApplication", payload: response.data.application }))
			.catch((error) => {
				dispatch({
					type: "changeMessageInfo",
					payload: makeError(error),
				});
			});
	}, [job, application]);
	const onClose = React.useCallback(
		() => application && application._id && history.push(`${endpoint.job}/${jobId}`),
		[application]
	);
	return (
		<>
			<LoadingToast show={loading} />
			<ConfirmBox info={info} onClose={onClose} />
			{!!job && (
				<ApplicationPage
					application={application}
					job={job}
					{...applicationPageProps}
					onChange={onChange}
					errors={info.errors}
					onChangeSurveyDate={onChangeSurveyDate}
					onSubmit={onSubmit}
				/>
			)}
		</>
	);
};
