import * as React from "react";
import { LogoutPage } from "../../components/pages/logout";
import { post } from "../../lib/request";
import { apiEndpoint } from "../../../../server/router/api/endpoint";
import { AppBar } from "../../components/parts/app-bar";
import { endpoint } from "../../routes/endpoint";
import classNames from "classnames";

export const LogoutContainter: React.FC = () => {
	const changeLocation = React.useCallback(() => location.replace(endpoint.login), []);
	const [isLogout, setIslogout] = React.useState<boolean>(false);
	React.useEffect(() => {
		post(apiEndpoint.logout, {}).finally(() => {
			setIslogout(true);
		});
	}, []);
	return (
		isLogout && (
			<div className={classNames("body-container")}>
				<AppBar />
				<LogoutPage changeLocation={changeLocation} />
			</div>
		)
	);
};
