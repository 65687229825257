import * as React from "react";
import { Tab, Tabs } from "react-bootstrap";

export type TabViwerItem = {
	key: string;
	title: string;
	elements: JSX.Element | JSX.Element[];
};
export type TabViwerProps = {
	items: TabViwerItem[];
};
export const TabViwer: React.FC<TabViwerProps> = React.memo(({ items }) => {
	return (
		<Tabs>
			{items.map((item) => (
				<Tab eventKey={item.key} key={item.key} title={item.title}>
					{item.elements}
				</Tab>
			))}
		</Tabs>
	);
});
