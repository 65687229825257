import { StandardCategoryDetail } from "..";

export const mascaraDetail: StandardCategoryDetail = {
	inUse: {
		standards: [
			{ id: 1, text: "容器から出したときの見た目・色がよい" },
			{ id: 2, text: "マスカラ液の量を調節しやすい" },
			{ id: 3, text: "のびがなめらか" },
			{ id: 4, text: "一度でまつげにしっかり液がつく" },
			{ id: 5, text: "重ね塗りしやすい" },
			{ id: 6, text: "ダマになりにくい" },
			{ id: 7, text: "簡単・スピーディーに仕上がる" },
		],
		options: [
			{ id: 8, text: "まつ毛１本１本に均一につく" },
			{ id: 9, text: "束になりにくい" },
			{ id: 10, text: "乾きの早さがちょうどよい" },
			{ id: 11, text: "目の周りなど余計なところにつきにくい" },
			{ id: 12, text: "香りが気にならない" },
			{ id: 13, text: "細かいところにつけやすい" },
			{ id: 14, text: "ボリュームのコントロールがしやすい" },
			{ id: 15, text: "長さのコントロールがしやすい" },
			{ id: 16, text: "カールアップさせやすい" },
			{ id: 17, text: "ブラシ・コームなどが使いやすい" },
			{ id: 18, text: "ブラシ・コームがまぶたにフィットする" },
		],
		canAdd: true,
	},
	afterUse: {
		standards: [
			{ id: 1, text: "ボリュームアップ効果がある" },
			{ id: 2, text: "まつ毛が長く見える" },
			{ id: 3, text: "カール効果がある" },
			{ id: 4, text: "セパレート効果がある" },
			{ id: 5, text: "ダマにならない" },
			{ id: 6, text: "目が大きく見える" },
			{ id: 7, text: "自然な仕上がりになる" },
		],
		options: [
			{ id: 8, text: "まつ毛の根元が太く先端が細く見える" },
			{ id: 9, text: "つやがある" },
			{ id: 10, text: "目もとの印象・インパクトが増す" },
			{ id: 11, text: "まつ毛がフサフサに見える" },
			{ id: 12, text: "まつ毛が太く見える" },
			{ id: 13, text: "まつ毛の量（本数）が多く見える" },
			{ id: 14, text: "べたつかない仕上がりになる" },
			{ id: 15, text: "やわらかな仕上がりになる" },
			{ id: 16, text: "弾力のある仕上がりになる" },
			{ id: 17, text: "素まつ毛が豊かになったように見える" },
		],
		canAdd: true,
	},
	afterFourHours: {
		standards: [
			{ id: 1, text: "仕上がりが持続する" },
			{ id: 2, text: "目周りににじんでいない" },
			{ id: 3, text: "化粧直しの重ね塗りがしやすい" },
		],
		options: [
			{ id: 4, text: "カール効果が持続する" },
			{ id: 5, text: "つやが持続する" },
			{ id: 6, text: "目の下が黒くならない" },
			{ id: 7, text: "繊維などのカスが落ちてこない" },
		],
		canAdd: true,
	},
	afterClean: {
		standards: [{ id: 1, text: "簡単に落とせる" }],
		options: [{ id: 2, text: "落とす時、まつげに負担を感じない" }],
		canAdd: true,
	},
};
