import * as React from "react";
import { Table } from "react-bootstrap";
import { jobLabel, surveyBrandLabel } from "../../../../../../../label";
import { JobWithSurveyBrands } from "../../../../../../../server/repositories/job/model";
import classnames from "classnames";
import { JobTableRow, JobTableRowProps } from "./job-table-row";

export type JobTableProps = {
	isTargetJob: boolean;
	jobs: JobWithSurveyBrands[];
	accountMap: Map<string, string>;
	brandMap: Map<string, string>;
	onChangeJobPage: JobTableRowProps["onChangeJobPage"];
	onChangeSurveyBrandPage: JobTableRowProps["onChangeSurveyBrandPage"];
};

export const JobTable: React.FC<JobTableProps> = React.memo(
	({ isTargetJob, jobs, accountMap, brandMap, onChangeJobPage, onChangeSurveyBrandPage }) => {
		return (
			<Table className={classnames("job-table")}>
				<thead>
					<tr>
						{!isTargetJob && <th>{jobLabel.no}</th>}
						<th>{surveyBrandLabel.brandId}</th>
						<th>{surveyBrandLabel.categoryType}</th>
						<th>{surveyBrandLabel.productType}</th>
						<th>{surveyBrandLabel.productName}</th>
						<th>{jobLabel.accountId}</th>
						<th>{jobLabel.surveyStartDate}</th>
						<th>{surveyBrandLabel.status}</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{jobs.map((job) => (
						<React.Fragment key={`job-${job._id.toString()}`}>
							{job.surveyBrands.map((surveyBrand) => (
								<JobTableRow
									isTargetJob={isTargetJob}
									key={`survey-brand-${surveyBrand._id}`}
									job={job}
									surveyBrand={surveyBrand}
									accountMap={accountMap}
									brandMap={brandMap}
									onChangeJobPage={onChangeJobPage}
									onChangeSurveyBrandPage={onChangeSurveyBrandPage}
								/>
							))}
						</React.Fragment>
					))}
				</tbody>
			</Table>
		);
	}
);
