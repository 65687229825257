import { StandardCategoryDetail } from "..";

export const manFaceWashDetail: StandardCategoryDetail = {
	inUse: {
		standards: [
			{ id: 1, text: "容器から出したときの見た目・色がよい" },
			{ id: 2, text: "泡立ちが早い" },
			{ id: 3, text: "泡の量が十分である" },
			{ id: 4, text: "泡がきめ細かい" },
			{ id: 5, text: "クリーミーな泡である" },
			{ id: 6, text: "洗っている時、泡の持ちがよい" },
			{ id: 7, text: "すすぎやすい（洗い流しやすい）" },
		],
		options: [
			{ id: 8, text: "チューブから出しやすい" },
			{ id: 9, text: "手のひらで伸ばしやすい" },
			{ id: 10, text: "気持ちのよい泡である" },
			{ id: 11, text: "肌へのなじみがよい" },
			{ id: 12, text: "香りがよい" },
			{ id: 13, text: "硬さがちょうどよい" },
			{ id: 14, text: "とろみ／粘度がちょうどよい" },
		],
		canAdd: true,
	},
	afterUse: {
		standards: [
			{ id: 1, text: "肌がみずみずしい" },
			{ id: 2, text: "肌にうるおいがある" },
			{ id: 3, text: "肌がしっとりする" },
			{ id: 4, text: "肌がさっぱりする" },
			{ id: 5, text: "肌がやわらかい" },
			{ id: 6, text: "肌がなめらかになる" },
			{ id: 7, text: "肌がかさつかない" },
			{ id: 8, text: "肌がつるつるになる" },
			{ id: 9, text: "肌がすべすべになる" },
			{ id: 10, text: "肌がサラサラになる" },
			{ id: 11, text: "肌がつっぱらない" },
			{ id: 12, text: "肌がすっきりした" },
			{ id: 13, text: "汚れ落ちがよい" },
			{ id: 14, text: "自分の肌のタイプに合う" },
		],
		options: [
			{ id: 15, text: "肌がひきしまる" },
			{ id: 16, text: "肌が明るくなる" },
			{ id: 17, text: "透明感がでる" },
			{ id: 18, text: "余分な皮脂がよく落ちる" },
			{ id: 19, text: "毛穴まできちんと洗いあげる" },
			{ id: 20, text: "肌にやさしい" },
		],
		canAdd: true,
	},
};
