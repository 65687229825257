import * as React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { productType, SurveyBrandWithBrand } from "../../../../../../../server/repositories/survey-brand/model";
import classnames from "classnames";
import { JobViewConfigItem } from "./item";
import { surveyBrandLabel } from "../../../../../../../label";
import {
	CategoryCustomKey,
	CategoryDetail,
	categoryType,
	CustomChoiceConfig,
	getCategoryDetail,
} from "../../../../../../../server/repositories/survey-brand/category";
import { questionContent } from "../../../../../../../server/muscat/enquete/main/config";

export type JobViewConfigProps = {
	surveyBrand: SurveyBrandWithBrand;
};

export const JobViewConfig = React.memo<JobViewConfigProps>(({ surveyBrand }) => {
	const name = React.useMemo(() => {
		if (surveyBrand.brandName) return `${surveyBrand.brandName}(${surveyBrand.brand.name})`;
		return surveyBrand.brand.name;
	}, [surveyBrand]);
	const categoryDetail = React.useMemo<CategoryDetail>(() => {
		return getCategoryDetail(surveyBrand.categoryType);
	}, [surveyBrand.categoryType]);
	return (
		<Card className={classnames("brand-config")}>
			<Card.Header className={classnames("brand-config__title")}>{name}</Card.Header>
			<Card.Body>
				<Row>
					<Col md={6}>
						<JobViewConfigItem label={surveyBrandLabel.productType} value={productType[surveyBrand.productType]} />
						<JobViewConfigItem label={surveyBrandLabel.categoryType} value={categoryType[surveyBrand.categoryType]} />
						<JobViewConfigItem label={surveyBrandLabel.releaseDate} value={surveyBrand.releaseDate} />
						<JobViewConfigItem
							label={surveyBrandLabel.age}
							value={`${surveyBrand.age.from || ""}～${surveyBrand.age.to || ""}歳`}
						/>
						<JobViewConfigItem
							label={surveyBrandLabel.hasFragrance}
							value={surveyBrand.hasFragrance ? "香料あり" : "香料なし"}
						/>
						<JobViewConfigItem label={surveyBrandLabel.memo} value={surveyBrand.memo} />
					</Col>
					<Col md={6}>
						<JobViewConfigItem label={surveyBrandLabel.imageInfo} value={<img src={surveyBrand.imageInfo.url} />} />
					</Col>
				</Row>
				<Row>
					<Col md={12}>
						<div className="brand-config__sub-title">任意設定項目</div>
					</Col>
				</Row>
				<Row>
					{/* 任意項目の表示 */}
					{categoryDetail && (
						<>
							{Object.entries(categoryDetail).map(([target, config]: [CategoryCustomKey, CustomChoiceConfig]) => {
								if (config.standards.length === 0 && (!config.options || !config.options.length)) {
									return <React.Fragment key={`custom-config-${target}`} />;
								}
								const options = config.options
									? config.options.filter((op) => !!surveyBrand.customConfig[target]?.options?.includes(op.id))
									: [];
								return (
									<Col md={4} key={`custom-config-${target}`}>
										<JobViewConfigItem
											label={questionContent[target]}
											value={
												<div>
													{!!config.standards && (
														<div className="brand-config__custom__item">
															<div>【標準項目】</div>
															{config.standards.map((st, index) => {
																return <div key={`custom-config-${target}-st-${index}`}>{st.text}</div>;
															})}
														</div>
													)}
													{options.length > 0 && (
														<div className="brand-config__custom__item">
															<div>【任意項目】</div>
															{options.map((op, index) => {
																return <div key={`custom-config-${target}-op-${index}`}>{op.text}</div>;
															})}
														</div>
													)}
													{!!surveyBrand.customConfig[target]?.additionalChoices && (
														<div className="brand-config__custom__item">
															<div>【追加項目】</div>
															{surveyBrand.customConfig[target]?.additionalChoices.map((op, index) => {
																return <div key={`custom-config-${target}-ad-${index}`}>{op}</div>;
															})}
														</div>
													)}
												</div>
											}
										/>
									</Col>
								);
							})}
						</>
					)}
				</Row>
			</Card.Body>
		</Card>
	);
});
