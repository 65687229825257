import * as React from "react";
import { Spinner } from "react-bootstrap";

export const LoadingSpinner: React.FC = () => {
	return (
		<div className="loading-spinner">
			<Spinner animation="border" variant="secondary" />
		</div>
	);
};
