import * as React from "react";
import { useHistory, useParams } from "react-router";
import { apiEndpoint } from "../../../../server/router/api/endpoint";
import { LoadingToast } from "../../components/parts/loadings";
import { ConfirmBox } from "../../components/parts/message/confirm-box";
import { get, post, makeError } from "../../lib/request";
import { initState, reducer } from "../../reducers/approve";
import { GetApproveResponse, PostApproveResponse } from "../../../../server/types/request/approve";
import { ApprovePage, ApprovePageProps } from "../../components/pages/approve";
import { endpoint } from "../../routes/endpoint";
import { AccountWithoutPassword } from "../../../../server/repositories/account/model";

export const ApproveContainer: React.FC<AccountWithoutPassword> = (loginAccount) => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { loading, info, rejectMessage, ...approvePageProps } = state;
	const { applicationId } = useParams<{ applicationId: string }>();
	const history = useHistory();
	React.useEffect(() => {
		get<GetApproveResponse>(`${apiEndpoint.approve}/${applicationId}`)
			.then((response) => dispatch({ type: "loadApprove", payload: response.data }))
			.catch((error) =>
				dispatch({
					type: "changeMessageInfo",
					payload: makeError(error),
				})
			);
	}, []);
	const onApprove = React.useCallback(
		() =>
			post<PostApproveResponse>(`${apiEndpoint.approve}/${applicationId}`, { status: "approved" })
				.then(() => dispatch({ type: "changeMessageInfo", payload: { isSuccess: true, message: "承認しました。" } }))
				.catch((error) => dispatch({ type: "changeMessageInfo", payload: makeError(error) })),
		[]
	);
	const onReject = React.useCallback(
		() =>
			post<PostApproveResponse>(`${apiEndpoint.approve}/${applicationId}`, { status: "reject", rejectMessage })
				.then(() => dispatch({ type: "changeMessageInfo", payload: { isSuccess: true, message: "却下しました。" } }))
				.catch((error) => dispatch({ type: "changeMessageInfo", payload: makeError(error) })),
		[rejectMessage]
	);
	const onChange = React.useCallback<ApprovePageProps["onChange"]>(
		(payload) => dispatch({ type: "changeRejectMessage", payload }),
		[]
	);
	const onClose = React.useCallback(() => {
		if (info.isSuccess && info.message) return history.push(endpoint.top);
	}, [info]);
	const onEnquetePage = React.useCallback<ApprovePageProps["onEnquetePage"]>(
		(page) => {
			switch (page) {
				case "preview":
					return window.open(`${apiEndpoint.redirectPreview}/${approvePageProps.job._id}`);
				case "debug":
					return window.open(`${apiEndpoint.redirectDebug}/${approvePageProps.job._id}`);
			}
		},
		[approvePageProps.job]
	);
	return (
		<>
			<LoadingToast show={loading} />
			<ConfirmBox info={info} onClose={onClose} />
			{!!approvePageProps.job && (
				<ApprovePage
					{...approvePageProps}
					onEnquetePage={onEnquetePage}
					loginAccount={loginAccount}
					onApprove={onApprove}
					onReject={onReject}
					rejectMessage={rejectMessage}
					onChange={onChange}
					errors={info.errors}
				/>
			)}
		</>
	);
};
