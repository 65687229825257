import * as React from "react";
import { useHistory } from "react-router";
import { apiEndpoint } from "../../../../../server/router/api/endpoint";
import { GetAccountResponse } from "../../../../../server/types/request/account";
import { AccountPage, AccountPageProps } from "../../../components/pages/account/list";
import { LoadingToast } from "../../../components/parts/loadings";
import { ConfirmBox } from "../../../components/parts/message/confirm-box";
import { get, makeError } from "../../../lib/request";
import { initState, reducer } from "../../../reducers/account/list";
import { endpoint } from "../../../routes/endpoint";

export const AccountListContainer: React.FC = () => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { loading, info, ...accountPageProps } = state;
	React.useEffect(() => {
		get<GetAccountResponse>(apiEndpoint.account)
			.then((response) => dispatch({ type: "loadAccounts", payload: response.data }))
			.catch((error) => dispatch({ type: "changeMessageInfo", payload: makeError(error) }));
	}, []);
	const onChangeSearchValues = React.useCallback<AccountPageProps["onChangeSearchValues"]>(
		(name, value) => dispatch({ type: "changeSearchValues", payload: { name, value } }),
		[]
	);
	const history = useHistory();
	const onEdit = React.useCallback<AccountPageProps["onEdit"]>((id) => history.push(`${endpoint.account}/${id}`), []);
	return (
		<>
			<LoadingToast show={loading} />
			<ConfirmBox info={info} />
			<AccountPage {...accountPageProps} onChangeSearchValues={onChangeSearchValues} onEdit={onEdit} />
		</>
	);
};
