import { Option } from "../../../../server/lib/option";
import { Application } from "../../../../server/repositories/application/model";
import { Brand } from "../../../../server/repositories/brand/model";
import { JobWithSurveyBrands } from "../../../../server/repositories/job/model";
import { ApplicationAction } from "../../types/actions";
import { MessageInfo } from "../../types/info";

export type State = {
	loading: boolean;
	info: MessageInfo;

	job: JobWithSurveyBrands;
	accountOptions: Option[];
	brands: Brand[];
	application: Partial<Application>;
};

export const initState: State = {
	loading: true,
	info: { message: "", isSuccess: true },
	job: undefined,
	accountOptions: [],
	brands: [],
	application: {},
};

export const reducer = (state: State, action: ApplicationAction): State => {
	switch (action.type) {
		case "changeMessageInfo":
			return {
				...state,
				loading: false,
				info: action.payload,
			};
		case "changeLoading":
			return {
				...state,
				loading: action.payload,
			};
		case "loadApplication":
			return {
				...state,
				loading: false,
				...action.payload,
			};
		case "changeApplication":
			return {
				...state,
				application: {
					...state.application,
					[action.payload.name]: action.payload.value,
				},
			};
		case "changeSurveyDate":
			return {
				...state,
				job: {
					...state.job,
					surveyStartDate: action.payload,
				},
			};
		case "updateApplication":
			return {
				...state,
				loading: false,
				info: { isSuccess: true, message: "申請が完了しました。" },
				application: action.payload,
			};
		default:
			return state;
	}
};
