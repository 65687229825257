import { DefaultSchema } from "@lu/repository";

export const accountRole = {
	admin: "管理者",
	approver: "承認者",
	applicant: "申請者",
} as const;

export type AccountRole = keyof typeof accountRole;
export const roleKeys = Object.keys(accountRole) as AccountRole[];

export type Account = {
	name: string;
	email: string;
	password: string;
	role: AccountRole;
	inactive?: boolean;
} & DefaultSchema;

export type AccountWithoutPassword = Omit<Account, "password">;
