import * as React from "react";
import { Alert } from "react-bootstrap";
import { InputBaseProps } from "..";
import { ImageInfo } from "../../../../../../server/repositories/survey-brand/model";
import classnames from "classnames";
import { DeleteIcon } from "../../icons";

export type ImageResizableInputProps = {
	data?: ImageInfo;
	id: string;
	onChange: (file: File | undefined) => void;
} & InputBaseProps;

export const ImageInput = React.memo((props: ImageResizableInputProps) => {
	const { data, onChange, id } = props;
	const [filename, setFilename] = React.useState<string>("");
	const onInputChange = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			if (!e.target && !e.target.files) {
				setFilename("");
				onChange(undefined);
			} else {
				const file = e.target.files.item(0);
				setFilename(file.name);
				onChange(file);
			}
		},
		[onChange]
	);
	const onDeleteClick = React.useCallback(() => {
		setFilename("");
		onChange(undefined);
	}, [onChange]);
	return (
		<div className={classnames("image-input-file")}>
			<div className={classnames("image-input-file__input")}>
				<div className="custom-file">
					<input
						type="file"
						className="custom-file-input"
						aria-describedby="inputGroupFileAddon01"
						onChange={onInputChange}
						accept="image/*"
					/>
					<label className="custom-file-label" htmlFor="inputGroupFile01">
						{filename}
					</label>
				</div>
				{data && data.url ? (
					<div id={id} className={classnames("image-preview")}>
						<img src={data.url} />
					</div>
				) : (
					<Alert variant={"dark"}>設定なし</Alert>
				)}
			</div>
			{data && data.url && (
				<div className={classnames("image-input-file__icons")}>
					<div className={classnames("image-input-file__icon")} onClick={onDeleteClick}>
						<DeleteIcon />
					</div>
				</div>
			)}
		</div>
	);
});
