import * as React from "react";
import { Dropdown } from "react-bootstrap";
import { SettingIcon } from "../icons";

export type SettingMenuItem =
	| {
			label: string;
			event: () => void;
	  }
	| { provider: true };

export type SettingMenuProps = {
	items: SettingMenuItem[];
};

export const SettingMenu: React.FC<SettingMenuProps> = React.memo(({ items }) => {
	return (
		<Dropdown>
			<Dropdown.Toggle as={"span"}>
				<SettingIcon />
			</Dropdown.Toggle>
			<Dropdown.Menu>
				{items.map((item, index) => {
					if ("provider" in item) {
						return <Dropdown.Divider key={`item-${index}`} />;
					}
					return (
						<Dropdown.Item key={`item-${index}`} onClick={item.event}>
							{item.label}
						</Dropdown.Item>
					);
				})}
			</Dropdown.Menu>
		</Dropdown>
	);
});
