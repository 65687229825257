import { StandardCategoryDetail } from "..";

export const manStylingDetail: StandardCategoryDetail = {
	inUse: {
		standards: [
			{ id: 1, text: "髪に塗布しやすい" },
			{ id: 2, text: "髪にムラなく均一につけられる" },
			{ id: 3, text: "適量を出しやすい" },
			{ id: 4, text: "髪へのなじみが良い" },
			{ id: 5, text: "スタイリングしやすい" },
			{ id: 6, text: "簡単に使える" },
			{ id: 7, text: "香りが気にならない" },
		],
		canAdd: true,
	},
	afterUse: {
		standards: [
			{ id: 1, text: "髪がほどよくまとまる" },
			{ id: 2, text: "髪が固まり過ぎない" },
			{ id: 3, text: "細かいアレンジがしやすい" },
			{ id: 4, text: "髪がベタつかない" },
			{ id: 5, text: "仕上がりの質感が良い" },
			{ id: 6, text: "自分の髪質に合っている" },
		],
		canAdd: true,
	},
	afterFourHours: {
		standards: [
			{ id: 1, text: "スタイルのキープ力がある／持ちがよい" },
			{ id: 2, text: "再整髪（手直し）しやすい" },
		],
		canAdd: true,
	},
	afterClean: {
		standards: [{ id: 1, text: "洗髪時に落としやすい" }],
		canAdd: true,
	},
};
