export const apiEndpoint = {
	login: "/api/login",
	authentication: "/api/auth",
	logout: "/api/logout",

	account: "/api/account",
	jobTop: "/api/job-top",
	surveyBrand: "/api/surveuy-brand",

	job: "/api/job",
	temporaryJob: "/api/temporary-job",

	completeJob: "/api/complete-job",
	loadJob: "/api/job-load",
	application: "/api/application",
	approve: "/api/approve",
	jobApprove: "/api/job-approve",

	image: "/api/image",

	redirectPreview: "/api/r/preview",
	redirectDebug: "/api/r/debug",

	// donwload
	downloadRawdata: "/api/download/rawdata",
	downloadQuestionSheet: "/api/download/question-sheet",

	// cross
	cross: "/api/cross",
	downloadCross: "/api/download/cross",

	aggregateJob: "/api/aggregate-job",
} as const;

export type ApiEndpoint = keyof typeof apiEndpoint;
