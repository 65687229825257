import { Label, DefaultLabel } from "./label";
import { CustomAggregateJob } from "../server/repositories/custom-aggregate-job/model";

export const customAggregateJobLabel: Label<CustomAggregateJob & { name: string }> = {
	...DefaultLabel,
	// enqueteId: "アンケートID",
	jobNo: "No",
	aggregateJobId: "集計ジョブ",
	surveyBrandId: "調査ブランド",
	accountId: "担当者",
	name: "集計名称",
};
