import { StandardCategoryDetail } from "..";

export const eyebrowDetail: StandardCategoryDetail = {
	inUse: {
		standards: [
			{ id: 1, text: "眉になじみやすい" },
			{ id: 2, text: "眉にフィットする" },
			{ id: 3, text: "眉尻が描きやすい" },
			{ id: 4, text: "簡単に描ける" },
			{ id: 5, text: "アイブローのつきがちょうどよい" },
		],
		options: [
			{ id: 6, text: "ぼかしやすい" },
			{ id: 7, text: "太くも細くも描きやすい" },
			{ id: 8, text: "濃淡をつけやすい" },
		],
		canAdd: true,
	},
	afterUse: {
		standards: [
			{ id: 1, text: "自然な眉毛に仕上がる" },
			{ id: 2, text: "立体感のある眉に仕上がる" },
			{ id: 3, text: "思い通りの眉に仕上がる" },
			{ id: 4, text: "色の濃さがちょうどよい" },
		],
		canAdd: true,
	},
	afterFourHours: {
		standards: [
			{ id: 1, text: "色が薄れない" },
			{ id: 2, text: "仕上がりが持続する" },
		],
		canAdd: true,
	},
};
