import { StandardCategoryDetail } from "..";

export const liquidFoundationDetail: StandardCategoryDetail = {
	inUse: {
		standards: [
			{ id: 1, text: "のばしやすい" },
			{ id: 2, text: "しっとりしている" },
			{ id: 3, text: "みずみずしさを感じる" },
			{ id: 4, text: "粉っぽくない" },
			{ id: 5, text: "油っぽくない" },
			{ id: 6, text: "肌にフィットする" },
		],
		options: [
			{ id: 7, text: "のびがなめらか" },
			{ id: 8, text: "香りが気にならない" },
			{ id: 9, text: "のびが軽い" },
		],
		canAdd: true,
	},
	afterUse: {
		standards: [
			{ id: 1, text: "肌が粉っぽくない" },
			{ id: 2, text: "肌へのフィット感がある" },
			{ id: 3, text: "肌に均一についている" },
			{ id: 4, text: "肌がキメ細かく見える" },
			{ id: 5, text: "厚塗りに見えない" },
			{ id: 6, text: "肌がなめらかに見える" },
			{ id: 7, text: "つやのある肌に見える" },
			{ id: 8, text: "毛穴が目立たない" },
			{ id: 9, text: "キメの粗さが目立たない" },
			{ id: 10, text: "シミ・そばかすが目立たない" },
			{ id: 11, text: "肌がしっとりしている" },
			{ id: 12, text: "肌がべたつかない" },
		],
		options: [
			{ id: 13, text: "肌の赤みが目立たない" },
			{ id: 14, text: "肌がサラサラしている" },
			{ id: 15, text: "自然な感じに見える" },
			{ id: 16, text: "パール感がある" },
			{ id: 17, text: "立体感があるように見える" },
			{ id: 18, text: "肌に透明感がある" },
			{ id: 19, text: "ハリのある肌に見える" },
			{ id: 20, text: "肌が明るく見える" },
			{ id: 21, text: "しわが目立たない" },
			{ id: 22, text: "香りが気にならない" },
		],
		canAdd: true,
	},
	afterFourHours: {
		standards: [
			{ id: 1, text: "肌が粉っぽくない" },
			{ id: 2, text: "肌へのフィット感がある" },
			{ id: 3, text: "肌に均一についている" },
			{ id: 4, text: "肌がキメ細かく見える" },
			{ id: 5, text: "厚塗りに見えない" },
			{ id: 6, text: "肌がなめらかに見える" },
			{ id: 7, text: "つやのある肌に見える" },
			{ id: 8, text: "毛穴が目立たない" },
			{ id: 9, text: "キメの粗さが目立たない" },
			{ id: 10, text: "シミ・そばかすが目立たない" },
			{ id: 11, text: "肌がテカっていない" },
			{ id: 12, text: "ファンデーションが薄れていない" },
			{ id: 13, text: "ファンデーションがよれていない" },
			{ id: 14, text: "肌がくすんでいない" },
			{ id: 15, text: "肌がしっとりしている" },
			{ id: 16, text: "肌がべたつかない" },
		],
		options: [
			{ id: 17, text: "肌の赤みが目立たない" },
			{ id: 18, text: "ファンデーションが粉浮きしていない" },
			{ id: 19, text: "肌がサラサラしている" },
			{ id: 20, text: "自然な感じに見える" },
			{ id: 21, text: "パール感がある" },
			{ id: 22, text: "立体感があるように見える" },
			{ id: 23, text: "肌に透明感がある" },
			{ id: 24, text: "ハリのある肌に見える" },
			{ id: 25, text: "肌が明るく見える" },
			{ id: 26, text: "しわが目立たない" },
			{ id: 27, text: "肌が乾燥していない" },
		],
		canAdd: true,
	},
};
