import * as React from "react";
import { Option } from "@lu/muscat-library/dist/lib/common";
import { Button } from "react-bootstrap";
import { applicationLabel, jobLabel } from "../../../../../../label";
import { Searcher, SearchField } from "../../../parts/searcher";
import { JobTableProps, JobTalbe } from "./job-table";

export type JobTopPageProps = {
	searchValues: { [key: string]: any };
	accountOptions: Option[];
	onChangeSearchValues: (name: string, value: any) => void;
} & Omit<JobTableProps, "accountMap">;

export const JobTopPage: React.FC<JobTopPageProps> = React.memo(
	({ jobs, accountOptions, searchValues, onChangeJobPage, onChangeSearchValues }) => {
		const onCreate = React.useCallback(() => onChangeJobPage("new", "edit"), [onChangeJobPage]);
		const accountMap = React.useMemo(() => {
			return accountOptions.reduce((a, b) => {
				a.set(b.value.toString(), b.label);
				return a;
			}, new Map<string, string>());
		}, [accountOptions]);
		const fields = React.useMemo<SearchField[]>(
			() => [
				{ type: "number", name: "no", label: jobLabel.no },
				{ type: "date", name: "surveyStartDate", label: jobLabel.surveyStartDate },

				{ type: "select", name: "applicant", label: applicationLabel.applicant, options: accountOptions },
				{ type: "select", name: "approver", label: applicationLabel.approver, options: accountOptions },
			],
			[accountOptions]
		);
		const filteredJobs = React.useMemo(() => {
			if (fields.every(({ name }) => !searchValues[name])) return jobs;
			return jobs.filter((job) => {
				return fields.every(({ name }) => {
					if (!searchValues[name]) return true;
					if (name === "no" || name === "surveyStartDate") {
						return searchValues[name] === job[name];
					}
					if (name === "applicant" || name === "approver") {
						if (!job.application) return false;
						return searchValues[name] === job.application[name];
					}
					return true;
				});
			});
		}, [jobs, searchValues, fields]);
		return (
			<>
				<div className="main-title">
					アクティビティ一覧
					<div className={"float-right"}>
						<Button variant={"outline-secondary"} onClick={onCreate}>
							新規作成
						</Button>
					</div>
				</div>
				<Searcher onChange={onChangeSearchValues} fields={fields} searchValues={searchValues} />
				<JobTalbe jobs={filteredJobs} accountMap={accountMap} onChangeJobPage={onChangeJobPage} />
			</>
		);
	}
);
