import * as React from "react";
import { LoadingToast } from "../../../components/parts/loadings";
import { ConfirmBox } from "../../../components/parts/message/confirm-box";
import { get, makeError } from "../../../lib/request";
import { initState, reducer } from "../../../reducers/job/view";
import { apiEndpoint } from "../../../../../server/router/api/endpoint";
import { useParams } from "react-router";
import { GetSurveyJobResponse } from "../../../../../server/types/request/survey-job";
import { JobViewConfig } from "../../../components/pages/job/view/config";
import classnames from "classnames";

export const JobViewContainer: React.FC = () => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { loading, info, surveyBrands } = state;
	const { jobId, surveyBrandId } = useParams<{ jobId: string; surveyBrandId?: string }>();
	React.useEffect(() => {
		const url = [apiEndpoint.surveyBrand, jobId, surveyBrandId].filter((v) => !!v).join("/");
		get<GetSurveyJobResponse>(url)
			.then((response) => dispatch({ type: "loadJobs", payload: response.data }))
			.catch((error) => dispatch({ type: "changeMessageInfo", payload: makeError(error) }));
	}, [surveyBrandId]);
	return (
		<>
			<LoadingToast show={loading} />
			<ConfirmBox info={info} />
			<div className={classnames("main-title")}>設定内容確認</div>
			{surveyBrands.map((surveyBrand) => (
				<JobViewConfig key={`survey-brand-${surveyBrand._id}`} surveyBrand={surveyBrand} />
			))}
		</>
	);
};
