import * as React from "react";
import classnames from "classnames";
import { JobWithSurveyBrands } from "../../../../../server/repositories/job/model";
import { Application } from "../../../../../server/repositories/application/model";
import { applicationLabel, jobLabel } from "../../../../../label";
import { ErrorObject } from "@lu/validator";
import { Button } from "react-bootstrap";
import { SurveyBrandList } from "../../parts/list/survey-brand";
import { RejectFormButton } from "./reject-form-button";
import { getErrorText } from "../../../lib/error";
import { AccountWithoutPassword } from "../../../../../server/repositories/account/model";
import { Brand } from "../../../../../server/repositories/brand/model";

export type EnquetePage = "debug" | "preview";
export type ApprovePageProps = {
	loginAccount: AccountWithoutPassword;
	job: JobWithSurveyBrands;
	brands: Brand[];
	application: Application;
	rejectMessage?: string;
	onApprove: () => void;
	onReject: () => void;
	onEnquetePage: (page: EnquetePage) => void;
	onChange: (rejectMessage: string) => void;
	errors?: ErrorObject;
};

export const ApprovePage: React.FC<ApprovePageProps> = React.memo(
	({ job, brands, application, rejectMessage, loginAccount, onApprove, onChange, onReject, errors, onEnquetePage }) => {
		const canApprove = React.useMemo(
			() => application.approver && application.approver.toString() === loginAccount._id.toString(),
			[loginAccount, application]
		);
		const onPreview = React.useCallback(() => onEnquetePage("preview"), [onEnquetePage]);
		const onDebug = React.useCallback(() => onEnquetePage("debug"), [onEnquetePage]);
		return (
			<>
				<div className={classnames("main-title")}>調査概要</div>
				<div className={"m-2"}>
					<div className={classnames("sub-title")}>製品</div>
					<div className={classnames("form-area", "form-area__800")}>
						<SurveyBrandList surveyBrands={job.surveyBrands} brands={brands} />
					</div>
					<div className={"m-2"}>
						<div className={classnames("sub-title")}>調査画面</div>
						<div className={classnames("form-area", "form-area__300")}>
							<Button type="button" variant={"secondary"} onClick={onPreview} className={"m-2"}>
								調査画面プレビュー
							</Button>
							<Button type="button" variant={"secondary"} onClick={onDebug} className={"m-2"}>
								動作確認
							</Button>
						</div>
					</div>
					<div className={"m-2"}>
						<div className={classnames("sub-title")}>{jobLabel.surveyStartDate}</div>
						<div className={classnames("form-area", "form-area__300")}>
							<div className="message">{job.surveyStartDate}</div>
						</div>
					</div>
					<div className={"m-2"}>
						<div className={classnames("sub-title")}>{applicationLabel.message}</div>
						<div className={classnames("form-area")}>
							<div className="message">{application.message}</div>
						</div>
					</div>
				</div>
				<div className="button-area">
					{canApprove && (
						<>
							<Button variant={"outline-secondary"} onClick={onApprove}>
								発注する
							</Button>
							<RejectFormButton
								onChange={onChange}
								onReject={onReject}
								rejectMessage={rejectMessage}
								error={getErrorText(errors, "rejectMessage")}
							/>
						</>
					)}
				</div>
			</>
		);
	}
);
