import * as React from "react";
import classnames from "classnames";
import { Key } from "@lu/repository";
import { Button, Table } from "react-bootstrap";
import { accountLabel } from "../../../../../../label";
import { AccountWithoutPassword, accountRole } from "../../../../../../server/repositories/account/model";
import { accountRoleOptions } from "../../../../../../server/lib/option/account";
import { EditIcon } from "../../../parts/icons";
import { Searcher, SearchField } from "../../../parts/searcher";

type AccountRowProps = {
	account: AccountWithoutPassword;
	onEdit: (id: Key) => void;
};
const AccountRow: React.FC<AccountRowProps> = React.memo(({ account, onEdit }) => {
	const onEditClick = React.useCallback(() => onEdit(account._id), [account._id]);
	return (
		<tr>
			<td>{account.name}</td>
			<td>{account.email}</td>
			<td>{accountRole[account.role]}</td>
			<td>{account.inactive ? "×" : "●"}</td>
			<td>
				<div className={classnames("icon")} onClick={onEditClick}>
					<EditIcon />
				</div>
			</td>
		</tr>
	);
});

export type AccountPageProps = {
	accounts: AccountWithoutPassword[];
	searchValues: { [key: string]: any };
	onChangeSearchValues: (name: string, value: any) => void;
	onEdit: (id: Key) => void;
};

export const AccountPage: React.FC<AccountPageProps> = React.memo(
	({ accounts, onEdit, searchValues, onChangeSearchValues }) => {
		const fields = React.useMemo<SearchField[]>(
			() => [
				{ type: "text", name: "name", label: accountLabel.name },
				{ type: "text", name: "email", label: accountLabel.email },
				{ type: "select", name: "role", label: accountLabel.role, options: accountRoleOptions },
			],
			[]
		);
		const filteredAccounts = React.useMemo(() => {
			if (fields.every(({ name }) => !searchValues[name])) return accounts;
			return accounts.filter((account) => {
				return fields.every(({ name, type }) => {
					if (!searchValues[name]) return true;
					if (type === "text") {
						return account[name as "name" | "email"].includes(searchValues[name]);
					}
					return searchValues[name] === account[name as "role"];
				});
			});
		}, [accounts, searchValues, fields]);
		const onCreate = React.useCallback(() => onEdit("new"), []);
		return (
			<>
				<div className={"main-title"}>
					アカウント一覧
					<div className={"float-right"}>
						<Button variant={"outline-secondary"} onClick={onCreate}>
							新規アカウント作成
						</Button>
					</div>
				</div>
				<Searcher searchValues={searchValues} onChange={onChangeSearchValues} fields={fields} />
				<Table>
					<thead>
						<tr>
							<th>{accountLabel.name}</th>
							<th>{accountLabel.email}</th>
							<th>{accountLabel.role}</th>
							<th>有効</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{filteredAccounts.map((account) => (
							<AccountRow key={`account-${account._id}`} account={account} onEdit={onEdit} />
						))}
					</tbody>
				</Table>
			</>
		);
	}
);
