import { StandardCategoryDetail } from "..";

export const concealerDetail: StandardCategoryDetail = {
	inUse: {
		standards: [
			{ id: 1, text: "のばしやすい" },
			{ id: 2, text: "しっとりしている" },
			{ id: 3, text: "粉っぽくない" },
			{ id: 4, text: "肌にフィットする" },
			{ id: 5, text: "肌になじませやすい" },
		],
		options: [
			{ id: 6, text: "のびがなめらか" },
			{ id: 7, text: "のびが軽い" },
			{ id: 8, text: "さらさらしている" },
			{ id: 9, text: "香りが気にならない" },
		],
		canAdd: true,
	},
	afterUse: {
		standards: [
			{ id: 1, text: "肌が粉っぽくない" },
			{ id: 2, text: "肌へのフィット感がある" },
			{ id: 3, text: "塗った部分が厚塗りに見えない" },
			{ id: 4, text: "塗った部分がなめらかに見える" },
			{ id: 5, text: "塗った部分がきちんとカバーされている" },
			{ id: 6, text: "塗った部分が自然な感じに見える" },
		],
		options: [
			{ id: 7, text: "塗った部分がサラサラしている" },
			{ id: 8, text: "塗った部分が明るく見える" },
			{ id: 9, text: "厚塗りに見えない" },
			{ id: 10, text: "塗った部分がしっとりしている" },
			{ id: 11, text: "シミ・そばかすがカバーできている" },
			{ id: 12, text: "くすみがカバーできている" },
			{ id: 13, text: "色ムラがカバーできている" },
			{ id: 14, text: "ニキビあとがカバーできている" },
			{ id: 15, text: "くまがカバーできている" },
			{ id: 16, text: "毛穴がカバーできている" },
		],
		canAdd: true,
	},
	afterFourHours: {
		standards: [
			{ id: 1, text: "肌が粉っぽくない" },
			{ id: 2, text: "肌へのフィット感がある" },
			{ id: 3, text: "塗った部分が厚塗りに見えない" },
			{ id: 4, text: "塗った部分がなめらかに見える" },
			{ id: 5, text: "塗った部分がきちんとカバーされている" },
			{ id: 6, text: "塗った部分が薄れていない" },
			{ id: 7, text: "塗った部分がよれていない" },
			{ id: 8, text: "塗った部分が自然な感じに見える" },
		],
		options: [
			{ id: 9, text: "塗った部分がサラサラしている" },
			{ id: 10, text: "塗った部分が明るく見える" },
			{ id: 11, text: "厚塗りに見えない" },
			{ id: 12, text: "塗った部分がしっとりしている" },
			{ id: 13, text: "シミ・そばかすがカバーできている" },
			{ id: 14, text: "くすみがカバーできている" },
			{ id: 15, text: "色ムラがカバーできている" },
			{ id: 16, text: "ニキビあとがカバーできている" },
			{ id: 17, text: "くまがカバーできている" },
			{ id: 18, text: "毛穴がカバーできている" },
		],
		canAdd: true,
	},
};
