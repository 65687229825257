import * as React from "react";
import { useHistory, useParams } from "react-router";
import { apiEndpoint } from "../../../../../server/router/api/endpoint";
import { GetCrossResposne } from "../../../../../server/types/request/aggregate/cross";
import { CrossResultPage, CrossResultPageProps, DataType } from "../../../components/pages/aggregates/cross";
import { LoadingToast } from "../../../components/parts/loadings";
import { ConfirmBox } from "../../../components/parts/message/confirm-box";
import { fileDownload, get } from "../../../lib/request";
import { initState, reducer } from "../../../reducers/aggregates/cross";

const getEndpoint = (dataType: DataType) => {
	switch (dataType) {
		case "rawdata":
			return apiEndpoint.downloadRawdata;
		case "question-sheet":
			return apiEndpoint.downloadQuestionSheet;
		case "cross":
			return apiEndpoint.downloadCross;
	}
};

export const CrossContainer: React.FC = () => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { loading, info, viewType, data, surveyBrand, cross } = state;
	const history = useHistory();
	const { surveyBrandId } = useParams<{ surveyBrandId: string }>();
	React.useEffect(() => {
		get<GetCrossResposne>(`${apiEndpoint.cross}/${surveyBrandId}`)
			.then((response) => dispatch({ type: "loadCross", payload: response.data }))
			.catch((error) => {
				dispatch({ type: "changeLoading", payload: false });
				console.error(error);
			});
	}, [surveyBrandId]);
	const onDownload = React.useCallback<CrossResultPageProps["onDownload"]>(
		(dataType) => {
			dispatch({ type: "changeLoading", payload: true });
			const url = `${getEndpoint(dataType)}/${surveyBrandId}${dataType === "cross" ? `?type=${viewType}` : ""}`;
			const filename = `#${surveyBrand.no}${surveyBrand.productName}-${dataType}${
				dataType === "cross" && viewType !== "both" ? `-${viewType}` : ""
			}.csv`;
			fileDownload(url, filename)
				.then(() => dispatch({ type: "changeLoading", payload: false }))
				.catch((error) => {
					dispatch({ type: "changeLoading", payload: false });
					console.error(error);
				});
		},
		[surveyBrandId, viewType, surveyBrand]
	);
	const handleChangeViewType = React.useCallback<CrossResultPageProps["onChangeViewType"]>(
		(payload) => dispatch({ type: "changeViewType", payload }),
		[]
	);
	const handleChangeCross = React.useCallback<CrossResultPageProps["onChangeCross"]>(
		(payload) => dispatch({ type: "changeCross", payload }),
		[]
	);
	const handleGoBack = React.useCallback(() => history.goBack(), []);
	return (
		<>
			<LoadingToast show={loading} />
			<ConfirmBox info={info} />
			{!!surveyBrand && (
				<CrossResultPage
					viewType={viewType}
					data={data}
					surveyBrand={surveyBrand}
					cross={cross}
					onDownload={onDownload}
					onChangeViewType={handleChangeViewType}
					onChangeCross={handleChangeCross}
					goBack={handleGoBack}
				/>
			)}
		</>
	);
};
