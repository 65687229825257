import { Label, DefaultLabel } from "./label";
import { Account } from "../server/repositories/account/model";

export const accountLabel: Label<Account> = {
	...DefaultLabel,
	name: "名前",
	email: "メールアドレス",
	password: "パスワード",
	role: "権限",
	inactive: "無効",
};
