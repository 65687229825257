import * as React from "react";
import classnames from "classnames";

export type JobViewConfigItemProps = {
	label: string;
	value: React.ReactText | React.ReactElement;
};
export const JobViewConfigItem = React.memo<JobViewConfigItemProps>(({ label, value }) => {
	return (
		<div className={classnames("brand-config__item")}>
			<div className={classnames("brand-config__item__label")}>{label}</div>
			<div className={classnames("brand-config__item__value")}>{value}</div>
		</div>
	);
});
