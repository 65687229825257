import React, { useCallback, useEffect, useRef, useState } from "react";
import { Crosses } from "../../../../lib/aggregate/converter";
import { ResultViewType } from "../../../../types/result-view-type";
import { VariableSizeList } from "react-window";
import { CrossArea } from "./cross-area";

export type CrossViewProps = {
	crosses?: Crosses;
	viewType: ResultViewType;
};

export const CrossView = React.memo<CrossViewProps>(({ crosses, viewType: view }) => {
	const ref = useRef<HTMLDivElement>(null);
	const listRef = useRef<VariableSizeList>(null);

	const [rect, setRect] = useState<DOMRect | undefined>();

	useEffect(() => {
		if (!ref.current) return;

		setRect(ref.current.getBoundingClientRect());
		window.onresize = () => {
			setRect(ref.current.getBoundingClientRect());
		};
	}, [ref.current]);

	useEffect(() => {
		if (!listRef.current) return;
		listRef.current.resetAfterIndex(0);
	}, [listRef, view]);

	const getHeight = useCallback(
		(index: number) => {
			// 1em = 12px;
			const em = 12;
			// padding = 0.1rem;
			const padding = em * 0.2;
			// line-hight = 1.5;
			const lineHight = 1.5;
			// border-width = 1px;
			const border = 1;
			// cross-areaのpadding
			// padding-top: 1rem;
			// padding-bottom
			const areaPadding = em * 3;
			// title
			// font-size = 1.2em;
			// line-height = 2;
			const titleHeight = em * 1.2 * 2;
			// 一行あたりの文字数
			const maxLineLettersCount = rect?.width ? Math.ceil((rect.width - em * 4) / (em * 1.2)) : 0;

			const { bigTitle, condition, labels, options, qunename, selections, title } = crosses[index];

			// 最長のラベル / 4で行数を取得
			const headerRowsCount = labels.reduce((accumulator, ls) => {
				return (
					accumulator + Math.max(...ls.map(({ label, colSpan }) => Math.ceil(label.length / (colSpan || 1) / 4)), 1)
				);
			}, 0);

			const bodyHight =
				em * lineHight +
				padding +
				selections.reduce((accumulator, selection) => {
					return (
						accumulator +
						Math.max(
							Math.ceil(selection.length / 14) * em * lineHight + padding + border,
							(view === "both" ? 2 : 1) * (em * lineHight + padding + border)
						)
					);
				}, 0) +
				options.reduce((accumulator, option) => {
					return (
						accumulator +
						Math.max(
							Math.ceil(option.length / 14) * (em * lineHight + padding + border),
							em * lineHight + padding + border
						)
					);
				}, 0);

			// + 1はheader
			// 最後の + borderは最下部のborder
			return (
				titleHeight *
					(Math.ceil(`${qunename}.${title}`.length / maxLineLettersCount) +
						(bigTitle ? (maxLineLettersCount ? Math.ceil(bigTitle.length / maxLineLettersCount) : 1) : 0) +
						(condition ? (maxLineLettersCount ? Math.ceil(`【${condition}】`.length / maxLineLettersCount) : 1) : 0)) +
				headerRowsCount * em * lineHight +
				padding +
				bodyHight +
				border +
				areaPadding
			);
		},
		[crosses, rect, view]
	);

	return (
		<div ref={ref} className="cross-view">
			{crosses.length > 1 ? (
				<VariableSizeList
					ref={listRef}
					height={rect?.height || 0}
					itemCount={crosses.length}
					itemSize={getHeight}
					width="auto"
				>
					{({ index, style }) => (
						<CrossArea
							key={`cross-${crosses[index].qunename}${crosses[index].condition}`}
							cross={crosses[index]}
							style={style}
							viewType={view}
						/>
					)}
				</VariableSizeList>
			) : (
				!!crosses &&
				!!crosses.length && (
					<CrossArea key={`cross-${crosses[0].qunename}${crosses[0].condition}`} cross={crosses[0]} viewType={view} />
				)
			)}
		</div>
	);
});
