import classNames from "classnames";
import * as React from "react";
import { ListGroup } from "react-bootstrap";

export type DrawerItem = {
	active: boolean;
	icon: React.ReactNode;
	label: string;
	event: () => void;
};

const DrawerItem: React.FC<DrawerItem> = React.memo(({ label, active, icon, event }) => {
	return (
		<ListGroup.Item className={classNames("drawer__item", { "drawer__item--active": active })} onClick={event}>
			<span className="drawer__item__icon">{icon}</span>
			<span className="drawer__item__label">{label}</span>
		</ListGroup.Item>
	);
});

const OpenButton: React.FC<{ onClick: () => void }> = React.memo(({ onClick }) => {
	return (
		<ListGroup.Item className="drawer__open-button" onClick={onClick}>
			<i className={classNames("drawer__open-button__icon", "bi-chevron-double-right")} />
		</ListGroup.Item>
	);
});

export type DrawerProps = {
	items: DrawerItem[];
};

export const Drawer: React.FC<DrawerProps> = React.memo(({ items }) => {
	const [open, setOpen] = React.useState<boolean>(true);
	const openButtonClickHandler = React.useCallback(() => {
		setOpen(!open);
	}, [open]);
	return (
		<div className={classNames("drawer", { "drawer--open": open })}>
			<OpenButton onClick={openButtonClickHandler} />
			<ListGroup className="drawer__items-list" as="nav" variant="flush">
				{items.map((item, index) => (
					<DrawerItem key={`drawer-item-${index}`} {...item} />
				))}
			</ListGroup>
		</div>
	);
});
