import { DefaultSchema, Key, Seq } from "@lu/repository";
import { AccountWithoutPassword } from "../account/model";
import { Application } from "../application/model";
import { SurveyBrand } from "../survey-brand/model";

export const jobStatus = {
	inPreparation: "準備中",
	pending: "申請中",
	reject: "却下",
	progress: "進行中",
	complete: "調査完了",
} as const;
export type JobStatus = keyof typeof jobStatus;

export const jobStatusKeys = Object.keys(jobStatus) as JobStatus[];

/**
 * テスト品と競合品のpairの情報
 */
export type SurveyPair = {
	targetId: Key;
	competitorId?: Key;
};

export type Job = {
	no: Seq;
	accountId: Key;
	status: JobStatus;
	surveyStartDate: string;
	enqueteId?: string;
	surveys: SurveyPair[];
} & DefaultSchema;

export type JobWithSurveyBrands = Job & { surveyBrands: SurveyBrand[] };
export type JobWithApplication = Job & {
	application?: Application;
};
export type JobWithAccount = Job & {
	account: AccountWithoutPassword;
};

export const maxSurveyCount = 99;
