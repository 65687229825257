import * as React from "react";
import classnames from "classnames";
import { Table } from "react-bootstrap";
import { jobLabel, applicationLabel } from "../../../../../../../label";
import { JobWithApplication } from "../../../../../../../server/repositories/job/model";
import { Key } from "@lu/repository";
import { JobTableRow } from "./job-table-row";

export type Page = "edit" | "detail" | "preview" | "debug" | "application" | "view";

export type JobTableProps = {
	jobs: JobWithApplication[];
	accountMap: Map<string, string>;
	onChangeJobPage: (jobId: Key, page: Page) => void;
};
export const JobTalbe: React.FC<JobTableProps> = React.memo(({ jobs, accountMap, onChangeJobPage }) => {
	return (
		<Table className={classnames("job-table")}>
			<thead>
				<tr>
					<th>{jobLabel.no}</th>
					<th>{jobLabel.accountId}</th>
					<th>{jobLabel.surveyStartDate}</th>

					<th>{jobLabel.status}</th>
					<th>{applicationLabel.applicant}</th>
					<th>{applicationLabel.approver}</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				{jobs.map((job) => (
					<JobTableRow
						key={`job-${job._id.toString()}`}
						job={job}
						accountMap={accountMap}
						onChangeJobPage={onChangeJobPage}
					/>
				))}
			</tbody>
		</Table>
	);
});
