import * as React from "react";
import { JobTopPage, JobTopPageProps } from "../../../components/pages/job/top";
import { LoadingToast } from "../../../components/parts/loadings";
import { ConfirmBox } from "../../../components/parts/message/confirm-box";
import { get, makeError } from "../../../lib/request";
import { initState, reducer } from "../../../reducers/job/top";
import { apiEndpoint } from "../../../../../server/router/api/endpoint";
import { GetJobTopResponse } from "../../../../../server/types/request/job";
import { endpoint } from "../../../routes/endpoint";
import { useHistory } from "react-router";
import { GetApproveByJobIdResponse } from "../../../../../server/types/request/approve";

export const JobTopContainer: React.FC = () => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { loading, info, ...jobCorrectProps } = state;
	const history = useHistory();
	React.useEffect(() => {
		get<GetJobTopResponse>(apiEndpoint.jobTop)
			.then((response) => dispatch({ type: "loadJobs", payload: response.data }))
			.catch((error) => dispatch({ type: "changeMessageInfo", payload: makeError(error) }));
	}, []);
	const onChangeJobPage = React.useCallback<JobTopPageProps["onChangeJobPage"]>((jobId, page) => {
		switch (page) {
			case "detail":
				return history.push(`${endpoint.job}/${jobId}`);
			case "view":
				return history.push(`${endpoint.surveyBrand}/${jobId}`);
			case "edit":
				return history.push(`${endpoint.jobEdit}/${jobId}`);
			case "preview":
				return window.open(`${apiEndpoint.redirectPreview}/${jobId}`);
			case "debug":
				return window.open(`${apiEndpoint.redirectDebug}/${jobId}`);
			case "application":
				return get<GetApproveByJobIdResponse>(`${apiEndpoint.jobApprove}/${jobId}`).then((response) => {
					return history.push(`${endpoint.approve}/${response.data.application._id}`);
				});
		}
	}, []);
	const onChangeSearchValues = React.useCallback<JobTopPageProps["onChangeSearchValues"]>(
		(name, value) => dispatch({ type: "changeSearchValues", payload: { name, value } }),
		[]
	);
	return (
		<>
			<LoadingToast show={loading} />
			<ConfirmBox info={info} />
			<JobTopPage {...jobCorrectProps} onChangeJobPage={onChangeJobPage} onChangeSearchValues={onChangeSearchValues} />
		</>
	);
};
