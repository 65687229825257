import * as React from "react";
import { useHistory, useParams } from "react-router";
import { apiEndpoint } from "../../../../../server/router/api/endpoint";
import { GetOneAccountResponse, UpsertAccountResponse } from "../../../../../server/types/request/account";
import { AccountCreatePage, AccountCreatePageProps } from "../../../components/pages/account/create";
import { LoadingToast } from "../../../components/parts/loadings";
import { ConfirmBox } from "../../../components/parts/message/confirm-box";
import { get, post, put, makeError } from "../../../lib/request";
import { initState, reducer } from "../../../reducers/account/create";
import { endpoint } from "../../../routes/endpoint";

export const AccountCreateContainer: React.FC = () => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { loading, info, account, ...accountCreatePageProps } = state;
	const history = useHistory();
	const { accountId } = useParams<{ accountId: string }>();
	React.useEffect(() => {
		if (accountId === "new") return dispatch({ type: "changeLoading", payload: false });
		get<GetOneAccountResponse>(`${apiEndpoint.account}/${accountId}`)
			.then((response) => dispatch({ type: "loadAccount", payload: response.data }))
			.catch((error) => dispatch({ type: "changeMessageInfo", payload: makeError(error) }));
	}, []);
	const onChange = React.useCallback<AccountCreatePageProps["onChange"]>(
		(name, value) => dispatch({ type: "change", payload: { name, value } }),
		[]
	);
	const onSubmit = React.useCallback(() => {
		const isUpdate = !!account._id;
		const request = isUpdate
			? put<UpsertAccountResponse>(`${apiEndpoint.account}/${account._id.toString()}`, account)
			: post<UpsertAccountResponse>(apiEndpoint.account, account);
		request
			.then((response) => dispatch({ type: "upsert", payload: response.data }))
			.catch((error) => dispatch({ type: "changeMessageInfo", payload: makeError(error) }));
	}, [account]);
	const onClose = React.useCallback(() => info.isSuccess && info.message && history.push(endpoint.account), [info]);
	return (
		<>
			<LoadingToast show={loading} />
			<ConfirmBox info={info} onClose={onClose} />
			<AccountCreatePage
				{...accountCreatePageProps}
				account={account}
				onSubmit={onSubmit}
				onChange={onChange}
				errors={info.errors}
			/>
		</>
	);
};
