import { Key } from "@lu/repository";

export type Option = {
	label: string;
	value: any;
};

export type CustomOption = Option & {
	requiredBrandName?: boolean;
};

export const makeOptions = (obj: { [key: string]: any }): Option[] => {
	return Object.entries(obj).map(([value, label]) => {
		return { value, label };
	});
};

export type GroupOption = {
	id: any;
	label: string;
	options: Option[];
};

export const makeOptionsMongoRecords = (records: { _id: Key; name: string }[]): Option[] => {
	return records.map(({ _id, name }) => {
		return { value: _id, label: name };
	});
};

export const option2Map = (options: Option[]): Map<string, string> => {
	return options.reduce((a, b) => {
		a.set(b.value.toString(), b.label);
		return a;
	}, new Map<string, string>());
};

export const option2OptionMap = <Op extends Option>(options: Op[]): Map<string, Op> => {
	return options.reduce((a, b) => {
		a.set(b.value.toString(), b);
		return a;
	}, new Map<string, Op>());
};
