import { StandardCategoryDetail } from "..";

export const baseMakeDetail: StandardCategoryDetail = {
	inUse: {
		standards: [
			{ id: 1, text: "容器から出したときの見た目・色がよい" },
			{ id: 2, text: "のばしやすい" },
			{ id: 3, text: "油っぽくない" },
			{ id: 4, text: "肌にフィットする" },
		],
		canAdd: true,
	},
	afterUse: {
		standards: [
			{ id: 1, text: "肌がしっとりしている" },
			{ id: 2, text: "肌がサラサラしている" },
			{ id: 3, text: "肌がべたつかない" },
			{ id: 4, text: "肌にハリがある" },
			{ id: 5, text: "肌がもちもちとする" },
			{ id: 6, text: "毛穴が目立たない" },
			{ id: 7, text: "シミ・そばかすが目立たない" },
			{ id: 8, text: "しわが目立たない" },
			{ id: 9, text: "キメの粗さが目立たない" },
			{ id: 10, text: "つやのある肌にみえる" },
			{ id: 11, text: "肌が自然な感じに見える" },
			{ id: 12, text: "ファンデーションが塗りやすい" },
			{ id: 13, text: "ファンデーションがムラなく均一になじむ" },
		],
		canAdd: true,
	},
	afterFourHours: {
		standards: [
			{ id: 1, text: "肌がしっとりする" },
			{ id: 2, text: "シミ・そばかすが目立たない" },
			{ id: 3, text: "毛穴が目立たない" },
			{ id: 4, text: "しわが目立たない" },
			{ id: 5, text: "キメの粗さが目立たない" },
			{ id: 6, text: "つやのある肌にみえる" },
			{ id: 7, text: "肌が自然な感じに見える" },
			{ id: 8, text: "テカらない" },
			{ id: 9, text: "化粧もちが良い" },
			{ id: 10, text: "肌がサラサラしている" },
			{ id: 11, text: "肌にハリがある" },
		],
		canAdd: true,
	},
};
